import {FC, useEffect, useState} from "react";
import {
    useWarehouseRequestsRepo, warehouseRequestConverter
} from "../pages/warehouse/warehouse-transactions/warehouse-request/useWarehouseRequestsRepo";
import useFirebaseCollection from "../hooks/useFirebaseCollection";
import {where} from "firebase/firestore";
import {WarehouseRequestType} from "../pages/warehouse/warehouse-transactions/warehouse-request/WarehouseRequestType";
import {convertWarehouseItemData} from "../pages/warehouse/hooks/useWarehouseItemsForObject";
import {Button, Spinner} from "react-bootstrap";
import useFirebaseCollectionOnce from "../hooks/useFirebaseCollectionOnce";
import {WarehouseItemType} from "../pages/warehouse/hooks/useWarehouseInItems";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalculator, faCircleInfo, faInfo} from "@fortawesome/free-solid-svg-icons";
import {dateToString} from "../common";
import ProductAvailability from "./ProductAvailability";

interface Props {
    productId: string;
}

const ProductAvailabilityOnDemand: FC<Props> = ({ productId }) => {
    const [showAvailability, setShowAvailability] = useState<boolean>(false);
    return (
        showAvailability ? <ProductAvailability productId={productId} />
                : <Button onClick={() => setShowAvailability(true)}>
                    <FontAwesomeIcon icon={faCalculator} />
            </Button>
    );
};

export default ProductAvailabilityOnDemand;
