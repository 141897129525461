import {
    FirestoreError, where,
    DocumentData,
    QueryDocumentSnapshot, DocumentReference, QueryConstraint, QuerySnapshot
} from "firebase/firestore";
import {useEffect, useState} from "react";
import {ProductGroup} from "../../product-groups/ProductGroupsList";
import useFirebaseCollectionOnce from "../../../../hooks/useFirebaseCollectionOnce";

export interface ProductAttribute {
    name: string;
    price: number;
}

export interface ProductType {
    id: string;
    ref: DocumentReference<DocumentData>;
    sku?: string;
    name: string;
    attributes: ProductAttribute[];
    connectorType?: string;
    outputType?: string;
    deliveryPrice?: number;
    description?: string;
    descriptionForClient?: string;
    price?: number;
    producer?: string;
    productGroup?: ProductGroup;
    supplier?: string;
    height1?: number;
    height2?: number;
    width?: number;
    _length?: number;
    weightWithElectrolit?: number;
    weightWithoutElectrolit?: number;
    dimens: string;
    isOwnProduct: boolean;
    warranty?: string;
    liveCycles?: string;
    chargePrice?: number;
    minQty: number;
}

export type ProductsType = ProductType[];

export function convertProduct(d: DocumentData): ProductType | null {
    if(d) {
        let _newDoc: ProductType = {
                    id: d.id,
                    ref: d.ref,
                    sku: d.get('sku'),
                    name: d.get('name') ?? "",
                    dimens: d.get('dimens'),
                    attributes: d.get('attributes') ?? [],
                    connectorType: d.get('connectorType'),
                    outputType: d.get('outputType'),
                    deliveryPrice: (d.get('deliveryPrice') ?? 0) * 1,
                    description: d.get('description'),
                    descriptionForClient: d.get('descriptionForClient'),
                    price: (d.get('price') ?? 0) * 1,
                    producer: d.get('producer'),
                    productGroup: d.get('productGroup') as ProductGroup,
                    supplier: d.get('supplier'),
                    height1: (d.get('height1') ?? 0) * 1,
                    height2: (d.get('height2') ?? 0) * 1,
                    width: (d.get('width') ?? 0) * 1,
                    _length: (d.get('_length') ?? 0) * 1,
                    weightWithElectrolit: (d.get('weightWithElectrolit') ?? 0) * 1,
                    weightWithoutElectrolit: (d.get('weightWithoutElectrolit') ?? 0) * 1,
                    isOwnProduct: false,
                    warranty: d.get('warranty'),
                    liveCycles: d.get('liveCycles'),
                    chargePrice: (d.get('chargePrice') ?? 0) * 1,
                    minQty: (d.get('minQty') ?? 0) * 1,
                } as ProductType;
        return _newDoc;
    } else return null;
}

export function convertProducts(_objects: DocumentData[]): ProductsType {
    if(_objects) {
        let _newObjectsCollection: ProductsType = _objects.map(d => d && convertProduct(d)!);
        return _newObjectsCollection;
    } else return [];
}

export default function useProductsOnce(constraints?: QueryConstraint[]): [ProductsType, boolean, FirestoreError | undefined] {

    const [documents, loading, error] = useFirebaseCollectionOnce("products",
        [where("isOwnProduct", "!=", true), ...(constraints || [])]);
    const [convertedData, setConvertedData] = useState<ProductsType>([]);

    useEffect(() => {
        if(documents) {
            setConvertedData(convertProducts(documents));
        }
    }, [documents]);

    return [convertedData, loading, error];
}
