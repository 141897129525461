import {DocumentData, DocumentReference, QueryDocumentSnapshot, SnapshotOptions} from "firebase/firestore";

export type RegionType = {
    id?: string;
    ref?: DocumentReference;
    country?: string;
    name?: string;
}

export const regionConverter = {
    toFirestore(region: RegionType): DocumentData {
        return {
            country: region.country || null,
            name: region.name || null,
        };
    },
    fromFirestore(
        snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions
    ): RegionType {
        const region = snapshot.data(options)!;
        return {
            id: snapshot.id,
            ref: snapshot.ref,
            country: region.country,
            name: region.name,
        } as RegionType;
    }
};
