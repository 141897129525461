import {CustomerInvoiceItemType, CustomerInvoiceType} from "../../../fb-converters/cutomerInvoice-converter";
import {useEffect, useState} from "react";
import {Spinner} from "react-bootstrap";
import {EMPTY_PRODUCTION_CARD, productionCardConverter, ProductionCardType} from "../types/ProductionCardType";
import {collection, doc, getDocs, query, where} from "firebase/firestore";
import {fbDb} from "../../../App";
import {convertVirtualProductData} from "../../nomenclatures/production/hooks/useProductionOnce";
import useProducts from "../../nomenclatures/products/hooks/useProducts";
import {CustomerInquiryType} from "../../../fb-converters/cutomerInquiry-converter";

interface Props {
    inquiry: CustomerInquiryType;
    invoice: CustomerInvoiceType;
    product: CustomerInvoiceItemType;
    onCardLoaded: (productionCard: ProductionCardType) => void;
}

export default function ProductionPlanLoader(props: Props) {
    const [isLoading, setIsLoading] = useState(false);

    async function loadOrCreateProductionCard(invoice: CustomerInvoiceType) {
        const cards = await getDocs(
            query(collection(fbDb, "productionCards"),
                where("invoiceId", "==", invoice.id),
                where("specificationIds", "array-contains", props.product.productId)
            ).withConverter(productionCardConverter));

        if (cards.docs.length === 0) {
            /// create new production card
            const newCard: ProductionCardType = {
                ...EMPTY_PRODUCTION_CARD,
                specification: [],
                materials: [],
                inquiryId: props.inquiry.id!,
                inquiryNumber: props.inquiry.inquiryNumber,
                inquiryDate: props.inquiry.inquiryDate,
                invoiceId: invoice.id!,
                invoiceNumber: invoice.invoiceNumber,
                invoiceDate: invoice.invoiceDate,
                invoiceClientName: invoice.receiver.name,
                cardDate: new Date(),
            }
            for (const item of invoice.items) {
                const idx = newCard.specification.length + 1;
                if (item.ownProduction && item.productId && item.code && item.productId === props.product.productId) {
                    newCard.specification.push({
                        productId: item.productId,
                        productRef: doc(collection(fbDb, "products"), item.productId),
                        productCode: item.code,
                        productName: item.name,
                        charging: false,
                        connectors: "",
                        outputType: "none",
                        productQty: item.qty,
                        productSize: {
                            l: item._length || 0,
                            b: item.width || 0,
                            h1: item.height1 || 0,
                            h2: item.height2 || 0,
                        },
                        drenageHoles: false,
                        orderIndex: idx,
                    });

                    const productId = item.productId;
                    const productionItems =
                        await getDocs(query(collection(fbDb, "production"),
                            where("invoiceId", "==", invoice.id),
                            where("productId", "==", productId)));
                    if (!productionItems.empty) {
                        const productionItemsData = convertVirtualProductData(productionItems.docs);
                        for (const item1 of productionItemsData) {
                            if (item1.items && item1.items.length > 0) {
                                for (const productionItemData of item1.items) {
                                    const idx1= newCard.materials.length + 1;
                                    newCard.materials.push({
                                        productId: productionItemData.productId,
                                        productRef: doc(collection(fbDb, "products"), productionItemData.productId),
                                        productCode: productionItemData.sku || "",
                                        productName: productionItemData.name,
                                        productQty: productionItemData.qty,
                                        productDimension: productionItemData.dimension || "",
                                        productSize: {
                                            l: productionItemData.size.l || 0,
                                            b: productionItemData.size.b || 0,
                                            h1: productionItemData.size.h1 || 0,
                                            h2: productionItemData.size.h2 || 0,
                                        },
                                        orderIndex: idx1,
                                        supplier: "",
                                        supplyDate: null,
                                        supplyAddress: ""
                                    });
                                }
                            }
                        }
                    }
                }
            }

            props.onCardLoaded(newCard);
        } else {
            const cardData = { ...cards.docs[0].data() };
            if(!cardData.inquiryId || !cardData.inquiryNumber || !cardData.inquiryDate) {
                cardData.inquiryId = props.inquiry.id!;
                cardData.inquiryNumber = props.inquiry.inquiryNumber;
                cardData.inquiryDate = props.inquiry.inquiryDate;
            }
            props.onCardLoaded(cardData);
        }
    }

    useEffect(() => {
        setIsLoading(true);
        try {
            loadOrCreateProductionCard(props.invoice);
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }

    }, [props.invoice]);

    return isLoading ? <Spinner animation="border" variant="primary"/> : null;
}
