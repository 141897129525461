import {useState} from "react";
import {Container} from "react-bootstrap";
import WarehouseReportGeneralFilter, {WarehouseReportGeneralFilterType} from "./WarehouseReportGeneralFilter";

export default function WarehouseTransactionsReport() {
    const today = new Date();
    const weekAgo = new Date();
    weekAgo.setDate(today.getDate() - 7);

    const [filter, setFilter] = useState<WarehouseReportGeneralFilterType>({
        fromDate: weekAgo,
        toDate: today,
    });


    return <Container fluid>
        <WarehouseReportGeneralFilter filter={filter} onChange={filter => setFilter(filter)} />
    </Container>
}
