import {DocumentData, DocumentReference, QueryDocumentSnapshot, SnapshotOptions} from "firebase/firestore";
import {firebaseDateToDate} from "../common";

export type ClientLocationType = {
    id?: string;
    ref?: DocumentReference;
    clientId?: string;
    clientName?: string;
    country?: string;
    email?: string;
    machines?: string;
    machinesCnt?: string;
    mol?: string;
    name?: string;
    phone?: string;
    position?: string;
    region?: string;
    address?: string;
    religion?: string;
    sizeGroup?: string;
    salesRepresentative?: string;
    trader?: string;
    startDate?: Date;
}

export type ClientLocationsType = ClientLocationType[];

export const locationConverter = {
    toFirestore(location: ClientLocationType): DocumentData {
        return {
            clientId: location.clientId || null,
            clientName: location.clientName || null,
            country: location.country || null,
            email: location.email || null,
            machines: location.machines || null,
            mol: location.mol || null,
            name: location.name || null,
            phone: location.phone || null,
            position: location.position || null,
            region: location.region || null,
            religion: location.religion || null,
            sizeGroup: location.sizeGroup || null,
            salesRepresentative: location.salesRepresentative || null,
            trader: location.trader || null,
            machinesCnt: location.machinesCnt || null,
            address: location.address || null,
            startDate: location.startDate || null,
        };
    },
    fromFirestore(
        snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions
    ): ClientLocationType {
        const location = snapshot.data(options)!;
        return {
            id: snapshot.id,
            ref: snapshot.ref,
            clientId: location.clientId,
            clientName: location.clientName,
            country: location.country,
            email: location.email,
            machines: location.machines,
            mol: location.mol,
            name: location.name,
            phone: location.phone,
            position: location.position,
            region: location.region,
            religion: location.religion,
            sizeGroup: location.sizeGroup,
            salesRepresentative: location.salesRepresentative || null,
            trader: location.trader || null,
            machinesCnt: location.machinesCnt || null,
            address: location.address || null,
            startDate: firebaseDateToDate(location.startDate) || null,
        } as ClientLocationType;
    }
};
