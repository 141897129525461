import {FC, useState} from "react";
import {Button, Col, Form, Modal, Row, Spinner,} from "react-bootstrap";
import {customerInquiryConverter, CustomerInquiryType} from "../../../fb-converters/cutomerInquiry-converter";
import ReactDatePicker from "react-datepicker";
import SelectValueFromDialog from "../../../common/SelectValueFromDialog";
import OptionalElement from "../../../common/OptionalElement";
import NoWrapCol from "../../../common/NoWrapCol";
import BoldElement from "../../../common/BoldElement";
import {ClientsDialog} from "../../nomenclatures/clients/ClientsDialog";
import {OwnCompanyType} from "../../../fb-converters/own-company-converter";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import CancelIconButton from "../../../common/icon-buttons/CancelIconButton";
import DialogCloseButton from "../../../common/DialogCloseButton";
import CustomerInquiryServices from "./CustomerInquiryServices";
import CustomerInquiryProducs from "./CustomerInquiryProducs";
import {removeEmptyFields, showErrorsListInToast} from "../../../common";
import {addDoc, collection, setDoc, updateDoc} from "firebase/firestore";
import {fbDb} from "../../../App";
import {useHookstate} from "@hookstate/core";
import appState from "../../../global-state/global-state";
import ModalBackground from "../../../common/ModalBackground";
import I18Label from "../../../i18/i18label";

interface Props {
    zIndex?: number;
    ownCompanies: OwnCompanyType[];
    inquiry: CustomerInquiryType;
    onClose: VoidFunction;
}


const CustomerInquiryDetails: FC<Props> = (props) => {
    const globalState = useHookstate(appState).get();
    const [working, setWorking] = useState(false);
    const [inquiry, setInquiry] = useState(props.inquiry);
    const client = inquiry.client;
    const location = inquiry.location;
    const clientsDialog = <ClientsDialog show={true} zIndex={(props.zIndex || 1200) + 200} onClose={() => {
    }}/>

    async function saveInquiry() {
        const data = removeEmptyFields<CustomerInquiryType>(inquiry);
        data?.inquiryProducts?.forEach(
            p => {
                p.buyback = p.buyback || false;
                if (p.size) {
                    p.size.l = p.size?.l || "";
                    p.size.h = p.size?.h || "";
                    p.size.b = p.size?.b || "";
                } else p.size = {
                    l: null, b: null, h: null, h1: null
                }
            }
        );

        try {
            setWorking(true)

            if (data && inquiry.ref) {
                data.client = removeEmptyFields(data.client);
                data.location = removeEmptyFields(data.location);
                data.ownCompany = removeEmptyFields(data.ownCompany);

                await setDoc(inquiry.ref, data);
            } else {
                const inquiryNumber = globalState.customerInquiryNumber + 1;
                data!.inquiryNumber = inquiryNumber;

                const collRef = collection(fbDb, "customerInquiries").withConverter(customerInquiryConverter);
                await addDoc(collRef, data);

                await updateDoc(globalState.ref!, {customerInquiryNumber: inquiryNumber});
            }
            props.onClose();
        } catch (e: any) {
            console.error(e.message);
            showErrorsListInToast("Грешка", [`Възникна проблем:\n${e.message}`]);
        } finally {
            setWorking(false)
        }
    }

    const zIndex = (props.zIndex || 1200) + 200;
    return <ModalBackground zIndex={zIndex}>
    <Modal show={true} size={"xl"} style={{zIndex: zIndex + 1}}>
        <Modal.Header>
            <Modal.Title><I18Label label={"ЗАПИТВАНЕ ОТ КЛИЕНТ"} /></Modal.Title>
            <DialogCloseButton onClose={props.onClose}/>
        </Modal.Header>

        <Modal.Body>
            <Row>
                {
                    inquiry.ref ?
                        <Col xs={"auto"}>
                            <Form.Group className={"w-75px"}>
                            <Form.Label>
                                No:
                            </Form.Label>
                            <Form.Control disabled value={inquiry.inquiryNumber} className={"fw-bold text-end"} />
                            </Form.Group>
                        </Col> : null
                }

                <Col xs={"auto"}>
                    <Form.Group className={"w-120px"}>
                        <Form.Label className={"small"}><I18Label label={"Дата"} />:</Form.Label>
                        <ReactDatePicker className={"form-control"}
                                         dateFormat="dd.MM.yyyy"
                                         onChange={(d) => {
                                             setInquiry(prevState => {
                                                 return {...prevState, inquiryDate: d || new Date()}
                                             })
                                         }}
                                         selected={inquiry.inquiryDate}
                        />
                    </Form.Group>
                </Col>
                <Col xs={3}>
                    <Form.Group>
                        <Form.Label>
                            <I18Label label={"Запитване до"} />:
                        </Form.Label>
                        <select className={"form-select"}
                                onChange={event => setInquiry(prevState => {
                                    return {
                                        ...prevState,
                                        ownCompany: props.ownCompanies.find(c => c.id === event.target.value) || null
                                    }
                                })}
                        >
                            {
                                props.ownCompanies.map(
                                    c => <option key={c.id} value={c.id}> {c.name} </option>
                                )
                            }
                        </select>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label>
                            <I18Label label={"Изберете локация"} />:
                        </Form.Label>
                        <SelectValueFromDialog value={location?.name || ''}
                                               onLocationChange={(selected) => {
                                                   setInquiry(prevState => {
                                                       return {
                                                           ...prevState,
                                                           location: selected.location,
                                                           client: selected.client
                                                       }
                                                   })
                                               }}
                                               dialog={clientsDialog}/>
                    </Form.Group>
                </Col>
            </Row>
            {
                client &&
                <Row className={"border m-1 mt-0 p-1 small bg-secondary-light"}>
                    <OptionalElement isVisible={client?.region}>
                        <NoWrapCol><I18Label label={"Регион"} />: <BoldElement>{client?.region || ''}</BoldElement> </NoWrapCol>
                    </OptionalElement>
                    <OptionalElement isVisible={client?.address}>
                        <NoWrapCol><I18Label label={"Адрес"} />: <BoldElement>{client?.address || ''}</BoldElement> </NoWrapCol>
                    </OptionalElement>
                    <OptionalElement isVisible={client?.mol}>
                        <NoWrapCol>
                            <I18Label label={"МОЛ"} />: <BoldElement>{location?.mol || ''}</BoldElement>
                            &nbsp;<FontAwesomeIcon icon={faInfoCircle} title={"Лице за контакт"} />
                        </NoWrapCol>
                    </OptionalElement>
                    <OptionalElement isVisible={client?.sizeGroup}>
                        <NoWrapCol><I18Label label={"Група"} />: <BoldElement>{client?.sizeGroup?.name || ''}</BoldElement> </NoWrapCol>
                    </OptionalElement>
                </Row>
            }
            <Row>
                <Col>
                    <Form.Label><I18Label label={"Запитване за"} />:</Form.Label>
                    <Form.Control as={"textarea"} rows={3}
                                  value={inquiry.inquiryContent || ""}
                                  onChange={event => setInquiry(prevState => {
                                      return {...prevState, inquiryContent: event.target.value}
                                  })}
                    />
                </Col>
            </Row>
            <Row className={"mt-3"}>
                <Col>
                    <CustomerInquiryProducs
                        onChange={products => setInquiry(prevState => {
                        return {...inquiry, inquiryProducts: products}
                    })} products={inquiry.inquiryProducts || []}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <CustomerInquiryServices
                        services={inquiry.services || []}
                        onChange={services => {
                            setInquiry(prevState => {
                                return {...prevState, services: services}
                            })
                        }}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Label><I18Label label={"Допълнителна информация (изисквания)"} />:</Form.Label>
                    <Form.Control as={"textarea"} rows={2}
                                  value={inquiry.additionalInfo || ""}
                                  onChange={event => setInquiry(prevState => {
                                      return {...prevState, additionalInfo: event.target.value}
                                  })}
                    />
                </Col>
            </Row>
        </Modal.Body>

        <Modal.Footer className={"text-end"}>
            {
                working ? <Spinner animation={"border"}/> :
                    <>
                        <Button onClick={saveInquiry}>
                            <FontAwesomeIcon icon={faCheck}/>&nbsp;&nbsp;<I18Label label={"Запази"} />
                        </Button>
                        <CancelIconButton onClick={props.onClose}/>
                    </>
            }
        </Modal.Footer>
    </Modal>
    </ModalBackground>
}

export default CustomerInquiryDetails;
