import {dateToString, zeroToEmptyStringAsNumber} from "../../../../common";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faPencil, faTrash} from "@fortawesome/free-solid-svg-icons";
import {WarehouseInType} from "../../hooks/useWarehouseIn";
import CenterAlignedCell from "../../../../common/CenterAlignedCell";


export interface WarehouseTransactionTableRowProps {
    data: WarehouseInType;
    onEdit?: () => void;
    onDelete?: () => void;
}

export default function WarehouseInTableRow(props: WarehouseTransactionTableRowProps) {
    return <tr>
        <td> {dateToString(props.data.transactionDate)} </td>
        {/*<td> {props.data.transactionNumber} </td>*/}
        <td> {props.data.provider?.bulstat} </td>
        <td> {props.data.provider?.name} </td>
        <td> {props.data.invoiceNumber} </td>
        <td className={"text-end w-100px"}> {zeroToEmptyStringAsNumber(props.data.price)} </td>
        <CenterAlignedCell> {props.data.hasDDS ? <FontAwesomeIcon icon={faCheck} /> : ""} </CenterAlignedCell>
        <CenterAlignedCell> {props.data.isEUR ? <FontAwesomeIcon icon={faCheck} /> : ""} </CenterAlignedCell>
        <td className={"text-end"}>
            {
                props.onEdit &&
                <Button size={"sm"} variant={"secondary"} onClick={() => props.onEdit!()}>
                    <FontAwesomeIcon icon={faPencil} />
                </Button>
            }&nbsp;
            {
                props.onDelete &&
                <Button size={"sm"} variant={"danger"} onClick={() => props.onDelete!()}>
                    <FontAwesomeIcon icon={faTrash} />
                </Button>
            }
        </td>
    </tr>;
}
