import {ClientsType} from "../../pages/nomenclatures/clients/hooks/useClients";
import {ClientLocationsType} from "../../fb-converters/location-converter";
import {ProductsType} from "../../pages/nomenclatures/products/hooks/useProductsOnce";
import {ProductionsType} from "../../pages/nomenclatures/production/hooks/useProductionOnce";
import {VirtualProductsType} from "../../pages/nomenclatures/virtual-products/hooks/useVirtualProductsOnce";
import {I18ItemsType} from "../../fb-converters/i18-converter";
import {ServiceType} from "../../fb-converters/service-converter";

export type GlobalDbType = {
    clients: ClientsType;
    clientsLocations: ClientLocationsType;
    products: ProductsType;
    services: ServiceType[];
    virtualProducts: VirtualProductsType;
    production: ProductionsType;
    i18: I18ItemsType;
    language: "bg" | "ro";
    markI18Labels: boolean;
};

export const globalDbDefault: GlobalDbType = {
    clients: [],
    clientsLocations: [],
    production: [],
    products: [],
    services: [],
    virtualProducts: [],
    i18: [],
    language: "bg",
    markI18Labels: false,
}
