import {Button, Form, ModalBody, ModalFooter, Spinner, Tab, Table, Tabs} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCancel, faCircleArrowDown, faEdit, faPlus, faSearch, faTrash} from "@fortawesome/free-solid-svg-icons";
import {useCollection} from "react-firebase-hooks/firestore";
import {
    collection,
    getFirestore,
    DocumentData,
    query,
    where,
    orderBy,
    limit,
    endBefore,
    startAfter
} from "firebase/firestore";
import {fbDb, firebaseApp} from "../../../App";
import {useEffect, useState} from "react";
import LoadDataFromServerError from "../../../common/LoadDataFromServerError";
import I18Label from "../../../i18/i18label";


export interface ProductsListProps {
    isVisible: boolean;
    onAdd: () => any;
    onEdit: (item: DocumentData) => any;
    onDelete: (item: DocumentData) => any;
    onClose: (result: any) => any;
    onSelect?: (result: any) => any;
}

export function ServicesList({isVisible, onAdd, onEdit, onDelete, onClose, onSelect}: ProductsListProps) {

    const [filterName, setFilterName] = useState('')
    const [filterDescription, setFilterDescription] = useState('')
    const [q, setQ] = useState(
        query(collection(fbDb, "services"),
            orderBy("name", "asc")
        ))

    const [services, loading, error] = useCollection(q);
    const [filtered, setFilteredServices] = useState<any[]>([])

    useEffect(() => {
        if (services) {
            setFilteredServices(services.docs.filter(
                r => (!filterName || filterName.length === 0 || r.data().name.toLowerCase().indexOf(filterName.toLowerCase()) > -1) &&
                    (!filterDescription || filterDescription.length === 0 || r.data().name_ro?.toLowerCase().indexOf(filterDescription.toLowerCase()) > -1)
            ));
        } else setFilteredServices([]);
    }, [services, filterName, filterDescription]);

    function isLoading() {
        return loading;
    }

    return (
        <>
            {isLoading() && <Spinner animation={"border"}/>}

            {
                !isLoading() && error && <LoadDataFromServerError />
            }

            {
                !loading && !error && isVisible &&
                <>
                    <ModalBody>
                        <div className={"max-h-60vh scrollable"}>
                            <Table size={"sm"} borderless hover>
                                <thead style={{backgroundColor: "white"}}>
                            <tr>
                                <th style={{width: onSelect ? 120 : 90}}></th>
                                <th><I18Label label={"Име"} /></th>
                                <th><I18Label label={"Име"} /> RO</th>
                                <th><I18Label label={"Цена"} /></th>
                            </tr>
                            <tr>
                                <td className={"text-start"}>
                                    <FontAwesomeIcon icon={faSearch}/>
                                </td>
                                <td>
                                    <Form.Control size={"sm"} value={filterName}
                                                  onChange={(e) => setFilterName(e.target.value)}/>
                                </td>
                                <td>
                                    <Form.Control size={"sm"} value={filterDescription}
                                                  onChange={(e) => setFilterDescription(e.target.value)}/>
                                </td>
                                <td>
                                    <Form.Control size={"sm"} disabled={true}/>
                                </td>
                            </tr>
                            </thead>
                            <tbody>

                            {
                                loading &&
                                <tr>
                                    <td colSpan={7} className={"text-center fw-bold"}>
                                        <Spinner animation={"border"}/>
                                    </td>
                                </tr>
                            }
                            {
                                filtered && filtered.length === 0 &&
                                <tr>
                                    <td colSpan={7} className={"text-center fw-bold"}><I18Label label={"Няма услуги"} /></td>
                                </tr>
                            }
                            {

                                filtered && filtered.length > 0 && filtered.map(
                                    r =>
                                        <tr key={r.id} className={"tr-bordered"}>
                                            <td>
                                                <Button size={"sm"} variant={"outline-secondary"}
                                                        onClick={() => onEdit(r)}
                                                >
                                                    <FontAwesomeIcon icon={faEdit}/>
                                                </Button>&nbsp;
                                                <Button size={"sm"} variant={"outline-danger"}
                                                        onClick={() => {
                                                            onDelete(r)
                                                        }}
                                                >
                                                    <FontAwesomeIcon icon={faTrash}/>
                                                </Button>&nbsp;
                                                {
                                                    onSelect &&
                                                    <Button size={"sm"} variant={"outline-success"}
                                                            onClick={() => {
                                                                onSelect(r.data())
                                                            }}
                                                    >
                                                        <FontAwesomeIcon icon={faCircleArrowDown}/>
                                                    </Button>
                                                }
                                            </td>
                                            <td>
                                                {r.data().name}
                                            </td>
                                            <td>
                                                {r.data().name_ro}
                                            </td>
                                            <td>
                                                {r.data().price}
                                            </td>
                                        </tr>
                                )
                            }
                            </tbody>
                        </Table>
                        </div>
                    </ModalBody>

                    <ModalFooter>
                        <Button onClick={() => onAdd()}>
                            <FontAwesomeIcon icon={faPlus}/> <I18Label label={"Добави нова"} />
                        </Button>&nbsp;
                        <Button variant={"outline-secondary"} onClick={() => {
                            onClose(null)
                        }}>
                            <FontAwesomeIcon icon={faCancel}/> <I18Label label={"Затвори"} />
                        </Button>
                    </ModalFooter>
                </>
            }
        </>
    )
}
