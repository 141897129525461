import {
    FirestoreError,
    DocumentData,
    QueryDocumentSnapshot, DocumentReference, QueryConstraint
} from "firebase/firestore";
import {useEffect, useState} from "react";
import useFirebaseCollectionOnce from "../../../../hooks/useFirebaseCollectionOnce";
import {firebaseDateToDate, textToFloat} from "../../../../common";
import {ProductionProductSizeType} from "../../../production/types/ProductionCardProductSizeType";

export interface ProductionItemType {
    productId: string;
    sku?: string;
    name: string;
    dimension?: string;
    qty: number;
    singlePrice: number;
    price: number;
    size: ProductionProductSizeType;
}
export type ProductionItemsType = ProductionItemType[];
export const EMPTY_PRODUCTION: ProductionType = {name: "", qty: 1, items: [], productionDate: new Date()};

export interface ProductionType {
    id?: string;
    ref?: DocumentReference<DocumentData>;
    number?: string;
    sku?: string;
    productionDate: Date;
    productId?: string;
    productRef?: DocumentReference<DocumentData>;
    name: string;
    dimension?: string;
    items: ProductionItemsType;
    price?: number;
    qty: number;
    description?: string;
    warehouseId?: string;
    warehouseName?: string;
    invoiceId?: string;
    invoiceNumber?: number;
    invoiceDate?: Date;
    clientId?: string;
    clientLocationId?: string;
    clientName?: string;
    inquiryId?: string;
    inquiryNumber?: number;
    inquiryDate?: Date;
}

export type ProductionsType = ProductionType[];

export function convertVirtualProductData(_objects: QueryDocumentSnapshot<DocumentData>[]): ProductionsType {
    if(_objects) {
        let _newObjectsCollection: ProductionsType = _objects.map(
            d => {
                return {
                    id: d.id,
                    ref: d.ref,
                    number: d.get('number') || null,
                    sku: d.get('sku') || null,
                    productionDate: firebaseDateToDate(d.get('productionDate')) || null,
                    productId: d.get('productId'),
                    productRef: d.get('productRef'),
                    name: d.get('name') ?? "",
                    dimension: d.get('dimension') || null,
                    qty: d.get('qty') || 1,
                    price: textToFloat(d.get('price')) || null,
                    description: d.get('description') || null,
                    items: d.get('items') || [],
                    warehouseId: d.get('warehouseId') || null,
                    warehouseName: d.get('warehouseName') || null,
                    invoiceId: d.get('invoiceId') || null,
                    invoiceNumber: d.get('invoiceNumber') || null,
                    invoiceDate: firebaseDateToDate(d.get('invoiceDate')) || null,
                    clientId: d.get('clientId') || null,
                    clientLocationId: d.get('clientLocationId') || null,
                    clientName: d.get('clientName') || null,
                    inquiryId: d.get('inquiryId') || null,
                    inquiryNumber: d.get('inquiryNumber') || null,
                    inquiryDate: firebaseDateToDate(d.get('inquiryDate')) || null,
                } as ProductionType;
            }
        );
        return _newObjectsCollection;
    } else return [];
}

export default function useProductionOnce(constraints?: QueryConstraint[]): [ProductionsType, boolean, FirestoreError | undefined] {
    const [documents, loading, error] = useFirebaseCollectionOnce("production", constraints);
    const [convertedData, setConvertedData] = useState<ProductionsType>([]);

    useEffect(() => {
        if(documents) {
            setConvertedData(convertVirtualProductData(documents));
        }
    }, [documents]);

    return [convertedData, loading, error];
}
