import {CustomerInvoiceItemType, CustomerInvoiceType} from "../../../fb-converters/cutomerInvoice-converter";
import FilesIconButton from "../../../common/icon-buttons/FilesIconButton";
import {useState} from "react";
import ProductionPlanDetailsPopup from "./ProductionPlanDetailsPopup";
import {CustomerInquiryType} from "../../../fb-converters/cutomerInquiry-converter";
import {Dropdown} from "react-bootstrap";
import {getI18Text} from "../../../i18/i18label";

export default function ProductionPlanButton(props: {
    inquiry: CustomerInquiryType;
    invoice: CustomerInvoiceType | undefined
}) {
    const [showDetails, setShowDetails] = useState<CustomerInvoiceItemType | undefined>(undefined);

    function setInvoiceProductionPlan(invoice: CustomerInvoiceType | undefined) {
        setShowDetails(undefined);
    }

    const ownProduction = props.invoice?.items.filter(item => item.ownProduction === true);

    return ownProduction ? <>

            {
                ownProduction.length === 1 &&
                <span className={"ms-1 me-1"}>
                <FilesIconButton onClick={() => {
                    setInvoiceProductionPlan(props.invoice);
                    setShowDetails(ownProduction[0]);
                }}
                                 title={getI18Text("Карта за възлагане към сервиза")}/>
            </span>
            }
            {
                ownProduction.length > 1 &&
                <Dropdown className={"p-0 bg-white ms-1 me-1"} style={{height: "23px"}}>
                    <Dropdown.Toggle variant="light" id="dropdown-inv-prod-plan" className={"p-0 m-0"}>
                        <FilesIconButton onClick={() => setInvoiceProductionPlan(props.invoice)}
                                         title={getI18Text("Карта за възлагане към сервиза")}/>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {
                            ownProduction.map((item, index) => (
                                <Dropdown.Item key={`${item.name}-${index}`}
                                               onClick={() => setShowDetails(item)}>
                                    {item.name}
                                </Dropdown.Item>
                            ))
                        }
                    </Dropdown.Menu>
                </Dropdown>
            }
            {
                showDetails && props.invoice && props.inquiry &&
                <ProductionPlanDetailsPopup onClose={() => setShowDetails(undefined)} show zIndex={1200}
                                            invoice={props.invoice}
                                            inquiry={props.inquiry}
                                            product={showDetails}
                />
            }
        </>
        :
        null;
}
