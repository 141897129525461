import React, {CSSProperties} from "react";

export default function ModalBackground(props: any) {
    const modalOverlay = {
        position: "fixed",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        display: "block",
        opacity: "50%",
        backgroundColor: "grey",
        zIndex: props.zIndex,
    } as CSSProperties;

    return props.show === false ? null : (
        <><div style={modalOverlay} />{ props.children }</>
    );
}
