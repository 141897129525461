import {Button, Col, Form, Modal, ModalBody, ModalFooter, ModalTitle, ProgressBar, Row, Spinner} from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMoneyBillTrendUp} from "@fortawesome/free-solid-svg-icons";
import DialogCloseButton from "../../../common/DialogCloseButton";
import React, {useState} from "react";
import {DIALOG_OPTIONS} from "../../../types";
import ModalBackground from "../../../common/ModalBackground";
import SelectValueFromDialog from "../../../common/SelectValueFromDialog";
import {dateToString, showErrorsListInToast, textToFloat} from "../../../common";
import {StringInput} from "../../../common/StringInput";
import {convertProductGroupVariantToLabel, ProductGroup} from "../product-groups/ProductGroupsList";
import {ProductGroupsDialog} from "../product-groups/ProductGroupsDialog";
import {addDoc, collection, doc, getDocs, getFirestore, query, setDoc, updateDoc, where} from "firebase/firestore";
import {fbDb, firebaseApp} from "../../../App";

export default function BulkPriceUpdateModal({zIndex, show, onClose}: DIALOG_OPTIONS) {
    const [confirmAction, setConfirmAction] = useState(false);
    const [working, setWorking] = useState(false);
    const [progress, setProgress] = useState(0);
    const [productGroup, setProductGroup] = useState<ProductGroup | null>(null);
    const [percent, setPercent] = useState(0);

    const productTypeDialog = <ProductGroupsDialog show={true} zIndex={zIndex || 1020} onClose={() => {
    }}/>

    if (!show) return null;

    async function performChange() {
        let errors: Array<string> = [];
        if (!productGroup) {
            errors.push("Не сте избрали продуктова група.");
        } else {
            if (productGroup!.variant > 1) {
                errors.push("Не сте избрали продуктова група.");
            }
        }
        if (percent === 0) {
            errors.push("Процента не може да е нула.");
        }

        if (errors.length > 0) {
            showErrorsListInToast("Внимание", errors);
            setConfirmAction(false);
        } else {
            setWorking(true);

            let _q = query(collection(fbDb, "products"),
                where("productGroup.id", '==', productGroup?.id));
            let products = await getDocs(_q);
            let total = products.size;
            setProgress(0);

            for (let idx = 0; idx < total; idx++) {
                try {
                    let product = products.docs[idx];
                    let _dPrice = textToFloat(product.get('deliveryPrice')?.toString(), 0) || 0;
                    let _sPrice = textToFloat(product.get('price')?.toString(), 0) || 0;
                    _dPrice += _dPrice * (percent / 100);
                    _sPrice += _sPrice * (percent / 100);

                    switch (product.get('productGroup')?.variant) {
                        case 0:
                            _sPrice = _dPrice + _dPrice * productGroup!.percent / 100;
                            break;
                        case 1:
                            _dPrice = _sPrice - _sPrice * productGroup!.percent / 100;
                            break;
                    }

                    await updateDoc(product.ref, {deliveryPrice: _dPrice, price: _sPrice});

                    await setDoc(doc(fbDb, `products/${product.id}/history/${dateToString(new Date())}`), {
                        deliveryPrice: _dPrice,
                        sellPrice: _sPrice
                    });

                } catch (e) {
                    console.error(e);
                } finally {
                    setProgress(Math.round(idx / total * 100));
                }
            }

            setWorking(false);
            setConfirmAction(false);
            setProgress(0);
            setProductGroup(null);
            setPercent(0);
            onClose(true);
        }
    }

    return <>
        <ModalBackground zIndex={zIndex}>
            <Modal show={show} size={"lg"} style={{zIndex: (zIndex + 1)}}>
                <ModalHeader>
                    <ModalTitle>
                        <FontAwesomeIcon icon={faMoneyBillTrendUp}/> Промяна на цените за група
                    </ModalTitle>
                    <DialogCloseButton onClose={() => onClose(false)}/>
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <SelectValueFromDialog label={"Продуктова група"}
                                               value={productGroup ? `${productGroup?.name || ""} ( ${convertProductGroupVariantToLabel(productGroup?.variant)} )`
                                                   : "Моля, изберете група за промяна на цената..."}
                                               onChange={(productGroup: any) => setProductGroup(productGroup || undefined)}
                                               dialog={productTypeDialog}
                        />
                        <Form.Group>
                            <Form.Label>Процент</Form.Label>
                            <StringInput value={percent.toString()}
                                         onChange={newValue => setPercent(textToFloat(newValue, 0) || 0)}/>
                        </Form.Group>
                    </Form>
                </ModalBody>

                <ModalFooter>
                    {
                        working ?
                            <Col className={"text-left"}>
                                <Spinner animation={"border"}/>
                                {
                                    progress > 0 && progress < 100 && <ProgressBar className={"ps-2"} now={progress}/>
                                }
                            </Col>
                            :
                            <>
                                <Col xs={"auto"}>
                                    <Button onClick={() => setConfirmAction(true)}>
                                        Приложи
                                    </Button>
                                </Col>
                                <Col>
                                    {
                                        confirmAction &&
                                        <>
                                            <Row className={"w-240px"}>
                                                <Col className={"text-end"}>
                                                    <span className={"pe-2 pt-2 d-inline-block"}>Сигурни ли сте?</span>
                                                </Col>
                                                <Col>
                                                    <Button variant={"success"}
                                                            onClick={performChange}>Да</Button>&nbsp;
                                                    <Button variant={"danger"}
                                                            onClick={() => setConfirmAction(false)}>Не</Button>
                                                </Col>
                                            </Row>
                                        </>
                                    }
                                </Col>
                                <Col xs={"auto"}>
                                    <Button onClick={() => onClose(false)} variant={"secondary"}>
                                        Откажи
                                    </Button>
                                </Col>
                            </>
                    }
                </ModalFooter>
            </Modal>
        </ModalBackground>
    </>

}
