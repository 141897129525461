import {
    Modal,
    ModalTitle,
} from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import React, {useEffect, useState} from "react";
import {DIALOG_OPTIONS} from "../../../types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTruck} from "@fortawesome/free-solid-svg-icons";
import ModalBackground from "../../../common/ModalBackground";
import {ProvidersList} from "./ProvidersList";
import {ProviderDetails} from "./ProviderDetails";
import { ProviderDelete } from "./ProviderDelete";
import DialogCloseButton from "../../../common/DialogCloseButton";
import {ProviderType} from "./hooks/useProviders";

export function ProvidersDialog({zIndex, show, onClose, onSelect}: DIALOG_OPTIONS) {

    const [viewMode, setViewMode] = useState<"VIEW_LIST" | "VIEW_ADD" | "VIEW_EDIT" | "VIEW_DELETE">('VIEW_LIST');
    const [editDocument, setEditDocument] = useState<ProviderType | null>(null);
    const [deleteDocument, setDeleteDocument] = useState<ProviderType | null>(null);
    const [addDocument, setAddDocument] = useState(false);


    useEffect(() => {
        setViewMode(editDocument ? "VIEW_EDIT" : "VIEW_LIST");
    }, [editDocument])

    useEffect(() => {
        setViewMode(deleteDocument ? "VIEW_DELETE" : "VIEW_LIST");
    }, [deleteDocument])

    useEffect(() => {
        setViewMode(addDocument ? "VIEW_ADD" : "VIEW_LIST");
    }, [addDocument])

    return (
        <ModalBackground zIndex={zIndex}>
            <Modal show={show} size={"xl"} style={{zIndex: (zIndex + 1)}}>
                <ModalHeader>
                    <ModalTitle>
                        <FontAwesomeIcon icon={faTruck}/> Доставчици
                    </ModalTitle>
                    <DialogCloseButton onClose={() => onClose(null)} />
                </ModalHeader>
                {
                    <ProvidersList onClose={onClose} isVisible={viewMode === "VIEW_LIST"}
                                   onSelect={onSelect}
                                   onAdd={() => setAddDocument(true)}
                                   onEdit={(doc: ProviderType) => {
                                     setEditDocument(doc);
                                 }}
                                   onDelete={(doc: ProviderType) => {
                                     setDeleteDocument(doc);
                                 }}
                    />
                }
                {
                    viewMode === "VIEW_EDIT" && editDocument &&
                    <ProviderDetails doc={editDocument}
                                     zIndex={(zIndex || 1100) + 10}
                                     onDone={() => {
                                      setEditDocument(null)
                                  }}/>
                }
                {
                    viewMode === "VIEW_DELETE" && deleteDocument &&
                    <ProviderDelete doc={deleteDocument} onDone={() => {
                        setDeleteDocument(null)
                    }}/>
                }
                {
                    viewMode === "VIEW_ADD" && addDocument &&
                    <ProviderDetails doc={null}
                                     zIndex={(zIndex || 1100) + 10}
                                     onDone={() => {
                                      setAddDocument(false)
                                  }}/>
                }

            </Modal>
        </ModalBackground>
    )
}
