import {Button, CloseButton, Col, Container, Modal, Row, Spinner, Table} from "react-bootstrap";
import ModalBackground from "../../../../common/ModalBackground";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilePdf, faPrint} from "@fortawesome/free-solid-svg-icons";
import {useReactToPrint} from "react-to-print";
import React, {useEffect, useRef, useState} from "react";
import "./WarehouseRevisionPrintPreview.css";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import {
    WarehouseRevisionIndexItemType, warehouseRevisionItemConverter, WarehouseRevisionItemsType,
} from "../../../../fb-converters/warehouse-revision-converter";
import {dateToString} from "../../../../common";
import {doc, getDoc} from "firebase/firestore";
import {fbDb} from "../../../../App";
import I18Label from "../../../../i18/i18label";

interface Props {
    zIndex?: number;
    warehouseRevison: WarehouseRevisionIndexItemType;
    onDismiss: () => void;
}


export default function WarehouseRevisionPrintPreview({zIndex, warehouseRevison, onDismiss}: Props) {
    const _zIndex = zIndex || 1000;
    const componentRef = useRef(null);
    const [working, setWorking] = useState(false);
    const [revision, setRevision] = useState<WarehouseRevisionItemsType | null>(null);


    useEffect(() => {
        const fetchData = async () => {
            if(warehouseRevison?.id) {
                setWorking(true);
                const docRef = doc(fbDb, "warehouseRevisions", warehouseRevison.id).withConverter(warehouseRevisionItemConverter);
                const docSnap = await getDoc(docRef);
                const docsnapData = docSnap.data();
                if(docsnapData) {
                    setRevision(docsnapData);
                } else {
                    setRevision(null);
                }
                setWorking(false);
            }
        };
        fetchData();
    }, []);

    const printContent = (
        <Container fluid ref={componentRef} className={"p-3"}>
            <div className={"mb-5 text-center"}>
                <img src={require('../../../../assets/images/logo.png')} className={"w-75"}/>
            </div>
            <Row className={"text-center"}>
                <h1 className={"text-uppercase"}>ПРОТОКОЛ ЗА РЕВИЗИЯ НА СТОКИ</h1>
            </Row>
            <Row className={"text-center mb-5"}>
                <Col>
                    <h4>{warehouseRevison.revisionNumber.toString().padStart(6, "0")} /
                        {dateToString(warehouseRevison.revisionDate)}</h4>
                </Col>
            </Row>

            <Row>
                <Col className={"border border-2 border-dark rounded pt-2 m-3 me-0"}>
                    <Table className={"no-border"}>
                        <thead>
                        <tr className={"text-light"}>
                            <th>#</th>
                            <th>SKU</th>
                            <th className={"w-50"}>Продукт</th>
                            <th>Мярка</th>
                            <th className={"text-end"}>Количество</th>
                            <th className={"text-end"}>Отчетено<br/>количество</th>
                            <th className={"text-end"}>Разлика</th>
                            <th className={"text-end"}>Ед.цена</th>
                            <th className={"text-end"}>Стойност</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            revision?.items.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.productCode}</td>
                                    <td>{item.productName}</td>
                                    <td>{item.productDimension}</td>
                                    <td className={"text-end"}>{item.expectedQty}</td>
                                    <td className={"text-end"}>{item.actualQty}</td>
                                    <td className={"text-end"}>{item.actualQty - item.expectedQty}</td>
                                    <td className={"text-end"}>{item.productSinglePrice.toFixed(2)}</td>
                                    <td className={"text-end"}>{((item.actualQty - item.expectedQty) * item.productSinglePrice).toFixed(2)}</td>
                                </tr>
                            ))
                        }

                        <tr>
                            <td colSpan={8} className={"text-end fw-bold border-0 border-top border-2 border-black"}>Общо:</td>
                            <td className={"text-end fw-bold border-0 border-top border-2 border-black"}>{revision?.items.reduce((acc, item) => acc + (item.actualQty - item.expectedQty) * item.productSinglePrice, 0).toFixed(2)}</td>
                        </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>

            <Row className={"mt-5 pt-5"}>
                <Col>
                    <h5>Създал:</h5>
                </Col>
                <Col>
                    <h5>Получил:</h5>
                </Col>
            </Row>

            <Row className={"mt-3 pt-3 text-center fst-italic"}>
                <Col>
                    <h5>/ {warehouseRevison.createdBy} /</h5>
                </Col>
                <Col>
                    <h5>/ {warehouseRevison.receivedBy} /</h5>
                </Col>
            </Row>

        </Container>
    );

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    function handleClose() {
        onDismiss();
    }

    const handleExport = async () => {
        if (componentRef.current) {
            const pdf = new jsPDF("p", "pt", "a4");
            const canvas = await html2canvas(componentRef.current);
            const img = canvas.toDataURL("image/png");
            const imgProperties = pdf.getImageProperties(img);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight =
                (imgProperties.height * pdfWidth) / imgProperties.width;
            pdf.addImage(
                img,
                "PNG",
                0,
                0,
                pdfWidth - 5,
                pdfHeight,
                "page1",
                "FAST",
            );
            return pdf;
        }
    };

    const handleGerenatePdf = async () => {
        setWorking(true);
        const pdf = await handleExport();
        if (pdf) {
            pdf.save(`warehouse-revision-protocol-${warehouseRevison.revisionNumber?.toString() ?? "xxx"}.pdf`);
        }
        setWorking(false);
    };

    const [pdfFile, setPdfFile] = useState<string | undefined>(undefined);
    useEffect(
        () => {
            const fetchData = async () => {
                const pdf = await handleExport();
                if (pdf) {
                    const content = pdf.output('arraybuffer');
                    var base64 = btoa(
                        new Uint8Array(content)
                            .reduce((data, byte) => data + String.fromCharCode(byte), ''));
                    setPdfFile(base64);
                }
            };
            fetchData();
        },
        [componentRef.current]
    );

    return (
        <ModalBackground zIndex={_zIndex}>
            <Modal show style={{zIndex: _zIndex + 1}} size={"xl"}>
                <Modal.Header>
                    <Modal.Title>Печат на документ</Modal.Title>
                    <CloseButton onClick={() => onDismiss()}/>
                </Modal.Header>
                <Modal.Body className={"p-5"}>
                    {printContent}
                </Modal.Body>
                <Modal.Footer>
                    {
                        working ? <Col className={"text-center"}><Spinner animation={"border"}/></Col> :
                            <>
                                <Col>
                                    <Button variant="secondary" onClick={handleClose}>
                                        <I18Label label={"Затвори"} />
                                    </Button>
                                </Col>
                                <Col xs={"auto"}>
                                    <Button onClick={handleGerenatePdf} variant={"info"} className={"text-light"}>
                                        <FontAwesomeIcon icon={faFilePdf}/> <I18Label label={"Запази като PDF"} />
                                    </Button>&nbsp;
                                    <Button variant="primary" onClick={handlePrint}>
                                        <FontAwesomeIcon icon={faPrint} className={"me-2"}/>
                                        <I18Label label={"Печат"} />
                                    </Button>
                                </Col>
                            </>
                    }
                </Modal.Footer>
            </Modal>
        </ModalBackground>
    );
}
