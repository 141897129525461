import { CustomerOfferProductionType } from "../../../../../../../fb-converters/cutomerOffer-converter";
import {TableRow} from "./OfferProductPdfTableHeader";
import React from "react";
import {View, Text} from "@react-pdf/renderer";
import {styles} from "../OfferPdfStyles";
import {ProductDimens} from "../../../../CustomerOfferDetailsProducts";
import {ProductType} from "../../../../../../nomenclatures/products/hooks/useProductsOnce";
import {zeroToEmptyStringAsCurrencyWithCommas} from "../../../../../../../common";

interface OfferPdfTableRowProps {
    productIndex: number;
    product: CustomerOfferProductionType;
    finalPrice: number;
}

const OfferProductPdfTableRow = ({productIndex, product, finalPrice}: OfferPdfTableRowProps) => {
    return (<View>
        <TableRow cols={[
            <View style={styles.col1}>
                <Text>
                    {productIndex + 1}
                </Text>
            </View>,
            <View style={styles.col2}>
                <Text>
                    {product.product.name}
                </Text>
            </View>,
            <View style={styles.col3}>
                <Text>
                    <ProductDimens product={{...product.product} as ProductType}/>
                </Text>
            </View>,
            <View style={styles.col4}>
                <Text>
                    {product.deliveryDays}
                </Text>
            </View>,
            <View style={styles.col5}>
                <Text>
                    {product.product.warranty}
                </Text>
            </View>,
            <View style={styles.col6}>
                <Text>
                    {product.product.liveCycles}
                </Text>
            </View>,
            <View style={styles.col7}>
                <Text>
                    {zeroToEmptyStringAsCurrencyWithCommas(finalPrice)}
                </Text>
            </View>,
            <View style={styles.col8}>
                <Text>
                    {zeroToEmptyStringAsCurrencyWithCommas(product.skrubPrice)}
                </Text>
            </View>,
            <View style={styles.col9}>
                <Text>
                    {zeroToEmptyStringAsCurrencyWithCommas(product.finalSinglePrice)}
                </Text>
            </View>,
            <View style={styles.col10}>
                <Text>
                    {zeroToEmptyStringAsCurrencyWithCommas(product.qty)}
                </Text>
            </View>,
            <View style={styles.col11}>
                <Text>
                    {zeroToEmptyStringAsCurrencyWithCommas((product.finalSinglePrice * product.qty))}
                </Text>
            </View>

        ]}/>
    </View>);
};

export default OfferProductPdfTableRow;
