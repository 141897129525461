import React, {useEffect, useMemo, useState} from "react";

import {Button, Col, Form, Modal, ModalBody, ModalTitle, Row, Spinner, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faSave,
    faCancel,
    faDolly, faTrash,
} from "@fortawesome/free-solid-svg-icons";
import {
    removeEmptyFields,
    showErrorsListInToast,
    textToFloat,
    zeroToEmptyString, zeroToEmptyStringAsCurrency, zeroToEmptyStringAsCurrencyWithCommas,
    zeroToEmptyStringAsNumber
} from "../../../../common";
import SelectValueFromDialog from "../../../../common/SelectValueFromDialog";
import ReactDatePicker from "react-datepicker";
import ModalHeader from "react-bootstrap/ModalHeader";
import DialogCloseButton from "../../../../common/DialogCloseButton";
import ModalBackground from "../../../../common/ModalBackground";
import PlusIconButton from "../../../../common/icon-buttons/PlusIconButton";
import LoadingErrorContent from "../../../../common/LoadingErrorContent";
import {WarehouseOutType} from "../../hooks/useWarehouseOut";
import UseWarehouseOutItems, {WarehouseOutItemsType, WarehouseOutItemType} from "../../hooks/useWarehouseOutItems";
import {ClientsDialog} from "../../../nomenclatures/clients/ClientsDialog";
import {WarehouseOutSelectItemDialog} from "./WarehouseOutSelectItemDialog";
import EditableTableCell from "../../../../common/EditableTableCell";
import {fbDb, firebaseApp} from "../../../../App";
import {addDoc, collection, getDocs, getFirestore, query, setDoc, where, writeBatch,} from "firebase/firestore";
import UseRecalcWarehouseItemStatus from "../../hooks/useRecalcWarehouseItemQty";
import LoadingErrorContentTable from "../../../../common/LoadingErrorContentTable";
import RightAlignedCell from "../../../../common/RightAlignedCell";
import {useHookstate} from "@hookstate/core";
import appDb from "../../../../global-state/global-db";
import {ProductType} from "../../../nomenclatures/products/hooks/useProductsOnce";


export interface WarehouseTransactionDetailsProps {
    zIndex?: number;
    warehouseTransaction: WarehouseOutType;
    onDone: () => any;
}

export function WarehouseOutDetails({zIndex, warehouseTransaction, onDone}: WarehouseTransactionDetailsProps) {
    const _products = useHookstate(appDb.products);

    const [saving, setSaving] = useState(false);
    const [item, setItem] = useState<WarehouseOutType>({...warehouseTransaction});
    const [newItem, setNewItem] = useState<WarehouseOutItemType | null>(null);
    const [editValue, setEditValue] = useState("");

    const [itemItems, loadItemItems, errorItemItems] = UseWarehouseOutItems(item.id || "-1");

    const warehouseItemUpdater = UseRecalcWarehouseItemStatus();


    const clientsDialog = <ClientsDialog show={true} zIndex={(zIndex || 1060) + 1} onClose={() => {
    }}/>;

    const productsDialog = <WarehouseOutSelectItemDialog show={true} zIndex={(zIndex || 1060) + 1}
                                                         warehouseId={warehouseTransaction.warehouseId}
                                                         onSelect={(item) => {
                                                         }}/>;

    useEffect(() => {
        setItem({...item, items: itemItems})
    }, [itemItems]);


    useEffect(() => {
        if (!newItem) return;

        const price = (newItem.qty || 0) * (newItem.singlePrice || 0);
        if (newItem.price !== price) {
            setNewItem({...newItem, price: price} as WarehouseOutItemType);
        }
    }, [newItem?.qty, newItem?.singlePrice]);

    const save = async () => {
        setSaving(true);

        item.price = 0;
        item.productIds = [];
        const _items: WarehouseOutItemsType = [];
        item.items?.forEach(
            i => {
                item.price += (i.price || 0);
                _items.push(removeEmptyFields(i)!);
                if (i.productId) {
                    item.productIds.push(i.productId);
                }
            }
        );
        let _data = removeEmptyFields({
            ...item,
            client: removeEmptyFields(item.client),
            items: null,
        });

        try {
            const affectedProducts = new Set<string>();

            if (item.ref) {
                await setDoc(item.ref, _data);

                const whtItemsRef = query(collection(fbDb, "warehouseOutItems"),
                    where("transactionId", "==", item.id));
                const whtItems = await getDocs(whtItemsRef);

                const batch = writeBatch(fbDb);
                for (const item of whtItems.docs) {
                    affectedProducts.add(item.get("productId"));
                    batch.delete(item.ref);
                }
                await batch.commit();
            } else {
                let newItem = await addDoc(collection(fbDb, "warehouseOut"), _data);
                item.id = newItem.id;
            }

            _items?.forEach(
                async i => {
                    const newItem: WarehouseOutItemType = {
                        transactionId: item.id!,
                        transactionDate: item.transactionDate || new Date(),
                        warehouseId: i.warehouseId,
                        productId: i.productId,
                        isOwnProduction: i.isOwnProduction,
                        sku: i.sku,
                        name: i.name,
                        dimens: i.dimens,
                        qty: i.qty,
                        singlePrice: i.singlePrice,
                        price: i.price,
                    };

                    affectedProducts.add(newItem.productId);
                    await addDoc(collection(fbDb, `warehouseOutItems`), newItem);
                }
            );

            affectedProducts.forEach(
                productId => {
                    const product = _products.find(value => value.value.id === productId)?.value as ProductType;
                    const i = _items?.find(item => item.productId === productId);
                    warehouseItemUpdater(item.warehouseId,
                        productId,
                        i?.isOwnProduction || false,
                        product
                    );
                }
            );

            onDone();
        } catch (e: any) {
            showErrorsListInToast("Възникна грешка", [e.message]);
        } finally {
            setSaving(false);
        }
    }


    function removeItem(idx: number) {
        let _item = {...item};
        let _items = (_item.items || []);
        _items.splice(idx, 1);
        setItem(_item);
    }

    const tableRows = useMemo(() => {
        if (item.items && item.items.length > 0) {
            return item.items.map(
                (i, idx) =>
                    <tr key={idx}>
                        <td> {i.sku} </td>
                        <td> {i.name} </td>
                        <td> {i.dimens} </td>
                        <td className={"text-end"}> {zeroToEmptyString(i.qty)} </td>
                        <td className={"text-end"}> {zeroToEmptyStringAsNumber(i.singlePrice)} </td>
                        <td className={"text-end"}> {zeroToEmptyStringAsNumber(i.price)} </td>
                        <td className={"text-end"}>
                            <Button variant={"danger"} size={"sm"} onClick={() => removeItem(idx)}>
                                <FontAwesomeIcon icon={faTrash}/>
                            </Button>
                        </td>
                    </tr>
            )
        }
        return <tr>
            <td colSpan={7}>
                <div className={"text-center fw-bold"}>
                    Добавете продукти
                </div>
            </td>
        </tr>
    }, [item]);

    function addProduct() {
        if (!newItem) return;

        let _item = {...item};
        let newItemItems = (item.items || []);
        newItemItems.push(newItem!);
        _item.items = newItemItems;
        setItem(_item);

        setNewItem(null);
    }

    return (
        <ModalBackground zIndex={zIndex || 1050}>
            <Modal show={true} size={"lg"} style={{zIndex: ((zIndex || 1050) + 1)}}>
                <ModalHeader>
                    <ModalTitle>
                        <FontAwesomeIcon icon={faDolly}/> {item?.id ? "Корекция" : "Добавяне"} на продажба
                    </ModalTitle>
                    <DialogCloseButton onClose={() => onDone()}/>
                </ModalHeader>
                <ModalBody>
                    <LoadingErrorContent loading={false} error={null}>
                        <div className={"ps-2 pe-2"}>
                            <Row>
                                <Col xs={"auto"}>
                                    <Form.Group className={"w-140px"}>
                                        <Form.Label>Дата:</Form.Label>
                                        <ReactDatePicker className={"form-control"} isClearable
                                                         dateFormat="dd.MM.yyyy"
                                                         onChange={(d) => {
                                                             setItem({...item, transactionDate: d || new Date()})
                                                         }}
                                                         selected={item.transactionDate}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <SelectValueFromDialog label={"Клиент"}
                                                           value={item.client?.name}
                                                           onChange={(client: any) => setItem({
                                                               ...item,
                                                               clientId: client.id,
                                                               client: client,
                                                           })}
                                                           dialog={clientsDialog}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Table size={"sm"} bordered hover>
                                        <thead>
                                        <tr>
                                            <th>SKU</th>
                                            <th>Стока</th>
                                            <th>М.Е.</th>
                                            <th className={"text-end w-120px"}>Количество</th>
                                            <th className={"text-end w-120px"}>Ед.Цена</th>
                                            <th className={"text-end w-120px"}>Цена</th>
                                            <th/>
                                        </tr>
                                        </thead>

                                        <tbody>
                                        <LoadingErrorContentTable loading={loadItemItems}
                                                                  error={errorItemItems?.message} colCount={7}>
                                            <>{tableRows}</>
                                        </LoadingErrorContentTable>

                                        <tr>
                                            <td> {newItem?.sku} </td>
                                            <td>
                                                <SelectValueFromDialog size={"sm"} className={"mb-0"}
                                                                       value={newItem?.name}
                                                                       onChange={(item) => {
                                                                           setNewItem({...item} as WarehouseOutItemType)
                                                                       }}
                                                                       dialog={productsDialog}
                                                />
                                            </td>
                                            <td> {newItem?.dimens} </td>
                                            <EditableTableCell inEditMode={editValue === "qty"} isWorking={false}
                                                               value={newItem?.qty?.toString() || ""}
                                                               className={"text-end"}
                                                               onEdit={() => {
                                                                   setEditValue("qty")
                                                               }}
                                                               onBlur={newValue => {
                                                                   setNewItem({
                                                                       ...newItem,
                                                                       qty: textToFloat(newValue)
                                                                   } as WarehouseOutItemType);
                                                                   setEditValue("");
                                                               }}
                                                               onDone={() => {
                                                                   setEditValue("")
                                                               }}/>
                                            <EditableTableCell inEditMode={editValue === "singlePrice"}
                                                               isWorking={false}
                                                               className={"text-end"}
                                                               value={zeroToEmptyStringAsCurrencyWithCommas(newItem?.singlePrice) || ""}
                                                               onEdit={() => {
                                                                   setEditValue("singlePrice")
                                                               }}
                                                               onBlur={newValue => {
                                                                   setNewItem({
                                                                       ...newItem,
                                                                       singlePrice: textToFloat(newValue)
                                                                   } as WarehouseOutItemType);
                                                                   setEditValue("");
                                                               }}
                                                               onDone={() => {
                                                                   setEditValue("")
                                                               }}/>
                                            <RightAlignedCell> {zeroToEmptyStringAsNumber(newItem?.price)} </RightAlignedCell>
                                            <td className={"text-end"}>
                                                <PlusIconButton size={"sm"} onClick={addProduct}
                                                                disabled={editValue != ""}/>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </div>
                    </LoadingErrorContent>
                </ModalBody>
                <Modal.Footer>
                    <Row className={"w-100 ps-0 ms-0"}>
                        {
                            saving && <Col xs={12} className={"text-center"}>
                                <Spinner animation={"border"}/>
                            </Col>
                        }

                        {
                            !saving &&
                            <>
                                <Col xs={6}>
                                    <Button variant={"outline-secondary"} onClick={() => {
                                        onDone()
                                    }}>
                                        <FontAwesomeIcon icon={faCancel}/> Откажи
                                    </Button>
                                </Col>
                                <Col xs={6} className={"text-end"}>
                                    <Button type={"submit"} onClick={() => save()}>
                                        <FontAwesomeIcon icon={faSave}/> Запиши
                                    </Button>
                                </Col>
                            </>
                        }
                    </Row>
                </Modal.Footer>
            </Modal>
        </ModalBackground>
    )
}
