import {
    FirestoreError,QueryConstraint
} from "firebase/firestore";
import {useEffect, useState} from "react";
import useFirebaseCollection from "../../../../hooks/useFirebaseCollection";
import {convertVirtualProductData, ProductionsType, ProductionType,} from "./useProductionOnce";

export default function useProduction(constraints?: QueryConstraint[]): [ProductionsType, boolean, FirestoreError | undefined] {
    const [documents, loading, error] = useFirebaseCollection("production", constraints);
    const [convertedData, setConvertedData] = useState<ProductionsType>([]);

    useEffect(() => {
        if(documents) {
            setConvertedData(convertVirtualProductData(documents.docs));
        }
    }, [documents]);

    return [convertedData, loading, error];
}
