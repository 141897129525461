import {Col, Container, Row, Spinner} from "react-bootstrap";

export default function FullSpaceLoader() {
    return <Container>
        <Row className={"h-100 justify-content-center align-content-center"}>
            <Col className={"text-center"}>
                <Spinner animation={"border"} />
            </Col>
        </Row>
    </Container>
}
