import {FormEvent, FormEventHandler, useMemo, useRef, useState} from "react";

import {DocumentData, updateDoc, addDoc, collection, getFirestore, DocumentReference, doc} from "firebase/firestore";
import {Button, Col, Form, InputGroup, Modal, Row, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faList, faSave} from "@fortawesome/free-solid-svg-icons";
import {fbDb, firebaseApp} from "../../../App";
import {removeEmptyFields, textToFloat, textToInt} from "../../../common";
import {ProductGroup} from "./ProductGroupsList";
import {useHookstate} from "@hookstate/core";
import appDb from "../../../global-state/global-db";
import {ProductsType, ProductType} from "../products/hooks/useProductsOnce";


export interface ProductGroupDetailsProps {
    zIndex?: number;
    group: ProductGroup | null;
    onDone: () => any;
}

type ProductGroupType = {
    id?: string;
    name: string;
    variant: number;
    percent: number | null;
}

export function ProductGroupDetails({
                                 zIndex,
                                 group,
                                 onDone
                             }: ProductGroupDetailsProps) {
    const _products = useHookstate(appDb.products);
    const products = useMemo(() => {
        return _products.value.map(v => {
            return {...v} as ProductType
        })
    }, [_products]);
    const [saving, setSaving] = useState(false);
    const [variant, setVariant] = useState(group?.variant ?? 2);

    async function applyChanges(data: ProductGroupType) {
        const changedProducts: ProductsType = [];
        if (data.variant === 2 || data.percent === null) {
            return;
        }

        products.filter(
            (product) => product.productGroup?.id === group?.id
                && product.deliveryPrice && product.price
        ).forEach(
            (product) => {
                if (data.variant === 0) {
                    product.price = product.deliveryPrice! + (product.deliveryPrice! * data.percent! / 100);
                } else {
                    product.deliveryPrice = product.price! - (product.price! * data.percent! / 100);
                    if (product.deliveryPrice < 0) {
                        product.deliveryPrice = 0;
                    }
                }

                changedProducts.push(product);
            }
        );

        for (let i = 0; i < changedProducts.length; i++) {
            await updateDoc(doc(fbDb, "products", changedProducts[i].id),
                {
                    price: changedProducts[i].price,
                    deliveryPrice: changedProducts[i].deliveryPrice,
                    productGroup: data
                });
        }
    }

    const save = async (data: ProductGroupType) => {
        const groupsRef = collection(fbDb, "productGroups");
        setSaving(true);
        let _data = removeEmptyFields(data);
        if (group?.id) {
            await updateDoc(doc(groupsRef, group?.id), _data);
            if(_data) {
                await applyChanges(_data);
            }
        } else {
            await addDoc(collection(fbDb, "productGroups"), _data);
        }

        setSaving(false);
        onDone();
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();
        event.stopPropagation();

        const data: ProductGroupType = {
            id: group?.id,
            name: event.target.elements.name.value || '',
            variant: variant,
            percent: textToFloat(event.target.elements.percent?.value, null),
        };
        save(data);
    }

    return (
        <><Form className={"p-2"} onSubmit={handleSubmit}>
            <Modal.Body>
                <h5 className={"mb-3"}>
                    {group ? "Корекция" : "Добавяне"} на продуктова група
                </h5>

                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Име</Form.Label>
                            <Form.Control type="text" name="name" defaultValue={group?.name}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Алгоритъм за изчисление на доставна и продажна цена</Form.Label>
                            <div className={"border p-2"}>
                            <Form.Check
                                type={"radio"}
                                label={"Вариант 1 - въвежда се доставна цена на продукта, към която се прибавя % и така се получава продажна цена."}
                                value={0}
                                name={"variant"}
                                checked={variant === 0}
                                onClick={event => setVariant(0)}
                            />
                            <Form.Check
                                type={"radio"}
                                label={"Вариант 2 - въвежда се продажна цена на продукта, от която се вади % и така се получава доставна цена."}
                                value={1}
                                name={"variant"}
                                checked={variant === 1}
                                onClick={event => setVariant(1)}
                            />
                            <Form.Check
                                type={"radio"}
                                label={"Вариант 3 - свободно въвеждане на доставна и продажна цена."}
                                value={2}
                                name={"variant"}
                                checked={variant === 2}
                                onClick={event => setVariant(2)}
                            />
                            </div>
                            {
                                variant !== 2 &&
                                <Form.Group>
                                    <Form.Label>Процент</Form.Label>
                                    <Form.Control name="percent" defaultValue={group?.percent}
                                                  onBlur={(e: any) => e.target.value = textToFloat(e.target.value, "")}
                                    />
                                </Form.Group>
                            }
                        </Form.Group>
                    </Col>

                </Row>

            </Modal.Body>
            <Modal.Footer>
                <Row className={"w-100"}>
                    {
                        saving && <Col xs={12} className={"text-center"}>
                            <Spinner animation={"border"}/>
                        </Col>
                    }

                    {
                        !saving &&
                        <>
                            <Col xs={6}>
                                <Button variant={"outline-secondary"} onClick={() => {
                                    onDone()
                                }}>
                                    <FontAwesomeIcon icon={faArrowLeft}/> Към списъка
                                </Button>
                            </Col>
                            <Col xs={6} className={"text-end"}>
                                <Button type={"submit"}>
                                    <FontAwesomeIcon icon={faSave}/> Запиши
                                </Button>
                            </Col>
                        </>
                    }
                </Row>
            </Modal.Footer>
        </Form>
        </>
    )
}
