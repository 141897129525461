import React from 'react';
import {HeaderComponent} from './HeaderComponent';
import {FooterComponent} from './FooterComponent';
import {ToastContainer} from 'react-toastify';

export function LayoutComponent(props: any) {
    return (
        <>
            <HeaderComponent/>

            <div className="page-content p-3 m-0">
                {props.children}
            </div>

            <nav className="page-footer navbar fixed-bottom">
                <div className="container-fluid">
                    <FooterComponent/>
                </div>
            </nav>

            <ToastContainer/>
        </>
    );
}
