import {Button, Card, Col, Form, Row} from "react-bootstrap";
import {
    importSuppliers,
    importRegions,
    importProducers,
    importProducts,
    importProductAttributes, importClients, importClientLocations
} from "./ImportSuppliers";

export default function ImportDataPage() {


    function onSubmitForm(e: any) {
        e.preventDefault();
        e.stopPropagation();
        switch(e.target.elements.report_type.value) {
            case '1': importSuppliers(e.target.elements.report.value); break;
            case '2': importRegions(e.target.elements.report.value); break;
            case '3': importProducers(e.target.elements.report.value); break;
            case '4': importProducts(e.target.elements.report.value); break;
            case '5': importProductAttributes(e.target.elements.report.value); break;
            case '6': importClients(e.target.elements.report.value); break;
            case '7': importClientLocations(e.target.elements.report.value); break;
        }

    }

    return <>
    <Form onSubmit={onSubmitForm} >
        <Card className={"w-100"}>

            <Card.Header>
                <Card.Title>Импортиране на данни</Card.Title>
            </Card.Header>


            <Card.Body className={"bg-secondary-light"}>
                <Row>
                    <Col className={"card m-1 shadow"}>
<Form.Group>
    <Form.Label>
        Вид на данните
    </Form.Label>
    <Form.Control as={"select"} name={"report_type"}>
        <option value={1}>Доставчици</option>
        <option value={2}>Региони</option>
        <option value={3}>Производители</option>
        <option value={4}>Продукти</option>
        <option value={5}>Продуктови атрибути</option>
        <option value={6}>Клиенти</option>
        <option value={7}>Клиентски локации</option>
    </Form.Control>
</Form.Group>

    <Form.Group>
        <Form.Label>
            Данните в JSON format:
        </Form.Label>
        <Form.Control as="textarea" rows={20} name={"report"} />
    </Form.Group>

                    </Col>
                </Row>
            </Card.Body>

            <Card.Footer>
                <Button title={"Импортирай"} type={"submit"}>Импортирай</Button>
            </Card.Footer>
        </Card>
    </Form>
    </>
}
