import {
    collection,
    FirestoreError,
    query,
    DocumentData,
    QuerySnapshot,
    QueryConstraint
} from "firebase/firestore";
import {fbDb} from "../App";
import {useCollection} from "react-firebase-hooks/firestore";

export default function useFirebaseCollection(path: string, constraints?: QueryConstraint[],
                                                 converter?: any): [(QuerySnapshot<DocumentData> | undefined), boolean, (FirestoreError | undefined)] {

    const ref = constraints ? query(collection(fbDb, path), ...constraints)
            .withConverter(converter) :
        query(collection(fbDb, path));

    const [documents, loading, error] = useCollection(ref);

    if (error) {
        console.error(error);
    }

    return [documents, loading, error];
}
