import React, {FC, useEffect, useState} from "react";
import {Col, Container, Form, FormGroup, Row, Tab, Tabs} from "react-bootstrap";
import {dateToString} from "../../../../common";
import CustomerOfferDetailsProducts from "../CustomerOfferDetailsProducts";
import CustomerOfferDetailsAttributes from "../CustomerOfferDetailsAttributes";
import CustomerOfferDetailsServices from "../CustomerOfferDetailsServices";
import CustomerOfferDetailsSummary from "../CustomerOfferDetailsSummary";
import {CustomerOfferType} from "../../../../fb-converters/cutomerOffer-converter";
import {CustomerInquiryType} from "../../../../fb-converters/cutomerInquiry-converter";
import I18Label, {getProductI18Name} from "../../../../i18/i18label";

interface Props {
    zIndex?: number;
    inquiry: CustomerInquiryType
    offer: CustomerOfferType;
    onChange: (newOffer: CustomerOfferType) => void;
}

const CustomerOfferDetailsTabOffer: FC<Props> = (props) => {
    const [offer, setOffer] = useState<CustomerOfferType>(props.offer);

    useEffect(() => props.onChange(offer), [offer]);

    return (
        <>
            <h4 className={"mb-3 fw-bold"}>
                {offer.ref ? <I18Label label={"Корекция на оферта"} />: <I18Label label={"Добавяне на оферта"} /> }
            </h4>

            <Row className={"mb-3"}>
                <Col xs={"auto"}>
                    <FormGroup>
                        <Form.Label><I18Label label={"Номер"} />:</Form.Label>
                        <Form.Control disabled
                                      defaultValue={`${props.inquiry.inquiryNumber} - ${offer.offerNumber || "x"}`}/>
                    </FormGroup>
                </Col>
                <Col xs={"auto"}>
                    <FormGroup>
                        <Form.Label><I18Label label={"Дата"} />:</Form.Label>
                        <Form.Control disabled defaultValue={dateToString(offer.offerDate)}/>
                    </FormGroup>
                </Col>
                <Col xs={"auto"}>
                    <Form.Label><I18Label label={"EUR курс"} />:</Form.Label>
                    <Form.Control disabled
                                  defaultValue={offer.eurExchangeRate.toFixed(4) + " лв"}/>
                </Col>
                <Col>
                    <Form.Label><I18Label label={"Начин на плащане"} />:</Form.Label>
                    <Form.Control
                        value={offer.paymentMethod || ""}
                        onChange={e => setOffer({...offer, paymentMethod: e.target.value})}
                    />
                </Col>
                <Col>
                    <Form.Label><I18Label label={"Срок на валидност"} />:</Form.Label>
                    <Form.Control
                        value={offer.validityPeriod || ""}
                        onChange={e => setOffer({...offer, validityPeriod: e.target.value})}
                    />
                </Col>
            </Row>
            <Row className={"mb-5"}>
                <Col>
                    <Form.Label><I18Label label={"Забележки"} />:</Form.Label>
                    <Form.Control
                        as={"textarea"}
                        value={offer.comment || ""}
                        onChange={e => setOffer({...offer, comment: e.target.value})}
                    />
                </Col>
            </Row>


            <CustomerOfferDetailsProducts
                zIndex={props.zIndex}
                offer={offer}
                setOffer={newOffer => setOffer(newOffer)}
                discount_price={props.inquiry.client?.discount_price || 0}
                bestProductPriceIdx={offer.bestProductPriceIdx}
                setBestProductPriceIdx={idx => setOffer({...offer, bestProductPriceIdx: idx})}
            />


            <Tabs defaultActiveKey={0}>
                {
                    offer.products.map(
                        (p, idx) =>
                            p && <Tab key={idx} title={getProductI18Name(p.product.name)} eventKey={idx}>
                                <Container fluid className={"border p-3"}>
                                    {
                                        p.attributes.length > 0 &&
                                        <CustomerOfferDetailsAttributes
                                            zIndex={props.zIndex}
                                            attributes={p.attributes}
                                            setAttributes={attributes => setOffer(prevState => {
                                                const _offer = {...prevState};
                                                _offer.products[idx].attributes = attributes;
                                                return _offer;
                                            })}
                                            eurExchangeRate={offer.eurExchangeRate}
                                            discount_price={props.inquiry.client?.discount_price || 0}
                                            qty={p.qty}
                                        />
                                    }

                                    <CustomerOfferDetailsServices
                                        zIndex={props.zIndex}
                                        services={p.services}
                                        setServices={services => setOffer(prevState => {
                                            const _offer = {...prevState};
                                            _offer.products[idx].services = services;
                                            return _offer;
                                        })}
                                        eurExchangeRate={offer.eurExchangeRate}
                                        discount_price={props.inquiry.client?.discount_price || 0}
                                        qty={p.qty}
                                    />
                                </Container>
                            </Tab>
                    )
                }
            </Tabs>

            <CustomerOfferDetailsSummary offer={offer}/>


        </>
    );
}

export default CustomerOfferDetailsTabOffer;
