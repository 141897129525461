import {Button, Col, Row} from "react-bootstrap";
import {CrmPeriodProps} from "./CrmCalendar";
import {useEffect, useState} from "react";
import {ARRAY_MONTHS_FULL} from "../../common";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import UserDropdownSelector from "./UserDropdownSelector";
import I18Label from "../../i18/i18label";


export default function CrmPeriodSelector({period, periodType, changePeriodType, createActivity, onMoveActivitiesCallback}: CrmPeriodProps) {
    const [_period, set_Period] = useState(period);
    useEffect(() => {
        let _period = {...period};

        switch (periodType) {
            case "day":
                _period.activity = undefined;
                break;
            case "month":
                _period.day = undefined;
                _period.activity = undefined;
                break;
            case "year":
                _period.month = undefined;
                _period.day = undefined;
                _period.activity = undefined;
                break;
        }
        set_Period(_period);
    }, [period]);


    const _btnYearLabel = <><I18Label label={"Година"} />: <strong className={"text-primary"}>{period.year.toString()}</strong></>;
    const _btnMonthLabel = <><I18Label label={"Месец"} />: <strong
        className={"text-primary"}><I18Label label={ARRAY_MONTHS_FULL[period.month || 0]} /></strong></>;
    const _btnDayLabel = <><I18Label label={"Ден"}/>: <strong className={"text-primary"}>{period.day?.toString()}</strong></>;

    return <Row className={"bg-secondary-light p-1"}>
        <Col>
            <Button type={"button"} variant={"outline-secondary"}
                    onClick={() => changePeriodType("year", _period)}>{_btnYearLabel}</Button>&nbsp;
            {
                typeof (_period.month) == "number" && <>
                    <span>&gt;&nbsp;</span>
                    <Button type={"button"} variant={"outline-secondary"}
                            onClick={() => changePeriodType("month", _period)}>{_btnMonthLabel}</Button>&nbsp;
                </>
            }
            {
                _period.day && <>
                    <span>&gt;&nbsp;</span>
                    <Button type={"button"} variant={"outline-secondary"}
                            onClick={() => changePeriodType("day", _period)}>{_btnDayLabel}</Button>&nbsp;
                </>
            }
            {
                periodType === "activity" && <>
                    <span>&gt;&nbsp;</span>
                    <Button type={"button"} variant={"outline-secondary"}
                            onClick={() => changePeriodType("activity", {
                                ..._period,
                                activity: undefined
                            })}><I18Label label={"Активности"}/></Button>&nbsp;

                </>
            }
        </Col>
        <Col>
            <Row className={"justify-content-end"}>
                <Col xs={"auto"}>
                    {
                        onMoveActivitiesCallback &&
                        <>
                            <UserDropdownSelector onSelectUser={(userId) => onMoveActivitiesCallback(userId)} />
                        </>
                    }
                </Col>
                <Col xs={"auto"}>
                    {
                        createActivity && <>
                            <Button type={"button"} variant={"primary"}
                                    onClick={() => createActivity()}>
                                <FontAwesomeIcon icon={faPlus}/> <I18Label label={"Добави активност"} />
                            </Button>
                        </>
                    }
                </Col>
            </Row>
        </Col>
    </Row>
}
