import {
    Modal,
    ModalTitle,
} from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import React, {useEffect, useState} from "react";
import {DIALOG_OPTIONS} from "../../../types";
import {DocumentData} from "firebase/firestore";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faIndustry} from "@fortawesome/free-solid-svg-icons";
import ModalBackground from "../../../common/ModalBackground";
import {ProducersList} from "./ProducersList";
import {ProducerDetail} from "./ProducerDetails";
import {ProducerDelete} from "./ProducerDelete";
import DialogCloseButton from "../../../common/DialogCloseButton";

export function ProducersDialog({zIndex, show, onClose, onSelect}: DIALOG_OPTIONS) {

    const [viewMode, setViewMode] = useState<"VIEW_LIST" | "VIEW_ADD" | "VIEW_EDIT" | "VIEW_DELETE">('VIEW_LIST');
    const [editDocument, setEditDocument] = useState<DocumentData | null>(null);
    const [deleteDocument, setDeleteDocument] = useState<DocumentData | null>(null);
    const [addDocument, setAddDocument] = useState(false);

    useEffect(() => {
        setViewMode(editDocument ? "VIEW_EDIT" : "VIEW_LIST");
    }, [editDocument])

    useEffect(() => {
        setViewMode(deleteDocument ? "VIEW_DELETE" : "VIEW_LIST");
    }, [deleteDocument])

    useEffect(() => {
        setViewMode(addDocument ? "VIEW_ADD" : "VIEW_LIST");
    }, [addDocument])

    return show ?
        <ModalBackground zIndex={zIndex}>
            <Modal show={true} style={{zIndex: (zIndex + 1)}}>
                <ModalHeader>
                    <ModalTitle>
                        <FontAwesomeIcon icon={faIndustry}/> Производители
                    </ModalTitle>
                    <DialogCloseButton onClose={() => onClose(null)} />
                </ModalHeader>
                {
                    <ProducersList onClose={onClose} isVisible={viewMode === "VIEW_LIST"}
                                 onSelect={onSelect}
                                 onAdd={() => setAddDocument(true)}
                                 onEdit={(doc: DocumentData) => {
                                     setEditDocument(doc);
                                 }}
                                 onDelete={(doc: DocumentData) => {
                                     setDeleteDocument(doc);
                                 }}
                    />
                }
                {
                    viewMode === "VIEW_EDIT" && editDocument &&
                    <ProducerDetail doc={editDocument}
                                  zIndex={(zIndex || 1100) + 10}
                                  onDone={() => {
                                      setEditDocument(null)
                                  }}/>
                }
                {
                    viewMode === "VIEW_DELETE" && deleteDocument &&
                    <ProducerDelete doc={deleteDocument} onDone={() => {
                        setDeleteDocument(null)
                    }}/>
                }
                {
                    viewMode === "VIEW_ADD" && addDocument &&
                    <ProducerDetail doc={null}
                                  zIndex={(zIndex || 1100) + 10}
                                  onDone={() => {
                                      setAddDocument(false)
                                  }}/>
                }

            </Modal>
        </ModalBackground>
        : null
}
