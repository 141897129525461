import {
    DocumentData,
    DocumentReference,
    FirestoreError,
    QueryConstraint,
    QueryDocumentSnapshot,
    where
} from "firebase/firestore";
import {useEffect, useState} from "react";
import useFirebaseCollection from "../../../hooks/useFirebaseCollection";

export interface WarehouseOutItemType {
    id?: string;
    ref?: DocumentReference;
    transactionId: string;
    transactionDate: Date;
    warehouseId: string;
    productId: string;
    isOwnProduction: boolean;
    sku?: string;
    name: string;
    dimens?: string;
    qty: number;
    singlePrice: number;
    price: number;
    availableQty?: number;
}
export type WarehouseOutItemsType = WarehouseOutItemType[];


export function convertWarehouseOutItemData(_objects: QueryDocumentSnapshot<DocumentData>[]): WarehouseOutItemsType {
    if(_objects) {
        return _objects.map(
            d => {
                return {
                    id: d.id,
                    ref: d.ref,
                    transactionId: d.get("transactionId"),
                    transactionDate: d.get("transactionDate"),
                    warehouseId: d.get("warehouseId"),
                    productId: d.get("productId"),
                    isOwnProduction: d.get("isOwnProduction") || false,
                    sku: d.get("sku"),
                    name: d.get("name"),
                    dimens: d.get("dimens"),
                    qty: d.get("qty"),
                    price: d.get("price"),
                    singlePrice: d.get("singlePrice"),
                } as WarehouseOutItemType;
            }
        );
    } else return [];
}


export default function UseWarehouseOutItems(outId?: string, constraints?: QueryConstraint[]): [WarehouseOutItemsType, boolean, FirestoreError | undefined] {
    const finalConstraints = constraints || [];
    if(outId) {
        finalConstraints.push(where("transactionId", "==", outId));
    }
    const [documents, loading, error] = useFirebaseCollection("warehouseOutItems", finalConstraints);
    const [convertedData, setConvertedData] = useState<WarehouseOutItemsType>([]);

    useEffect(() => {
        if(documents) {
            setConvertedData(convertWarehouseOutItemData(documents.docs));
        }
    }, [documents]);

    return [convertedData, loading, error];
}
