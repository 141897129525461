import {FC, useEffect, useState} from "react";
import {Button, Col, Form, Modal, Row, Spinner, Table,} from "react-bootstrap";
import {CustomerInquiryType} from "../../../fb-converters/cutomerInquiry-converter";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileDownload, faUpload} from "@fortawesome/free-solid-svg-icons";
import CancelIconButton from "../../../common/icon-buttons/CancelIconButton";
import DialogCloseButton from "../../../common/DialogCloseButton";
import {dateToString, showErrorsListInToast, zeroToEmptyStringAsNumber} from "../../../common";
import {fStorage} from "../../../App";
import {
    listAll,
    ListResult,
    ref,
    uploadBytesResumable,
    getDownloadURL,
    StorageReference,
    deleteObject
} from "firebase/storage";
import TrashIconButton from "../../../common/icon-buttons/TrashIconButton";
import RightAlignedCell from "../../../common/RightAlignedCell";
import CheckIconButton from "../../../common/icon-buttons/CheckIconButton";
import ModalBackground from "../../../common/ModalBackground";
import I18Label from "../../../i18/i18label";

interface Props {
    zIndex?: number;
    inquiry: CustomerInquiryType;
    onClose: VoidFunction;
}


const CustomerInquiryFiles: FC<Props> = (props) => {
    const [working, setWorking] = useState(false);
    const [deleteFile, setDeleteFile] = useState<StorageReference | undefined>(undefined);
    const [inquiry] = useState(props.inquiry);
    const [percent, setPercent] = useState(0);
    const [file, setFile] = useState<any>("");
    const [files, setFiles] = useState<ListResult>();
    const [links, setLinks] = useState<Map<string, string>>(new Map());

    useEffect(() => {
        getFilesList()
    }, []);

    function handleChange(event: any) {
        setFile(event.target.files[0]);
    }

    async function getFilesList() {
        try {
            setWorking(true);
            setPercent(0);
            setFile(undefined);

            const docId = inquiry.id;
            const listRef = ref(fStorage, `/customer_inquiries/${docId}`);
            const list = await listAll(listRef);
            setFiles(list);
        } catch (e: any) {
            showErrorsListInToast("Грешка", [`Възникна грешка при опит да се прикачи този файл. Грешка:\n${e.message}`]);
            console.error(e);
        } finally {
            setWorking(false);
        }
    }

    function uploadFile() {
        try {
            setWorking(true);
            const docId = inquiry.id;
            if (docId) {
                const storageRef = ref(fStorage, `/customer_inquiries/${docId}/${file.name} [${(file.type).replace('/', '\\')}]`);
                const uploadTask = uploadBytesResumable(storageRef, file);

                uploadTask.on(
                    "state_changed",
                    (snapshot) => {
                        const percent = Math.round(
                            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                        );
                        setPercent(percent);
                    },
                    (err) => {
                        showErrorsListInToast("Грешка", [`Възникна грешка при опит да се прикачи този файл. Грешка:\n${err.message}`]);
                        console.error(err);
                        getFilesList();
                    },
                    async () => {
                        getFilesList();
                    }
                );

            }
        } catch (e: any) {
            showErrorsListInToast("Грешка", [`Възникна грешка при опит да се прикачи този файл. Грешка:\n${e.message}`]);
            console.error(e);
            setWorking(false);
        }
    }

    async function getLinkToFiles() {
        const _links = new Map<string, string>()
        for (const f of files?.items || []) {
            const url = await getDownloadURL(f);
            _links.set(f.name, url);
        }
        setLinks(_links);
    }

    useEffect(() => {
        if (files && files.items) {
            getLinkToFiles();
        } else setLinks(new Map());
    }, [files]);

    async function removeCustomerFile(f: StorageReference) {
        try {
            setWorking(true);
            await deleteObject(f);
        } catch (e: any) {
            showErrorsListInToast("Грешка", [`Възникна грешка при опит да се прикачи този файл. Грешка:\n${e.message}`]);
            console.error(e);
        } finally {
            setWorking(false);
            getFilesList();
            setDeleteFile(undefined);
        }
    }

    const zIndex = (props.zIndex || 1200) + 200;
    return <ModalBackground zIndex={zIndex}>
        <Modal show={true} size={"xl"} style={{zIndex: zIndex + 1}}>
            <Modal.Header>
                <Modal.Title><I18Label label={"ДОКУМЕНТИ КЪМ ЗАПИТВАНЕ ОТ КЛИЕНТ"} />
                    No: {inquiry.inquiryNumber} / {dateToString(inquiry.inquiryDate)}</Modal.Title>
                <DialogCloseButton onClose={props.onClose}/>
            </Modal.Header>

            <Modal.Body>
                <Table>
                    <thead>
                    <tr>
                        <th><I18Label label={"Име на файла"} /></th>
                        <th className={"w-75px"}/>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        !files || !files.items.length ? <tr>
                                <td colSpan={2} className={"text-center"}>
                                    <strong><I18Label label={"Няма прикачени документи"} /></strong></td>
                            </tr> :
                            files.items.map(
                                f => <tr key={f.name}>
                                    <td> {f.name} </td>
                                    <RightAlignedCell>
                                        {
                                            deleteFile && deleteFile === f ?
                                                <>
                                                    <CheckIconButton onClick={() => removeCustomerFile(f)}/>&nbsp;
                                                    <CancelIconButton onClick={() => setDeleteFile(undefined)}/>
                                                </> :
                                                <>
                                                    {
                                                        links && links.has(f.name) &&
                                                        <a href={links.get(f.name)} target={"_blank"}
                                                           className={"btn btn-secondary btn-small"}>
                                                            <FontAwesomeIcon icon={faFileDownload}/>
                                                        </a>
                                                    }&nbsp;
                                                    <TrashIconButton onClick={() => setDeleteFile(f)}/>
                                                </>
                                        }
                                    </RightAlignedCell>
                                </tr>
                            )
                    }
                    </tbody>
                </Table>
            </Modal.Body>

            <Modal.Footer className={"text-end"}>
                {
                    working ? <Row>
                            <Col>
                                <Spinner animation={"border"}/>&nbsp;{zeroToEmptyStringAsNumber(percent, "%")}
                            </Col>
                        </Row>
                        :
                        <>
                            <Form.Control type="file" onChange={handleChange} accept="/image/*"/>
                            <Button onClick={uploadFile} disabled={!file}>
                                <FontAwesomeIcon icon={faUpload}/>&nbsp;&nbsp;<I18Label label={"Добави файл"} />
                            </Button>
                            <CancelIconButton onClick={props.onClose}/>
                        </>
                }
            </Modal.Footer>
        </Modal>
    </ModalBackground>
}

export default CustomerInquiryFiles;

