import {DocumentData} from "firebase/firestore";
import {Button, Col, Modal, Row, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faCircleArrowDown, faEdit, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import React, {useMemo, useState} from "react";
import {ClientLocationDialog} from "./ClientLocationDialog";
import {ClientLocationDelete} from "./ClientLocationDelete";
import {ClientType} from "../hooks/useClients";
import {useHookstate} from "@hookstate/core";
import appDb from "../../../../global-state/global-db";
import {ClientLocationType} from "../../../../fb-converters/location-converter";
import I18Label from "../../../../i18/i18label";

export interface ClientDetailsLocationsProps {
    zIndex?: number;
    doc: ClientType;
    onDone: () => {}
    onSelect?: (result: any) => any;
}

export function ClientDetailsLocations({
                                           zIndex,
                                           doc,
                                           onDone,
                                           onSelect
                                       }: ClientDetailsLocationsProps) {

    const [editLocation, setEditLocation] = useState<DocumentData | null>(null);
    const [editMode, setEditMode] = useState(false);
    const [deleteLocation, setDeleteLocation] = useState<DocumentData | null>(null);

    const _locations = useHookstate(appDb.clientsLocations).value;

    const locations = useMemo(
        () => {
            if(doc.id) {
                return _locations
                    .filter(l => l.clientId === doc.id)
                    .map(l => { return {...l} as ClientLocationType})
            }
        }, [doc?.id, _locations]);

    const showLocationDialog = function (location: DocumentData | null) {
        setEditLocation(location);
        setEditMode(true);
    }

    const showDeleteDialog = function (location: DocumentData) {
        setDeleteLocation(location);
    }


    return (
        <>
            {
                !deleteLocation &&
                <>
                    <Modal.Body>
                        <Table>
                            <thead>
                            <tr>
                                <th></th>
                                <th><I18Label label={"Наименование"} /></th>
                                <th><I18Label label={"Регион"} /></th>
                                <th><I18Label label={"Адрес"} /></th>
                                <th><I18Label label={"Отговорно лице"} /></th>
                                <th><I18Label label={"Контакт"} /></th>
                                <th><I18Label label={"E-mail"} /></th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                locations && locations.length === 0 && <tr>
                                    <td colSpan={6} className={"text-center fw-bold"}><I18Label label={"Няма въведени локации"} /></td>
                                </tr>
                            }

                            {
                                locations && locations.length > 0 &&
                                locations.map(
                                    l => <tr key={l.id}>
                                        <td>
                                            <Button size={"sm"} variant={"outline-secondary"}
                                                    onClick={() => showLocationDialog(l)}
                                            >
                                                <FontAwesomeIcon icon={faEdit}/>
                                            </Button>&nbsp;
                                            {
                                                locations.length > 1 &&
                                                <><Button size={"sm"} variant={"outline-danger"}
                                                        onClick={() => {
                                                            showDeleteDialog(l)
                                                        }}
                                                >
                                                    <FontAwesomeIcon icon={faTrash}/>
                                                </Button>&nbsp;</>
                                            }
                                            {
                                                onSelect &&
                                                <Button size={"sm"} variant={"outline-success"}
                                                        onClick={() => {
                                                            onSelect({...l, id: l.id})
                                                        }}
                                                >
                                                    <FontAwesomeIcon icon={faCircleArrowDown}/>
                                                </Button>
                                            }
                                        </td>
                                        <td> {l.name} </td>
                                        <td> {l.region} </td>
                                        <td> {l.address} </td>
                                        <td> {l.mol} </td>
                                        <td> {l.phone} </td>
                                        <td> {l.email} </td>
                                    </tr>
                                )
                            }
                            </tbody>
                        </Table>
                    </Modal.Body>

                    <Modal.Footer>
                        <Row className={"w-100"}>
                            <Col xs={6}>
                                <Button variant={"outline-secondary"} onClick={() => {
                                    onDone()
                                }}>
                                    <FontAwesomeIcon icon={faArrowLeft}/> <I18Label label={"Към списъка"} />
                                </Button>
                            </Col>
                            <Col xs={6} className={"text-end"}>
                                <Button type={"submit"} onClick={() => showLocationDialog(null)}>
                                    <FontAwesomeIcon icon={faPlus}/> <I18Label label={"Добави локация"} />
                                </Button>
                            </Col>
                        </Row>
                    </Modal.Footer>
                </>}


            {
                editMode && <ClientLocationDialog zIndex={zIndex || 1022}
                                                  client={doc!}
                                                  location={editLocation}
                                                  onDone={() => setEditMode(false)}/>
            }

            {
                deleteLocation && <ClientLocationDelete location={deleteLocation} onDone={() => {
                    setDeleteLocation(null)
                }}/>
            }
        </>
    )
}
