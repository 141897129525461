import {CrmPeriodProps} from "./CrmCalendar";
import {useEffect, useState} from "react";
import CrmPeriodYearViewer from "./CrmPeriodYearViewer";
import CrmPeriodMonthViewer from "./CrmPeriodMonthViewer";
import CrmPeriodDayViewer from "./CrmPeriodDayViewer";
import CrmPeriodActivitesViewer from "./CrmPeriodActivitiesViewer";
import ActivityDetails from "./ActivityDetails";
import {useDocument} from "react-firebase-hooks/firestore";
import {doc, getFirestore} from "firebase/firestore";
import {fbDb, firebaseApp} from "../../App";
import {Spinner} from "react-bootstrap";


export default function CrmPeriodViewer({period, periodType, changePeriodType, onSetMoveActivitiesCallback}: CrmPeriodProps) {
    const [periodViewer, setPeriodViewer] = useState<any>();

    const [statistics, loadStatistics, errorStatistics] = useDocument(
        doc(fbDb, 'activities', 'statistics')
    );

    useEffect(() => {
        if(!statistics) {
            setPeriodViewer(<Spinner animation={"border"} />);
            return;
        }

        switch (periodType) {
            case "year": setPeriodViewer(<CrmPeriodYearViewer period={period} periodType={periodType} changePeriodType={changePeriodType} statistics={statistics} />); break;
            case "month": setPeriodViewer(<CrmPeriodMonthViewer period={period} periodType={periodType} changePeriodType={changePeriodType} statistics={statistics} />); break;
            case "day": setPeriodViewer(<CrmPeriodDayViewer period={period} periodType={periodType} changePeriodType={changePeriodType} statistics={statistics} />); break;
            case "activity": if(period.activity) {
                setPeriodViewer(<ActivityDetails period={period} periodType={periodType} changePeriodType={changePeriodType} statistics={statistics} />);
            } else {
                setPeriodViewer(<CrmPeriodActivitesViewer period={period} periodType={periodType}
                                                          changePeriodType={changePeriodType}
                                                          statistics={statistics}
                                                          onSetMoveActivitiesCallback={cb => {
                                                              if(onSetMoveActivitiesCallback) onSetMoveActivitiesCallback(cb);
                                                          }}
                />);
            }
            break;
        }

    }, [period, statistics]);



    return <>
        {
            periodViewer
        }
    </>
}
