import {Spinner} from "react-bootstrap";
import LoadDataFromServerError from "./LoadDataFromServerError";

export interface LoadingErrorContentProps {
    loading: boolean;
    error: string | null | undefined;
    children: JSX.Element;
}

export default function LoadingErrorContent(props: LoadingErrorContentProps) {
    if(props.loading) return <div className={"text-center"}><Spinner animation={"border"} /></div>

    if(props.error) return  <div className={"text-center text-danger fw-bold"}><LoadDataFromServerError /></div>

    return props.children;
}
