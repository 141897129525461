import {Spinner} from "react-bootstrap";
import LoadDataFromServerError from "./LoadDataFromServerError";

interface LoadingErrorContentTableProps {
    loading: boolean;
    error: string | null | undefined;
    colCount: number;
    children: JSX.Element;
}

export default function LoadingErrorContent(props: LoadingErrorContentTableProps) {
    if(props.loading) return <tr><td colSpan={props.colCount}> <div className={"text-center"}><Spinner animation={"border"} /></div></td></tr>

    if(props.error) return <tr><td colSpan={props.colCount}> <div className={"text-center text-danger fw-bold"}><LoadDataFromServerError /></div></td></tr>

    return props.children;
}
