import {Card, Col, Row} from "react-bootstrap";
import CrmReportActivitiesFilter, {CrmReportActivitiesFilterType} from "./CrmReportActivitiesFilter";
import {useEffect, useState} from "react";
import {dateWithZeroTime} from "../../../../common";
import CrmReportActivitiesResults from "./CrmReportActivitiesResults";
import I18Label from "../../../../i18/i18label";

export default function CrmReportActivitiesPage() {
    const today = dateWithZeroTime(new Date())!;
    const [filter, setFilter] = useState<CrmReportActivitiesFilterType>({});

    useEffect(() => {
        const monthFirstDay = dateWithZeroTime(new Date())!;
        monthFirstDay.setDate(0);
        setFilter({
            fromDate: monthFirstDay,
            toDate: today,
        })
    }, []);


    function applyFilter(newFilter: CrmReportActivitiesFilterType) {
        setFilter(newFilter);
    }

    return <>

        <Card className={"w-100"}>

            <Card.Header>
                <Card.Title><I18Label label={"CRM - Справка за активности"} /></Card.Title>
            </Card.Header>


            <Card.Body className={"bg-secondary-light"}>
                <CrmReportActivitiesFilter filter={filter}
                                           onChange={newFilter => applyFilter(newFilter)} />

                <CrmReportActivitiesResults filter={filter} />

            </Card.Body>

        </Card>


    </>
}
