import {Link, Text, View, Image as PdfFImage} from "@react-pdf/renderer";
import {styles} from "./OfferPdfStyles";
import whyToChoose from "../../../../../../assets/images/why_to_choose.png";
import React from "react";

const OfferPdfStaticPage1 = () => (
    <View wrap={false} style={{ marginLeft: 30, lineHeight: 1.75, fontSize: 10 }}>
        <Text style={{ fontWeight: "bold" }}>
            Допълнителна информация към офертата:
        </Text>
        <Text style={styles.deliveryItem}>
            &bull; Техническа скица на батерията (прикачен файл)
        </Text>
        <View style={[styles.deliveryItem, { paddingTop: 10 }]}>
            <Text style={{ fontWeight: "bold" }}>
                Защо да изберете „Индустриални батерии“ ЕООД за ваш доставчик:
            </Text>
            <PdfFImage src={whyToChoose} style={{ width: "75%", margin: 5 }} />
            <Text>
                • Оптимален експлоатационен срок на батерията и изгодна обща цена на притежание
            </Text>
            <Text>
                • Над 20 години опит в производството на индустриални батерии
            </Text>
            <Text>
                • Световни сертификати ISO 9001:2015 и ISO 14001:2015
            </Text>
            <Text>
                • Качествени компоненти от европейски доставчици
            </Text>
            <Text>
                • Финално асемблиране в България
            </Text>
            <Text>
                • Безупречно гаранционно обслужване
            </Text>
            <Text>
                - Линкове:
            </Text>
            <View style={[styles.deliveryItem, {  }]}>
                <Text>
                    Продукти ТАБ: <Link>https://industrialbatteries.bg/editor/files/TAB_Traction_EN.pdf</Link>
                </Text>
                <Text>
                    Продукти Perpetuum: <Link>https://industrialbatteries.bg/perpetuum</Link>
                </Text>
                <Text>
                    Продукти Искра: <Link>http://elhim-iskra.com/tyagovi_baterii_tip_pzs_80v_(2_x_40v)-p-23.html</Link>
                </Text>
            </View>
        </View>
    </View>
);

export default OfferPdfStaticPage1;
