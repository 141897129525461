import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {API} from "../api";
import {faUser} from "@fortawesome/free-solid-svg-icons";
import {Dropdown} from "react-bootstrap";
import {auth} from "../App";
import { signOut } from "firebase/auth";



export function UserNameComponent() {
    const logOut = () => {
        signOut(auth);
    }

    return (
            <>
                {
                    !API.user &&
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                }
                {
                    API.user &&
                    <Dropdown>
                        <Dropdown.Toggle variant="outline-primary" className={"text-dark"}>
                                <FontAwesomeIcon icon={faUser} size="sm" /> {API.user.name}&nbsp;
                        </Dropdown.Toggle>&nbsp;

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => logOut()}>Изход</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>



                }
            </>
    );
}
