import {doc, collection, getDocs, setDoc, getFirestore, writeBatch, deleteDoc} from "firebase/firestore";
import {fbDb, firebaseApp} from "../../App";
import {removeEmptyFields, textToFloat} from "../../common";



export function importSuppliers(jsonString) {
    importJson(jsonString, "suppliers", data => data);
}

export function importProducers(jsonString) {
    importJson(jsonString, "producers", data => data);
}


export function importRegions(jsonString) {
    importJson(jsonString, "regions",
        data => {
            return {...data, country: ((/[а-яА-ЯЁё]/.test(data.name)) ? 'България' : 'Румъния' )}
        });
}


export async function importProducts(jsonString) {
    const suppliers = await loadCollectionDocs("suppliers");
    const producers = await loadCollectionDocs("producers");

    importJson(jsonString, "products",
        data => {
            return {
                connectorType: getConnectorType(data.connector_type),
                outputType: getOutputType(data.pins_type),
                description: data.product_description,
                descriptionForClient: data.client_description,
                height1: data.height_one,
                height2: data.height_two,
                _length: data["length"],
                width: data.width,
                name: data.name,
                price: (data.sell_price / 100),
                deliveryPrice: (data.delivery_price / 100),
                producer: getNameFromCollectionById(producers, data.manufacturer_id),
                supplier: getNameFromCollectionById(suppliers, data.supplier_id),
                sku: data.sku,
                weightWithElectrolit: data.electrolyte_weight / 1000,
                weightWithoutElectrolit: data.no_electrolyte_weight / 1000,
            }
        });
}


export async function importProductAttributes(jsonString) {
    const fStore = getFirestore(firebaseApp);
    const products = await loadCollectionDocs("products");
    const jsonData = JSON.parse(jsonString);
    let batch = writeBatch(fStore);
    let batchCnt = 0;

    for (const prodKey in products) {
        let product = products[prodKey];
        let attr = [];
        for (const attrKey in jsonData) {
            let data = jsonData[attrKey];
            if(data.product_id == product.id) {
                attr.push({name: data.attribute_name, price: data.attribute_price / 100});
            }
        }
        batch.set(doc(fbDb, "products", product.id), {...product.data(), attributes: attr});
        batchCnt++;

        if(batchCnt === 500) {
            await batch.commit();
            console.log('commit');
            batchCnt = 0;
            batch = writeBatch(fbDb);
        }
    }
    await batch.commit();
    console.log('final commit');
}



export async function importClients(jsonString) {
    const regions = await loadCollectionDocs("regions");

    importJson(jsonString, "clients",
        data => {
            return {
                address: data.registration_address,
                bulstat: data.pid,
                email: data.email,
                mol: data.accountable_person,
                name: data.name,
                phone: data.phone,
                region: getFieldFromCollectionById(regions, data.region_id, 'name'),
                country: getFieldFromCollectionById(regions, data.region_id, 'country'),
                sector: data.sector,
                sizeGroup: getClientType(data.type),
                website: data.website,
                discount_price: data.discount,
                discount_eco: data.eco_tax,
                discount_charge: data.charge,
                discount_transport: data.transport,
                sku: data.sku,
            }
        });
}

export async function importClientLocations(jsonString) {
    const clients = await loadCollectionDocs("clients");
    let client;

    importJson(jsonString, "clientLocations",
        data => {
            client = findInCollectionById(clients, data.customer_id);
            console.log(client);
            if(!client) return null;
            return {
                clientId: data.customer_id,
                clientName: client.data().name,
                sizeGroup: client.data().sizeGroup,
                address: data.address,
                email: data.accountable_person_mail,
                mol: data.accountable_person,
                phone: data.contact_data,
                religion: data.accountable_person_religion,
                position: data.accountable_person_position,
                machinesCnt: data.owned_machines_cnt,
                machines: data.owned_machines,
                country: client.data().country,
                region: client.data().region,
            }
        });
}


// =================================================================

export async function loadCollectionDocs(collectionName) {
    const db = getFirestore(firebaseApp);
    const _collection = collection(db, collectionName);
    const currentSnap = await getDocs(_collection);
    return currentSnap.docs;
}


function processColleactions(skipToIdx, currentData, jsonData, onDelete, onAdd) {
    const batchMaxSize = 500;
    let bCnt = 0;

    for (const currItemIdx in currentData) {
        const currItem = currentData[currItemIdx];
        const item = jsonData.find((i) => i.id == currItem.id);
        if (!item) {
            if(bCnt < batchMaxSize) {
                onDelete(currItem.id);
                bCnt++;
                console.log("delete ", currItem);
            } else break;
        } else {
            // console.log("keep ", currItem);
        }
    }

    let skip = skipToIdx !== null;
    let currItemIdx;
    for (currItemIdx in jsonData) {
        if(skip && currItemIdx === skipToIdx) {
            skip = false;
        }

        if(!skip) {
            console.log("Item #" + currItemIdx.toString());
            const currItem = jsonData[currItemIdx];
            // const item = currentData?.find(i => i.id == currItem.id);
            // if (!item) {
            if(bCnt < batchMaxSize) {
                console.log("add ", currItem);
                onAdd(currItem);
                bCnt++;
            } else break;
            // } else {
            // console.log("skip ", currItem);
            // }
        }
    }

    return (bCnt !== batchMaxSize) ? null : currItemIdx;
}

async function importJson(jsonString, collectionName, beforeAdd) {
    const db = getFirestore(firebaseApp);
    const jsonData = JSON.parse(jsonString).map(i => i);


    console.log(jsonData);

    let currentData = await loadCollectionDocs(collectionName);

    console.log(currentData);

    jsonData.forEach(
        jd => {
            const item = currentData.find(
                cd => jd.id === cd.id
            );

            if(!item) {
                console.log(jd);
            }
        }
    )
    return;
    //
    // let hasMore = true;
    // let skipToIdx = null;
    //
    // while (hasMore) {
    //     // const batch = writeBatch(db);
    //     skipToIdx = processColleactions(skipToIdx, currentData, jsonData,
    //         (id) => {
    //             // batch.delete(doc(db, collectionName, id));
    //             debugger
    //             console.log("DELETE: ", id);
    //         },
    //         (currItem) => {
    //             const itemToAdd = removeEmptyFields(beforeAdd(currItem));
    //             if(itemToAdd) {
    //                 // batch.set(doc(db, collectionName, currItem.id), {...itemToAdd, imported: 1});
    //                 console.log("SET: ", itemToAdd);
    //             }
    //         });
    //     // await batch.commit();
    //     hasMore = skipToIdx !== null;
    //
    //     currentData = await loadCollectionDocs(collectionName);
    //     console.log("Current collection size: " + currentData.length)
    //     console.log("Has more: ", hasMore)
    // };


}

function getConnectorType(connector_type) {
    switch (connector_type) {
        case 0 : return "Гъвкави връзки";
        case 1 : return "Твърди връзки";
        case 2 : return "няма";
    }
}

function getOutputType(connector_type) {
    switch (connector_type) {
        case 0 : return "Под болт";
        case 1 : return "БДС";
        case 2 : return "DIN";
        case 3 : return "няма";
    }
}

function getClientType(clientType) {
    switch (clientType.toString()) {
        case '0' : return "Ключов клиент/дилър";
        case '1' : return "Голям клиент";
        case '2' : return "Среден клиент";
        case '3' : return "Малък клиент";
    }
    return '';
}

export function findInCollectionById(collection, id) {
    return collection.find(i => i.id == id);
}

export function getNameFromCollectionById(collection, id) {
    const item = findInCollectionById(collection, id);
    return item ? item.data().name : null;
}
export function getFieldFromCollectionById(collection, id, fieldName) {
    const item = findInCollectionById(collection, id);
    return item ? item.data()[fieldName] : null;
}
