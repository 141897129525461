import {useState} from "react";

import {DocumentData, getFirestore, deleteDoc} from "firebase/firestore";
import {Button, Col, Form, Modal, Row, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faSave} from "@fortawesome/free-solid-svg-icons";
import {httpsCallable} from "firebase/functions";
import {functions} from "../../../App";


export interface ClientDeleteProps {
    doc: DocumentData | null;
    onDone: () => any;
}

export function UserDelete({doc, onDone}: ClientDeleteProps) {
    const [deleting, setDeleting] = useState(false);

    const deleteUser = async function (email: string) {
        const userRedux = httpsCallable(functions, 'userReduxService');
        await userRedux({
            action: 'delete',
            email: email
        })
    }

    const deleteRegion = async () => {
        const email = doc?.data().email;
        setDeleting(true);
        await deleteDoc(doc?.ref);
        await deleteUser(email);
        setDeleting(false);
        onDone();
    }

    return (
        <>
            <Modal.Body>
                <h5 className={"mb-3"}>
                    Изтриване на потребител
                </h5>

                <div className={"text-danger"}>
                    Моля, потвърдете изтриването на потребителя <strong className={"text-primary"}>{doc?.data().name}</strong>!
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Row className={"w-100"}>
                    {
                        deleting && <Col xs={12} className={"text-center"}>
                            <Spinner animation={"border"}/>
                        </Col>
                    }

                    {
                        !deleting &&
                        <>
                            <Col xs={6}>
                                <Button variant={"outline-secondary"} onClick={() => {
                                    onDone()
                                }}>
                                    <FontAwesomeIcon icon={faArrowLeft}/> Към списъка
                                </Button>
                            </Col>
                            <Col xs={6} className={"text-end"}>
                                <Button onClick={() => deleteRegion()} variant={"danger"}>
                                    <FontAwesomeIcon icon={faSave}/> Изтрий
                                </Button>
                            </Col>
                        </>
                    }
                </Row>
            </Modal.Footer>
        </>
    )
}
