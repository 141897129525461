import {Button, CloseButton, Col, Container, Form, Modal, Row, Table} from "react-bootstrap";
import {dateToString} from "../../../../../common";
import ModalBackground from "../../../../../common/ModalBackground";
import CenterAlignedCell from "../../../../../common/CenterAlignedCell";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPrint} from "@fortawesome/free-solid-svg-icons";
import {useReactToPrint} from "react-to-print";
import {useRef} from "react";
import "../invoice/InvoicePrintPreview.css";
import {ProductionCardType} from "../../../../production/types/ProductionCardType";
import I18Label, {getProductI18Name} from "../../../../../i18/i18label";

interface Props {
    zIndex?: number;
    card: ProductionCardType;
    onDismiss: () => void;
}

const ACTION_LIST = ["ФАКТУРА",
    "ИНВОЙС",
    "ЧМР/ТОВАРИТЕЛНИЦА",
    "СТОКОВА РАЗПИСКА/ППП",
    "ПОТВЪРЖДЕНИЕ ЗА ПОЛУЧАВАНЕ",
    "ГАРАНЦИОННА КАРТА",
    "ДОКУМЕНТАЦИЯ СТАРА БАТЕРИЯ",
    "КАБЕЛНИ ОБУВКИ/КЛЕМИ",
    "КРАЙНИ КАБЕЛИ",
    "ГЪВКАВИ СЪЕДИНИТЕЛИ - ТИП",
    "СИСТЕМА ЗА ДОЛИВАНЕ",
    "АРЕОМЕТЪР",
    "ПРОМЯНА СТИКЕРИ",
    "ТРАНСПОРТ ДО КЛИЕНТА",
    "МОНТАЖ",
    "ИЗКУПУВАНЕ НА СТАРАТА БАТЕРИЯ",
    "РЕКЛАМНИ МАТЕРИАЛИ"];

export default function ProductionPlanPrintPreview({zIndex, card, onDismiss}: Props) {
    const _zIndex = zIndex || 1000;
    const componentRef = useRef(null);

    const printContent = (
        <Container fluid ref={componentRef} className={"p-5 pt-2 pb-2"}>
            <div className={"d-none d-print-block m-3"}>&nbsp;</div>
            <Row>
                <h2 className={"text-uppercase w-100 text-center"}><I18Label label={"КАРТА ЗА ВЪЗЛАГАНЕ КЪМ СЕРВИЗА"} /></h2>
                <h5 className={"w-100 text-center"}><I18Label label={"за клиент"} /> <b>{card.invoiceClientName}</b></h5>
            </Row>

            <Row className={"mt-5"}>
                <Col className={"border rounded-1 p-2"}>
                    <Row>
                        <Col xs={3}>
                            <h5><I18Label label={"Номер"} />:</h5>
                        </Col>
                        <Col className={"text-end"}>
                            <h5><b>{card.cardNumber.toString().padStart(6, '0')}</b></h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={3}>
                            <h5><I18Label label={"Дата"} />:</h5>
                        </Col>
                        <Col className={"text-end"}>
                            <h5><b>{dateToString(card.cardDate)}</b></h5>
                        </Col>
                    </Row>
                </Col>
                <Col className={"border rounded-1 p-2"}>
                    <Row>
                        <Col xs={5}>
                            <h5 className={"text-nowrap"}><I18Label label={"Запитв.No:"} /></h5>
                        </Col>
                        <Col className={"text-end"}>
                            <h5><b>{card.inquiryNumber?.toString().padStart(6, '0')}</b></h5>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={5}>
                            <h5><I18Label label={"Дата"} />:</h5>
                        </Col>
                        <Col className={"text-end"}>
                            <h5><b>{dateToString(card.inquiryDate)}</b></h5>
                        </Col>
                    </Row>
                </Col>
                <Col className={"border rounded-1 p-2"}>
                    <Row>
                        <Col xs={5}>
                            <h5 className={"text-nowrap"}><I18Label label={"Поръчка No:"} /></h5>
                        </Col>
                        <Col className={"text-end"}>
                            <h5><b>{card.invoiceNumber.toString().padStart(6, '0')}</b></h5>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={5}>
                            <h5><I18Label label={"Дата"} />:</h5>
                        </Col>
                        <Col className={"text-end"}>
                            <h5><b>{dateToString(card.invoiceDate)}</b></h5>
                        </Col>
                    </Row>
                </Col>
                <Col className={"border rounded-1 p-2"} xs={4}>
                    <Row>
                        <Col xs={7}>
                            <h5><I18Label label={"Относно"} />:</h5>
                        </Col>
                        <Col className={"text-end"}>
                            <h5><b>
                                <I18Label label={card.regarding} />
                            </b></h5>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={"7"}>
                            <h5><I18Label label={"Място на изв."} />:</h5>
                        </Col>
                        <Col className={"text-end"}>
                            <h5><b>
                                <I18Label label={card.productionPlace} />
                            </b></h5>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row className={"mt-5"}>
                <Col>
                    <h5><b><I18Label label={"СПЕЦИФИКАЦИЯ"} />:</b></h5>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Table bordered>
                        <thead>
                        <tr>
                            <th className={"text-center print-no-bg"}>№</th>
                            <th className={"text-center print-no-bg"}><I18Label label={"Тип на стоката"} /></th>
                            <th className={"text-center print-no-bg"} colSpan={4}><I18Label label={"Размери"} /> (L/B/H1/H2) mm</th>
                            <th className={"text-center print-no-bg"}><I18Label label={"Брой"} /></th>
                            <th className={"text-center print-no-bg"}><I18Label label={"Конектори"} /></th>
                            <th className={"text-center print-no-bg"}><I18Label label={"Тип на изводите"} /></th>
                            <th className={"text-center print-no-bg"}><I18Label label={"Заряд"} /></th>
                            <th className={"text-center print-no-bg"}><I18Label label={"Дренажни отв."} /></th>
                        </tr>
                        </thead>
                        <tbody>
                        {card.specification.map((spec, index) => (
                            <tr key={index}>
                                <CenterAlignedCell>{index + 1}</CenterAlignedCell>
                                <CenterAlignedCell>{ getProductI18Name(spec.productName) } : </CenterAlignedCell>
                                <CenterAlignedCell>{spec.productSize.l}</CenterAlignedCell>
                                <CenterAlignedCell>{spec.productSize.b}</CenterAlignedCell>
                                <CenterAlignedCell>{spec.productSize.h1}</CenterAlignedCell>
                                <CenterAlignedCell>{spec.productSize.h2}</CenterAlignedCell>
                                <CenterAlignedCell>{spec.productQty}</CenterAlignedCell>
                                <CenterAlignedCell>
                                    <I18Label label={spec.connectors} />
                                </CenterAlignedCell>
                                <CenterAlignedCell>
                                    <I18Label label={spec.outputType} />
                                </CenterAlignedCell>
                                <CenterAlignedCell>{spec.charging && <Form.Check checked={true}/>}</CenterAlignedCell>
                                <CenterAlignedCell>
                                    {spec.drenageHoles &&
                                    <Form.Check checked={true}/>}
                                </CenterAlignedCell>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>

            <Row className={"mt-5"}>
                <Col>
                    <h5><b><I18Label label={"ДОПЪЛНИТЕЛНИ ИЗИСКВАНИЯ"} />:</b></h5>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className={"border border-2 rounded border-dark p-3"}>
                        {card.notes}
                    </div>
                </Col>
            </Row>

            <Row className={"mt-5"}>
                <Col>
                    <h5><b><I18Label label={"НЕОБХОДИМИ МАТЕРИАЛИ"} />:</b></h5>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Table bordered>
                        <thead>
                        <tr className={"print-no-bg"}>
                            <th className={"text-center print-no-bg"}>№</th>
                            <th className={"text-center print-no-bg"}><I18Label label={"Тип на стоката"} /></th>
                            <th className={"text-center print-no-bg"} colSpan={4}><I18Label label={"Размери"} /> (L/B/H1/H2) mm</th>
                            <th className={"text-center print-no-bg"}><I18Label label={"К-во"} /></th>
                            <th className={"text-center print-no-bg"}><I18Label label={"Ед."} /></th>
                            <th className={"text-center print-no-bg"}><I18Label label={"Доставчик"} /></th>
                            <th className={"text-center print-no-bg"}><I18Label label={"Дата на доставка"} /></th>
                            <th className={"text-center print-no-bg"}><I18Label label={"Адрес на доставка"} /></th>
                        </tr>
                        </thead>
                        <tbody>
                        {card.materials.map((material, index) => (
                            <tr key={index}>
                                <CenterAlignedCell>{index + 1}</CenterAlignedCell>
                                <CenterAlignedCell>{ getProductI18Name(material.productName) }</CenterAlignedCell>
                                <CenterAlignedCell>{material.productSize.l}</CenterAlignedCell>
                                <CenterAlignedCell>{material.productSize.b}</CenterAlignedCell>
                                <CenterAlignedCell>{material.productSize.h1}</CenterAlignedCell>
                                <CenterAlignedCell>{material.productSize.h2}</CenterAlignedCell>
                                <CenterAlignedCell>{material.productQty}</CenterAlignedCell>
                                <CenterAlignedCell>
                                    <I18Label label={material.productDimension} />
                                </CenterAlignedCell>
                                <CenterAlignedCell>{material.supplier}</CenterAlignedCell>
                                <CenterAlignedCell>{dateToString(material.supplyDate)}</CenterAlignedCell>
                                <CenterAlignedCell>{material.supplyAddress}</CenterAlignedCell>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>

            <Row className={"mt-5"}>
                <Col>
                    <h5><b><I18Label label={"УСЛОВИЯ НА ДОСТАВКА КЪМ КЛИЕНТ"} />:</b></h5>
                </Col>
            </Row>
            <Row>
                <Col xs={"auto"}>
                    <I18Label label={"Транспорт"} />: <b><I18Label label={"card.transport"} /></b>
                </Col>
                <Col>
                    <I18Label label={"Адрес"} />: <b>{card.address}</b>
                </Col>
            </Row>
            <Row>
                <Col>
                    <I18Label label={"Лице за контакти"} />: <b>{card.personForContact}</b>
                </Col>
                <Col>
                    <I18Label label={"Телефон"} />: <b>{card.phone}</b>
                </Col>
                <Col xs={"auto"} style={{paddingRight: "120px"}}>
                    <I18Label label={"Краен срок за доставка"} />: <b>{dateToString(card.deliveryDate)}</b>
                </Col>
            </Row>
            <Row>
                <Col>
                    <I18Label label={"Допълнителни изисквания"} />: <b>{card.deliveryNote}</b>
                </Col>
            </Row>


            <Row className={"mt-5"}>
                <Col>
                    <h5><b><I18Label label={"РЕАЛИЗАЦИЯ"} />:</b></h5>
                </Col>
            </Row>
            <Row>
                <Col xs={"2"}>
                    <I18Label label={"На дата"} />: <span
                    className={"d-sm-inline-block w-100 border border-2 border-dark lh-2-5em rounded"}>&nbsp;</span>
                </Col>
                <Col>
                    <I18Label label={"От"} />: <span
                    className={"d-sm-inline-block w-100 border border-2 border-dark lh-2-5em rounded"}>&nbsp;</span>
                </Col>
            </Row>
            <Row>
                <Col>
                    <I18Label label={"Се извърши следното"} />:
                    <span
                        className={"d-sm-inline-block w-100 border border-2 border-dark lh-2-5em rounded"}>&nbsp;</span>
                    <span
                        className={"d-sm-inline-block w-100 border border-2 border-dark lh-2-5em rounded mt-1"}>&nbsp;</span>
                </Col>
            </Row>

            <div className="pagebreak"></div>

            <div className={"m-5 mt-3 mb-2"}>&nbsp;</div>
            <Row>
                <h2 className={"text-uppercase w-100 text-center"}><I18Label label={"ОБХОДЕН ЛИСТ"} /></h2>
            </Row>

            <Row className={"mt-3"}>
                <Col>
                    <Table bordered>
                        <thead>
                        <tr>
                            <th className={"text-center print-no-bg"}><I18Label label={"ДЕЙСТВИЕ"} /></th>
                            <th className={"text-center print-no-bg"}><I18Label label={"ДА"} /></th>
                            <th className={"text-center print-no-bg"}><I18Label label={"НЕ"} /></th>
                            <th className={"text-center w-340px print-no-bg "}><I18Label label={"ИЗВЪРШИЛ"} /></th>
                            <th className={"text-center print-no-bg"}><I18Label label={"ПОДПИС"} /></th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            ACTION_LIST.map(
                                (action, index) => (
                                    <tr key={index} className={"lh-2"}>
                                        <td><I18Label label={action} /></td>
                                        <CenterAlignedCell><Form.Check/></CenterAlignedCell>
                                        <CenterAlignedCell><Form.Check/></CenterAlignedCell>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                )
                            )
                        }
                        </tbody>
                    </Table>
                </Col>
            </Row>

            <Row className={"mt-5"}>
                <Col>
                    <I18Label label={"КООРДИНАТОР"} />: ___________________________________
                </Col>
                <Col className={"text-end"}>
                    <I18Label label={"ОТГОВОРНИК ПЛАСМЕНТ"} />: __________________________________
                </Col>
            </Row>

            <Row className={"mt-5"}>
                <h2 className={"mt-5 text-uppercase w-100 text-center"}>
                    <I18Label label={"ПРОТОКОЛ ЗА ОСТАТЪЧНИ МАТЕРИАЛИ"} />
                </h2>
            </Row>

            <Row className={"mt-3"}>
                <Col>
                    <Table bordered>
                        <thead>
                        <tr>
                            <th className={"text-center w-50 print-no-bg"}><I18Label label={"НАИМЕНОВАНИЕ"} /></th>
                            <th className={"text-center print-no-bg"}><I18Label label={"БРОЙ"} /></th>
                            <th className={"text-center print-no-bg"}><I18Label label={"КОЛИЧЕСТВО"} /></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr><td>&nbsp;</td><td></td><td></td></tr>
                        <tr><td>&nbsp;</td><td></td><td></td></tr>
                        <tr><td>&nbsp;</td><td></td><td></td></tr>
                        <tr><td>&nbsp;</td><td></td><td></td></tr>
                        <tr><td>&nbsp;</td><td></td><td></td></tr>
                        <tr><td>&nbsp;</td><td></td><td></td></tr>
                        <tr><td>&nbsp;</td><td></td><td></td></tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>

            <Row className={"mt-5"}>
                <Col>
                    <I18Label label={"ОТГОВОРНИК СЕРВИЗ"} />: ___________________________________
                </Col>
            </Row>

        </Container>
    );

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    function handleClose() {
        onDismiss();
    }

    return (
        <ModalBackground zIndex={_zIndex}>
            <Modal show style={{zIndex: _zIndex + 1}} size={"xl"}>
                <Modal.Header>
                    <Modal.Title><I18Label label={"Печат на документ"} /></Modal.Title>
                    <CloseButton onClick={() => onDismiss()}/>
                </Modal.Header>
                <Modal.Body className={"p-5"}>
                    {printContent}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        <I18Label label={"Затвори"} />
                    </Button>
                    <Button variant="primary" onClick={handlePrint}>
                        <FontAwesomeIcon icon={faPrint} className={"me-2"}/>
                        <I18Label label={"Печат"} />....
                    </Button>
                </Modal.Footer>
            </Modal>
        </ModalBackground>
    );
}
