import {StyleSheet} from "@react-pdf/renderer";

export const styles = StyleSheet.create({
    headerLine: {
        height: 16,
        border: 1,
        borderColor: "silver",
        padding: 1,
        paddingLeft: 5,
        marginTop: 5,
        borderBottomStyle: "dotted",
        borderLeftStyle: "solid",
        borderTop: 0,
        borderRight: 0,
        flexDirection: "row",
        justifyContent: "space-between"
    },
    card: {
        margin: 10,
        marginLeft: 0,
        marginRight: 0,
        border: 1,
        padding: 2,
        borderStyle: "dotted",
        borderLeft: 0,
        borderRight: 0,
        flexDirection: "column",
        justifyContent: "space-between",
    },
    body: {
        paddingTop: 135,
        paddingBottom: 50,
        paddingHorizontal: 35,
        fontSize: 9,
        fontFamily: "Roboto",
        fontWeight: "normal",
        borderColor: "silver",
    },
    headerImage: {
        position: 'absolute',
        top: 5,
        left: 35,
        right: 35,
    },
    footerImage: {
        position: 'absolute',
        bottom: 15,
        left: "20%",
        right: "20%",
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 8,
        bottom: 5,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey'
    },

    col1H: { width: 20, fontWeight: "bold", backgroundColor: "silver", textAlign: "center", padding: 2, },
    col2H: { width: 280, fontWeight: "bold", backgroundColor: "silver", textAlign: "left", padding: 2, },
    col3H: { width: 100, fontWeight: "bold", backgroundColor: "silver", textAlign: "center", padding: 2, },
    col4H: { width: 80, fontWeight: "bold", backgroundColor: "silver", textAlign: "center", padding: 2, },
    col5H: { width: 80, fontWeight: "bold", backgroundColor: "silver", textAlign: "center", padding: 2, },
    col6H: { width: 80, fontWeight: "bold", backgroundColor: "silver", textAlign: "center", padding: 2, },
    col7H: { width: 80, fontWeight: "bold", backgroundColor: "silver", textAlign: "center", padding: 2, },
    col8H: { width: 80, fontWeight: "bold", backgroundColor: "silver", textAlign: "center", padding: 2, },
    col9H: { width: 80, fontWeight: "bold", backgroundColor: "silver", textAlign: "center", padding: 2, },
    col10H: { width: 80, fontWeight: "bold", backgroundColor: "silver", textAlign: "center", padding: 2, },
    col11H: { width: 120, fontWeight: "bold", backgroundColor: "silver", textAlign: "center", padding: 2, },



    col1: { width: 20, textAlign: "center", padding: 2, },
    col2: { width: 280, textAlign: "left", padding: 2, },
    col3: { width: 100, textAlign: "center", padding: 2, },
    col4: { width: 80, textAlign: "center", padding: 2, },
    col5: { width: 80, textAlign: "center", padding: 2, },
    col6: { width: 80, textAlign: "center", padding: 2, },
    col7: { width: 80, textAlign: "center", padding: 2, },
    col8: { width: 80, textAlign: "center", padding: 2, },
    col9: { width: 80, textAlign: "center", padding: 2, },
    col10: { width: 80, textAlign: "center", padding: 2, },
    col11: { width: 120, textAlign: "right", padding: 2, paddingRight: 18 },
    col9_10: { width: 160, textAlign: "center", padding: 2, },
    col9_10_11: { width: 280, textAlign: "right", padding: 2, },

    col11Green: { width: 120, backgroundColor: "lightgreen",
        textAlign: "right",
        paddingTop: 5, paddingBottom: 5, paddingRight: 2 },

    col1Yellow: { width: 120, backgroundColor: "gold",
        textAlign: "right",
        paddingTop: 5, paddingBottom: 5, paddingRight: 2 },

    tableRowWrapper: {
        flexDirection: "row",
        borderTop: 0,
        borderBottom: 1,
        borderColor: "silver",
    },
    deliveryItem: {
        paddingLeft: 20,
    }

});
