import {FC, useEffect, useState} from "react";
import appDb from "../global-state/global-db";
import {addDoc, collection, getDocs, query, updateDoc, where} from "firebase/firestore";
import {fbDb} from "../App";
import i18Converter, {I18ItemType} from "../fb-converters/i18-converter";
import {Col, Row} from "react-bootstrap";
import {ServiceType} from "../fb-converters/service-converter";

interface Props {
    label: string | null | undefined;
    control?: string;
    language?: "bg" | "ro";
}

export const getI18Text = (text: string, language?: string, control?: string): string => {
    const i18 = appDb.i18.value;
    let lng = language ? language : appDb.language.value;
    text = text.trim();

    const i18Item =
        i18.find(i => i.bg === text && (!control || i.control === control)) as I18ItemType;
    if (i18Item && lng === "ro" && i18Item.ro) {
        return i18Item.ro;
    }
    return text;
}

export const getProductI18Name = (name: string, language?: string): string => {
    const lng = language ? language : appDb.language.value;
    const products = appDb.products.value;
    if(lng === "bg" || !name) {
        return name;
    }

    const product = products.find(p => p.name === name);
    if(product && product.descriptionForClient && product.descriptionForClient.trim().length > 0) {
        return product.descriptionForClient.trim();
    }
    return name;
}

export const getServeceI18Name = (name: string, language?: string): string => {
    const lng = language ? language : appDb.language.value;
    const services = appDb.services.value;
    if(lng === "bg" || !name) {
        return name;
    }
    const service = services.find(s => s.name === name);
    return service ? service.name_ro ?? service.name : name;
}

export const getServeceI18Description = (description: string, language?: string): string => {
    const lng = language ? language : appDb.language.value;
    const services = appDb.services.value;
    if(lng === "bg" || !description) {
        return description;
    }
    const service = services.find(s => s.description === description);
    return service ? service.description_ro ?? service.description : description;
}

const I18Label: FC<Props> = ({label, control, language}) => {
    const i18 = appDb.i18.value;
    let lng = language ? language : appDb.language.value;
    const showI18Data = appDb.markI18Labels.value;
    const [showI18, setShowI18] = useState(showI18Data);
    const [inEditMode, setInEditMode] = useState(false);
    const [editValue, setEditValue] = useState(label ?? "");
    const i18Item = i18.find(i => i.bg === label && (!control || i.control === control));
    useEffect(() => {
        if (i18Item) {
            setEditValue(i18Item.ro || i18Item.bg);
        }
    }, [i18Item]);

    useEffect(() => {
        setShowI18(showI18Data);
    }, [showI18Data]);

    async function updateI18Label() {
        const conditions = [where('bg', '==', label)];
        if (control) {
            conditions.push(where('control', '==', control));
        }
        const snapshot = await getDocs(query(collection(fbDb, 'i18'), ...conditions).withConverter(i18Converter));
        if (snapshot.empty) {
            await addDoc(collection(fbDb, 'i18'), {bg: label, ro: editValue, control: control ?? null});
        } else {
            for (const doc of snapshot.docs) {
                await updateDoc(doc.ref, {ro: editValue});
            }
        }
    }

    function onLabelDblClick(e: React.WheelEvent<HTMLSpanElement>) {
        if (!showI18) return;
        e.stopPropagation();
        e.preventDefault();

        const newMode = e.deltaY > -1;
        if (inEditMode && !newMode) {
            updateI18Label();
        }

        setInEditMode(newMode);
    }

    if (!label) {
        return null;
    }

    if (lng === "bg" || !label) {
        return <>{label}</>
    }

    return <span
        className={showI18 ? "border-bottom border-danger" : ""}
        onWheel={e => onLabelDblClick(e)}
    >
        {inEditMode ? <Row className={"flex-nowrap p-0 m-0 gap-0"}>
                <Col className={"p-0 m-0"}>
                    <input type={"text"} value={editValue} onChange={
                        (e) => {
                            setEditValue(e.target.value.trim());
                        }
                    }
                           onKeyPress={(e) => {
                               if (e.key === "Enter") {
                                   updateI18Label();
                                   setInEditMode(false);
                               }
                           }}
                    />
                </Col>
            </Row>
            : (i18Item && i18Item.ro) ? i18Item.ro : label}
    </span>
}

export const getI18Price = (singlePrice: number, eurExchangeRate: number = 1)=> {
    const lng = appDb.language.value;
    if(lng === "ro") {
        return singlePrice / eurExchangeRate;
    }
    return singlePrice;
}
export const getI18PriceInLv = (singlePrice: number, eurExchangeRate: number = 1)=> {
    const lng = appDb.language.value;
    if(lng === "ro") {
        return singlePrice * eurExchangeRate;
    }
    return singlePrice;
}

export default I18Label;
