import {DocumentReference} from "firebase/firestore";

export type I18ItemType = {
    id?: string;
    ref?: DocumentReference
    bg: string;
    ro: string;
    control?: string;
}
export type I18ItemsType = I18ItemType[];

const i18Converter = {
    toFirestore: (i18: I18ItemType) => {
        return {
            bg: i18.bg,
            ro: i18.ro,
            control: i18.control
        }
    },
    fromFirestore: (snapshot: any) => {
        const data = snapshot.data();
        return {
            id: snapshot.id,
            ref: snapshot.ref,
            bg: data.bg,
            ro: data.ro,
            control: data.control
        } as I18ItemType;
    }
}

export default i18Converter;
