import {Card, Col, Row} from "react-bootstrap";
import CrmCalendar from "./CrmCalendar";
import {CrmCampaigns} from "./CrmCampaigns";
import {useState} from "react";
import {CrmPeriod} from "../../types";
import moment from "moment/moment";
import I18Label from "../../i18/i18label";


export default function CrmPage() {
    const [period, setPeriod] = useState<CrmPeriod>({
        year: moment().year(),
        month: moment().month(),
        day: moment().date(),
    });

    return <>

        <Card className={"w-100"}>

            <Card.Header>
                <Card.Title><I18Label label={"CRM - Активности"} /></Card.Title>
            </Card.Header>


            <Card.Body className={"bg-secondary-light"}>
                <Row>
                    <Col className={"card shadow"}>
                        <h3><I18Label label={"Календар"} /></h3>
                        <CrmCalendar period={period} onChangePeriod={p => setPeriod(p) }/>
                    </Col>

                    <Col className={"card shadow"}>
                        <h3><I18Label label={"Кампании"} /></h3>
                        <CrmCampaigns period={period}/>
                    </Col>
                </Row>
            </Card.Body>

        </Card>


    </>
}
