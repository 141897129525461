import {Button, Form, ModalBody, ModalFooter, Spinner, Tab, Table, Tabs} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCancel, faCircleArrowDown, faEdit, faPlus, faSearch, faTrash} from "@fortawesome/free-solid-svg-icons";
import {useCollection, useCollectionData} from "react-firebase-hooks/firestore";
import {collection, getFirestore, query, where, DocumentData} from "firebase/firestore";
import {fbDb, firebaseApp} from "../../../App";
import {useEffect, useState} from "react";
import LoadDataFromServerError from "../../../common/LoadDataFromServerError";
import SmallBorderedHooveredTable from "../../../common/SmallBorderedHooveredTable";


export interface PositionsListProps {
    isVisible: boolean;
    onAdd: () => any;
    onEdit: (item: DocumentData) => any;
    onDelete: (item: DocumentData) => any;
    onClose: (result: any) => any;
    onSelect?: (result: any) => any;
}

export function PositionsList({isVisible, onAdd, onEdit, onDelete, onClose, onSelect}: PositionsListProps) {

    const [filterName, setFilterName] = useState('')
    const [positions, loadingPositions, errorPositions] = useCollection(
        collection(fbDb, "positions")
    );

    const [filtered, setFiltered] = useState<any[]>([])

    useEffect(() => {
        if (positions) {
            setFiltered(positions.docs?.filter(
                r => (!filterName || filterName.length === 0 || r.data().name.toLowerCase().indexOf(filterName.toLowerCase()) > -1)
            ));
        } else setFiltered([]);
    }, [positions, filterName]);

    function isLoading() {
        return loadingPositions;
    }

    return (
        <>
            {isLoading() && <Spinner animation={"border"}/>}

            {
                !isLoading() && errorPositions && <LoadDataFromServerError/>
            }

            {
                !isLoading() && !errorPositions && positions && isVisible &&
                <>
                    <ModalBody>
                        <div className={"max-h-60vh scrollable"}>
                            <SmallBorderedHooveredTable>
                                <thead style={{backgroundColor: "white"}}>
                                <tr>
                                    <th style={{width: onSelect ? 120 : 90}}></th>
                                    <th>Име</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className={"text-start"}>
                                        <FontAwesomeIcon icon={faSearch}/>
                                    </td>
                                    <td>
                                        <Form.Control size={"sm"} value={filterName}
                                                      onChange={(e) => setFilterName(e.target.value)}/>
                                    </td>
                                </tr>
                                {
                                    isLoading() &&
                                    <tr>
                                        <td colSpan={6} className={"text-center fw-bold"}>
                                            <Spinner animation={"border"}/>
                                        </td>
                                    </tr>
                                }
                                {
                                    filtered && filtered.length === 0 &&
                                    <tr>
                                        <td colSpan={6} className={"text-center fw-bold"}>Няма позиции</td>
                                    </tr>
                                }
                                {
                                    filtered && filtered.length > 0 && filtered.map(
                                        r =>
                                            <tr key={r.id} className={"tr-bordered"}>
                                                <td>
                                                    <Button size={"sm"} variant={"outline-secondary"}
                                                            onClick={() => onEdit(r)}
                                                    >
                                                        <FontAwesomeIcon icon={faEdit}/>
                                                    </Button>&nbsp;
                                                    <Button size={"sm"} variant={"outline-danger"}
                                                            onClick={() => {
                                                                onDelete(r)
                                                            }}
                                                    >
                                                        <FontAwesomeIcon icon={faTrash}/>
                                                    </Button>&nbsp;
                                                    {
                                                        onSelect &&
                                                        <Button size={"sm"} variant={"outline-success"}
                                                                onClick={() => {
                                                                    onSelect(r)
                                                                }}
                                                        >
                                                            <FontAwesomeIcon icon={faCircleArrowDown}/>
                                                        </Button>
                                                    }
                                                </td>
                                                <td>
                                                    {r.data().name}
                                                </td>
                                            </tr>
                                    )
                                }
                                </tbody>
                            </SmallBorderedHooveredTable>
                        </div>
                    </ModalBody>

                    <ModalFooter>
                        <Button onClick={() => onAdd()}>
                            <FontAwesomeIcon icon={faPlus}/> Добави нова
                        </Button>
                        <Button variant={"outline-secondary"} onClick={() => {
                            onClose(null)
                        }}>
                            <FontAwesomeIcon icon={faCancel}/> Затвори
                        </Button>
                    </ModalFooter>
                </>
            }
        </>
    )
}
