import {DocumentReference} from "firebase/firestore";
import {ClientType} from "./pages/nomenclatures/clients/hooks/useClients";
import {ClientLocationType} from "./fb-converters/location-converter";

export const MONTHS_NAMES: string[] = ['Януари', 'Февруари', 'Март', 'Април', 'Май', 'Юни', 'Юли', 'Август', 'Септември', 'Октомври', 'Ноември', 'Декември'];

export type IBVoidFunction = () => void;
export type IBVoidFunctionWithUserId = (userId: string) => void | null;

export interface IdName {
    id: number,
    name: string
}

export interface Account {
    id: string;
    name: string;
    email: string;
    position: string;
    positionName: string;
    phone: string;
    isAdmin: boolean;
    isManager: boolean;
    isSalesRepresentative: boolean;
    isServiceWorker: boolean;
    language: "bg" | "ro";
    activeTo: Date;
}

export interface DIALOG_OPTIONS {
    zIndex: number;
    show: boolean;
    onClose: (result: any) => any;
    onSelect?: (result: any) => any;
    onMultiSelect?: (result: any) => any;
    onSelectLocation?: (selected: {client: ClientType, location: ClientLocationType}) => void;
}

// =====================================================================================================================

export type CrmPeriodType = "year" | "month" | "day" | "activity";

export type ActivityType = "изходящо" | "входящо" | "среща" | "задача" | "напомняне";
export const ActivityTypes = ["изходящо", "входящо", "среща", "задача", "напомняне"];

export type ActivityStatus = "" | "приет" | "отложен" | "не отговаря" | "изпълнено" | "отказано/отменено" | "прието предложение" | "отказано предложение";
export const ActivityStatuses = ["", "приет", "отложен", "не отговаря", "изпълнено", "отказано/отменено", "прието предложение", "отказано предложение"];

export interface CrmPeriod {
    year: number; month?: number; day?: number; activity?: Activity;
}

export interface Activity {
    id?: string;
    ref?: DocumentReference;
    userId?: string;
    userName?: string;
    campaignId?: string;
    campaignName?: string;
    region: string | null;
    clientId: string | null;
    clientName: string | null;
    date: Date;
    duration?: string;
    activityType: ActivityType;
    withPriority?: boolean;
    activityStatus?: ActivityStatus;
    pospondActivityId?: string;
    pospondDate?: Date | null;
    details?: string;
}
export type ActivitiesType = Activity[];



export interface Region {
    id?: string;
    name?: string;
    country?: string;
}
