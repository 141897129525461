import {
    Button,
    Modal, ModalBody,
    ModalTitle,
} from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import React, {useEffect, useState} from "react";
import {DIALOG_OPTIONS} from "../../../types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFile,} from "@fortawesome/free-solid-svg-icons";
import ModalBackground from "../../../common/ModalBackground";
import {CustomerOffersList} from "./CustomerOffersList";
import DialogCloseButton from "../../../common/DialogCloseButton";
import {CustomerInquiryType} from "../../../fb-converters/cutomerInquiry-converter";
import {CustomerOfferDetails} from "./details/CustomerOfferDetails";
import {
    customerOfferConverter,
    CustomerOfferType,
    productToOfferMapper
} from "../../../fb-converters/cutomerOffer-converter";
import {useHookstate} from "@hookstate/core";
import appState from "../../../global-state/global-state";
import {dateToString} from "../../../common";
import {OfferPage} from "./print/customer-offer/print-offer-parts/OfferPage";
import {addDoc, collection, doc, getDocs, updateDoc} from "firebase/firestore";
import {fbDb} from "../../../App";
import ReactDOMServer from 'react-dom/server';
import CustomerOffersPrintPreview from "./print/customer-offer/CustomerOffersPrintPreview";
import I18Label from "../../../i18/i18label";

interface Props extends DIALOG_OPTIONS {
    customerInquiry?: CustomerInquiryType
}

export function CustomerOffersDialog({zIndex, show, onClose, onSelect, customerInquiry}: Props) {
    const exchangeRate = useHookstate(appState.currencyIndex.EUR);
    const [viewMode, setViewMode] = useState<"VIEW_LIST" | "VIEW_ADD" | "VIEW_EDIT" | "VIEW_DELETE" | "VIEW_PREVIEW">('VIEW_LIST');
    const [editDocument, setEditDocument] = useState<CustomerOfferType | null>(null);
    const [printDocument, setPrintDocument] = useState<CustomerOfferType | null>(null);
    const [deleteDocument, setDeleteDocument] = useState<CustomerOfferType | null>(null);
    const [addDocument, setAddDocument] = useState(false);
    const _appState = useHookstate(appState);

    useEffect(() => {
        setViewMode(editDocument ? "VIEW_EDIT" : "VIEW_LIST");
    }, [editDocument])

    useEffect(() => {
        setViewMode(deleteDocument ? "VIEW_DELETE" : "VIEW_LIST");
    }, [deleteDocument])

    useEffect(() => {
        setViewMode(addDocument ? "VIEW_ADD" : "VIEW_LIST");
    }, [addDocument])

    useEffect(() => {
        setViewMode(printDocument ? "VIEW_PREVIEW" : "VIEW_LIST");
    }, [printDocument])

    async function updateInqueryOffersList() {
        if (!customerInquiry?.id) return;

        const allInqueryOffersData = await getDocs(collection(fbDb, `customerInquiries/${customerInquiry.id}/offers`).withConverter(customerOfferConverter));
        const allInqueryOffers = allInqueryOffersData.docs.map(d => d.data());
        const inqueryOffers = allInqueryOffers.map(o => ({
            offerId: o.id,
            offerDate: o.offerDate,
            offerNumber: o.offerNumber
        }));

        await updateDoc(doc(fbDb, `customerInquiries/${customerInquiry.id}`), {
            offers: inqueryOffers
        });
    }

    return (
        <ModalBackground zIndex={zIndex}>
            <Modal show={show} style={{zIndex: (zIndex + 1)}} className={"modal-90"}>
                <ModalHeader>
                    <ModalTitle>
                        <FontAwesomeIcon icon={faFile}/> <I18Label label={"Оферти"} />
                        {
                            customerInquiry && <>
                                <div className={"small text-primary"}>
                                    <i>
                                        <I18Label label={"към запитване от клиент"} /> <strong>
                                        {customerInquiry.location?.name},&nbsp;
                                        {customerInquiry.location?.region}
                                    </strong> <I18Label label={"с No:"} /> <strong>{customerInquiry.inquiryNumber} </strong>
                                        <I18Label label={"от дата"} />: <strong>{dateToString(customerInquiry.inquiryDate)}</strong> </i></div>
                            </>
                        }
                    </ModalTitle>
                    <DialogCloseButton onClose={() => onClose(null)}/>
                </ModalHeader>
                {
                    customerInquiry &&
                    <CustomerOffersList onClose={onClose}
                                        inquiry={customerInquiry}
                                        isVisible={viewMode === "VIEW_LIST"}
                                        onSelect={onSelect}
                                        onAdd={() => setAddDocument(true)}
                                        onPrint={(doc: CustomerOfferType) => {
                                            setPrintDocument(doc);
                                        }}
                                        onEdit={(doc: CustomerOfferType) => {
                                            setEditDocument(doc);
                                        }}
                                        onDelete={(doc: CustomerOfferType) => {
                                            updateInqueryOffersList();
                                            setDeleteDocument(doc);
                                        }}
                    />
                }
                {
                    customerInquiry && viewMode === "VIEW_EDIT" && editDocument &&
                    <CustomerOfferDetails inquiry={customerInquiry}
                                          offer={editDocument}
                                          zIndex={(zIndex || 1100) + 10}
                                          onDone={() => {
                                              updateInqueryOffersList();
                                              setEditDocument(null)
                                          }}/>
                }
                {
                    customerInquiry && viewMode === "VIEW_ADD" && addDocument &&
                    <CustomerOfferDetails
                        inquiry={customerInquiry}
                        offer={{
                            offerNumber: 0,
                            offerDate: new Date(),
                            inquiryId: customerInquiry.id!,
                            inqueryNumber: customerInquiry.inquiryNumber,
                            inqueryDate: customerInquiry.inquiryDate,
                            products: customerInquiry.inquiryProducts.map(p => p && p.product && productToOfferMapper(p.product,
                                customerInquiry.client?.discount_price || 0, exchangeRate.value || 1))
                                .filter(p => p !== null),
                            services: [],
                            eurExchangeRate: _appState.currencyIndex.EUR.value || 1,
                            invoice: null,
                            proformaInvoice: null,
                            bestProductPriceIdx: null,
                            validityPeriod: null,
                            paymentMethod: null,
                            comment: null,
                        } as CustomerOfferType}
                        zIndex={(zIndex || 1100) + 10}
                        onDone={() => {
                            updateInqueryOffersList();
                            setAddDocument(false)
                        }}/>
                }
                {
                    customerInquiry && viewMode === "VIEW_PREVIEW" && printDocument &&
                    <CustomerOffersPrintPreview
                        inquiry={customerInquiry}
                        offer={printDocument!}
                        onDone={() => {
                            setPrintDocument(null)
                        }}/>
                }

            </Modal>
        </ModalBackground>
    )
}
