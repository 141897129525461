import {dateToString, zeroToEmptyStringAsCurrency, zeroToEmptyStringAsNumber} from "../../../../common";
import {Button, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil, faPrint, faTrash} from "@fortawesome/free-solid-svg-icons";
import {WarehouseInType} from "../../hooks/useWarehouseIn";
import {WarehouseRequestType} from "./WarehouseRequestType";


export interface WarehouseTransactionTableRowProps {
    data: WarehouseRequestType;
    onEdit?: () => void;
    onDelete?: () => void;
    onPrint?: () => void;
}

export default function WarehouseRequestTableRow(props: WarehouseTransactionTableRowProps) {
    const itemsCnt = props.data.items.length;
    const item = itemsCnt > 0 ? props.data.items[0] : undefined;
    return <>
        <tr>
            <td rowSpan={itemsCnt}> {props.data.requestNumber?.toString().padStart(8, '0')} </td>
            <td rowSpan={itemsCnt}> {dateToString(props.data.createdAt)} </td>
            <td rowSpan={itemsCnt}> {props.data.provider?.bulstat} </td>
            <td rowSpan={itemsCnt}> {props.data.provider?.name} </td>

            <td> {item?.product.sku} </td>
            <td> {item?.product.name} </td>
            <td> {zeroToEmptyStringAsNumber(item?.qty)} </td>
            <td> {dateToString(item?.expectedDeliveryDate)} </td>
            <td> {dateToString(item?.deliveryDate)} </td>
            <td> {zeroToEmptyStringAsNumber(item?.deliveryQty)} </td>
            <td> {zeroToEmptyStringAsCurrency(item?.deliveryPrice)} </td>
            <td> {dateToString(item?.invoiceDate)} </td>
            <td> {item?.invoiceNumber} </td>

            <td className={"text-end"} rowSpan={itemsCnt}>
                {
                    props.onPrint &&
                    <Button size={"sm"} variant={"info"} className={"text-light"} onClick={() => props.onPrint!()}>
                        <FontAwesomeIcon icon={faPrint}/>
                    </Button>
                }&nbsp;
                {
                    props.onEdit &&
                    <Button size={"sm"} variant={"secondary"} onClick={() => props.onEdit!()}>
                        <FontAwesomeIcon icon={faPencil}/>
                    </Button>
                }&nbsp;
                {
                    props.onDelete &&
                    <Button size={"sm"} variant={"danger"} onClick={() => props.onDelete!()}>
                        <FontAwesomeIcon icon={faTrash}/>
                    </Button>
                }
            </td>
        </tr>
        {
            itemsCnt > 1 && props.data.items.map((item, index) => {
                return index > 0 ? <tr key={index}>
                    <td> {item.product.sku} </td>
                    <td> {item.product.name} </td>
                    <td> {zeroToEmptyStringAsNumber(item.qty)} </td>
                    <td> {dateToString(item.expectedDeliveryDate)} </td>
                    <td> {dateToString(item.deliveryDate)} </td>
                    <td> {zeroToEmptyStringAsNumber(item.deliveryQty)} </td>
                    <td> {zeroToEmptyStringAsCurrency(item.deliveryPrice)} </td>
                    <td> {dateToString(item.invoiceDate)} </td>
                    <td> {item.invoiceNumber} </td>
                </tr> : null
            })
        }
    </>
}
