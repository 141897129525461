import {CrmPeriodProps} from "./CrmCalendar";
import {Component, useEffect, useState} from "react";
import {Button, Col, Row, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {ARRAY_MONTHS_FULL} from "../../common";
import CrmStatisticsViewer from "./CrmStatisticsViewer";
import moment from "moment";
import I18Label from "../../i18/i18label";


export default function CrmPeriodMonthViewer({period, changePeriodType, statistics}: CrmPeriodProps) {

    function showMonthDetails(idx: number) {
        const newPeriod = {...period, month: idx};
        changePeriodType("day", newPeriod);
    }

    const _stat = statistics.data() || {};
    const _y = 'y' + period.year.toString();
    const _data = _stat[_y][_y];

    return <>
        <Row>
            <Col>
                <Table>
                    <thead>
                        <tr>
                            <th><I18Label label={"Месец"} /></th>
                            <th><I18Label label={"Активности"}/></th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                    {
                        ARRAY_MONTHS_FULL.map(
                            (m, idx) =>
                                <tr key={idx} className={idx == moment().month() ? "bg-primary-light" : ""}>
                                    <td>{<I18Label label={m} />}, {period.year.toString()}</td>
                                    <td><CrmStatisticsViewer data={_data['m'+idx.toString()]}/></td>
                                    <td className={"text-end"}>
                                        <Button onClick={e => showMonthDetails(idx)} size={"sm"}>
                                            <FontAwesomeIcon icon={faArrowRight}/>
                                        </Button>
                                    </td>
                                </tr>
                        )
                    }
                    </tbody>

                </Table>
            </Col>
        </Row>
    </>
}
