import {
    Modal,
    ModalTitle,
} from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import React, {useEffect, useState} from "react";
import {DIALOG_OPTIONS} from "../../../types";
import {RegionsList} from "./RegionsList";
import {RegionDetails} from "./RegionsDetails";
import {DocumentData} from "firebase/firestore";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGlobe} from "@fortawesome/free-solid-svg-icons";
import {RegionDelete} from "./RegionDelete";
import ModalBackground from "../../../common/ModalBackground";
import DialogCloseButton from "../../../common/DialogCloseButton";
import I18Label from "../../../i18/i18label";

export function RegionsDialog({zIndex, show, onClose, onSelect}: DIALOG_OPTIONS) {

    const [viewMode, setViewMode] = useState<"VIEW_LIST" | "VIEW_ADD" | "VIEW_EDIT" | "VIEW_DELETE">('VIEW_LIST');
    const [editDocument, setEditDocument] = useState<DocumentData | null>(null);
    const [deleteDocument, setDeleteDocument] = useState<DocumentData | null>(null);
    const [addToCountry, setAddToCountry] = useState<string | null>(null);


    useEffect(() => {
        setViewMode(editDocument ? "VIEW_EDIT" : "VIEW_LIST");
    }, [editDocument])

    useEffect(() => {
        setViewMode(deleteDocument ? "VIEW_DELETE" : "VIEW_LIST");
    }, [deleteDocument])

    useEffect(() => {
        setViewMode(addToCountry ? "VIEW_ADD" : "VIEW_LIST");
    }, [addToCountry])

    return (
        <ModalBackground zIndex={zIndex}>
            <Modal show={show} style={{zIndex: (zIndex + 1)}}>
                <ModalHeader>
                    <ModalTitle>
                        <FontAwesomeIcon icon={faGlobe}/> <I18Label label={"Държави и региони"} />
                    </ModalTitle>
                    <DialogCloseButton onClose={() => onClose(null)} />
                </ModalHeader>
                {
                    <RegionsList onClose={onClose} isVisible={viewMode === "VIEW_LIST"}
                                 onSelect={onSelect}
                                 onAdd={(country) => setAddToCountry(country)}
                                 onEdit={(doc: DocumentData) => {
                                     setEditDocument(doc);
                                 }}
                                 onDelete={(doc: DocumentData) => {
                                     setDeleteDocument(doc);
                                 }}
                    />
                }
                {
                    viewMode === "VIEW_EDIT" && editDocument &&
                    <RegionDetails doc={editDocument} country={editDocument.data().country} onDone={() => {
                        setEditDocument(null)
                    }}/>
                }
                {
                    viewMode === "VIEW_DELETE" && deleteDocument &&
                    <RegionDelete doc={deleteDocument} onDone={() => {
                        setDeleteDocument(null)
                    }}/>
                }
                {
                    viewMode === "VIEW_ADD" && addToCountry &&
                    <RegionDetails doc={null} country={addToCountry} onDone={() => {
                        setAddToCountry(null)
                    }}/>
                }
            </Modal>
        </ModalBackground>
    )
}
