import {Component, useEffect, useState} from "react";

import {
    DocumentData,
    updateDoc,
    addDoc,
    collection,
    getFirestore,
    query,
    where,
    orderBy,
    getDocs
} from "firebase/firestore";
import {
    Col,
    Container,
    Dropdown,
    Form,
    Row,
    Spinner,
    Tab,
    Table,
    Tabs
} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClipboardCheck} from "@fortawesome/free-solid-svg-icons";
import {fbDb} from "../../../App";
import {
    useCollection
} from "react-firebase-hooks/firestore";
import {loadAllCountries, loadAllRegions} from "../../../common/DataLoader";
import I18Label from "../../../i18/i18label";

function ErrorRow(props: any) {
    return (
        <tr>
            <td colSpan={3} className={"text-center text-danger"}> {props.msg.toString()} </td>
        </tr>
    )
}

export interface ClientsSelectorProps {
    selectedRegions: string[];
    selectedClients: string[];
    onChangeRegions: (regions: string[]) => any;
    onChangeClients: (clients: string[]) => any;
}

export function ClientsSelector({
                                    selectedRegions,
                                    selectedClients,
                                    onChangeRegions,
                                    onChangeClients
                                }: ClientsSelectorProps) {
    const [selectedCountry, setSelectedCountry] = useState<any>('');
    const [businessSizeTab, setBusinessSizeTab] = useState("Ключов клиент/дилър");
    const [regionTab, setRegionTab] = useState("");
    const [clientsTab, setClientsTab] = useState<any>(null);
    const [regions, setRegions] = useState<DocumentData[]>([]);
    const [loadingRegions, setLoadingRegions] = useState(false);
    const [countries, setCountries] = useState<DocumentData[]>([]);
    const [loadingCountries, setLoadingCountries] = useState(false);
    const [selecting, setSelecting] = useState(false);


    const [clients, loadingClients, errorClients] = useCollection(
        query(collection(fbDb, "clientLocations"),
            where('country', "==", selectedCountry),
            where('region', "==", regionTab),
            where('sizeGroup', "==", businessSizeTab),
            orderBy("name", "asc")
        )
    )

    const loadCountries = async () => {
        setLoadingCountries(true);
        let _result = await loadAllCountries();
        setCountries(_result.docs);
        setLoadingCountries(false);
        if (_result.docs.length > 0) {
            setSelectedCountry(_result.docs[0].data().name)
        }
    }

    const loadRegions = async () => {
        setLoadingRegions(true);
        let _regions = await loadAllRegions();
        setRegions(_regions.docs);
        setLoadingRegions(false);
    }

    useEffect(() => {
        loadCountries();
        loadRegions();
    }, []);

    useEffect(() => {
        if (selectedRegions.length === 0) {
            setRegionTab("")
        } else {
            if (selectedRegions.indexOf(regionTab) === -1) {
                setRegionTab(selectedRegions[0]);
            }
        }
    }, [selectedRegions]);

    useEffect(() => {
        let _clientsTab: any[] = [(<tr key={999}>
            <td colSpan={3} className={"text-center"}><Spinner animation={"border"}/></td>
        </tr>)];
        if (!loadingClients) {
            if (errorClients) {
                _clientsTab = [<ErrorRow msg={errorClients}/>]
            } else {
                if (clients && clients.size > 0) {
                    _clientsTab = [];
                    clients.docs
                        .filter(d => (selectedRegions.indexOf(d.data().region) > -1))
                        .map(d => {
                                const _d = d.data();
                                _clientsTab.push(
                                    <tr key={d.id} onClick={() => onClientClick(d.id)}>
                                        <td> {_d.name} </td>
                                        <td> {_d.region} </td>
                                        <td className={"text-center"}>
                                            <Form.Check type={"switch"}
                                                        checked={selectedClients.indexOf(d.id) > -1}
                                                        onChange={e => onChangeClient(d.id, e.target.checked)}
                                            />
                                        </td>
                                    </tr>
                                )
                            }
                        )
                } else {
                    _clientsTab = [(<tr key={0}>
                        <td colSpan={3} className={"text-center fw-bold"}> Няма клиенти от тази група в избраните
                            райони
                        </td>
                    </tr>)];
                }
            }
        }
        setClientsTab(_clientsTab);
    }, [loadingClients, selectedRegions, selectedClients, selectedCountry]);

    function switchInList(list: Array<string>, item: string, checked: boolean) {
        const idx = list.indexOf(item);
        if (!checked) {
            if (idx > -1) {
                list.splice(idx, 1);
            }
        } else {
            if (idx === -1) {
                list.push(item);
            }
        }
        return list;
    }

    function onChangeRegion(region: string, checked: boolean) {
        const newArray = switchInList(selectedRegions, region, checked);
        onChangeRegions([...newArray]);

        if (!checked) {
            const _selectedClients = [...selectedClients];
            selectedClients.forEach(
                cId => {
                    clients?.forEach(
                        c => {
                            if (c.id === cId && c.data().region === region) {
                                const idx = _selectedClients.indexOf(cId);
                                if (idx > -1) {
                                    _selectedClients.splice(idx, 1);
                                }
                            }
                        }
                    )
                }
            );
            onChangeClients(_selectedClients);
        }
    }

    function onChangeClient(clientId: string, checked: boolean) {
        const newArray = switchInList(selectedClients, clientId, checked);
        onChangeClients([...newArray]);
    }

    function onClientClick(id: string) {
        onChangeClient(id, (selectedClients.indexOf(id) == -1));
    }

    async function selectAllClients(mode: number) {
        let newArray = selectedClients;
        let _clients;
        setSelecting(true);
        switch (mode) {
            case 0 :
                clients?.docs
                    .filter(d => d.data().country === selectedCountry && selectedRegions.indexOf(d.data().region) > -1)
                    .forEach(client => newArray = switchInList(newArray, client.id, true));
                break;
            case 1 :
                _clients = await getDocs(query(collection(fbDb, "clientLocations"),
                    where('country', "==", selectedCountry),
                    where('region', "==", regionTab)));
                _clients?.docs.forEach(client => newArray = switchInList(newArray, client.id, true));
                break;
            case 2 :
                _clients = await getDocs(query(collection(fbDb, "clientLocations"),
                    where('sizeGroup', "==", businessSizeTab)));
                _clients?.docs.forEach(client => newArray = switchInList(newArray, client.id, true));
                break;
            case 3 :
                _clients = await getDocs(query(collection(fbDb, "clientLocations")));
                _clients?.docs.forEach(client => newArray = switchInList(newArray, client.id, true));
                break;
        }

        onChangeClients([...newArray]);
        setSelecting(false);
    }

    return (
        <>
            <Row className={"mb-2"}>
                <Col>
                    <Tabs activeKey={selectedCountry} onSelect={eventKey => setSelectedCountry(eventKey)}>
                        {
                            countries?.map(c => <Tab key={c.id} title={c.data().name} eventKey={c.data().name}>
                                <Container className={"p-3 border-0 border-bottom border-start border-end"}>
                                    <Row>
                                        {
                                            regions?.filter(
                                                r => r.data().country === c.data().name
                                            ).map(
                                                r => <Col key={r.id} xs={3}>
                                                    <Form.Check type={"switch"} label={r.data().name}
                                                                className={"space-before-label"}
                                                                checked={selectedRegions.indexOf(r.data().name) > -1}
                                                                onChange={e => onChangeRegion(r.data().name, e.target.checked)}
                                                    />
                                                </Col>
                                            )
                                        }
                                    </Row>
                                </Container>
                            </Tab>)
                        }
                    </Tabs>
                </Col>
            </Row>

            <Row className={"mb-2"}>
                <Col>
                    <Tabs onSelect={key => setBusinessSizeTab(key?.toString() || "Ключов клиент/дилър")}
                          variant={"pills"}>
                        <Tab title={"Ключов клиент/дилър"} eventKey={"Ключов клиент/дилър"}/>
                        <Tab title={"Голям клиент"} eventKey={"Голям клиент"}/>
                        <Tab title={"Среден клиент"} eventKey={"Среден клиент"}/>
                        <Tab title={"Малък клиент"} eventKey={"Малък клиент"}/>
                    </Tabs>
                </Col>
            </Row>

            <Row className={"mb-2"}>
                <Col>
                    <Tabs
                        onSelect={key => setRegionTab(key?.toString() || ((selectedRegions && selectedRegions.length > 0) ? selectedRegions[0] : ""))}
                        variant={"pills"}>
                        {
                            selectedRegions.map(
                                region => <Tab title={region} eventKey={region} key={region}/>
                            )
                        }
                    </Tabs>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Table bordered size={"sm"} hover className={"w-100"}>
                        <thead style={{background: "white"}}>
                        <tr>
                            <th><I18Label label={"Клиент"} /></th>
                            <th>Регион</th>
                            <th className={"w-30px text-center ps-4"}>
                                {
                                    selecting ? <Spinner animation={"border"}/> :
                                        <Dropdown>
                                            <Dropdown.Toggle variant="outline-secondary" id="dropdown-auto-elect">
                                                <FontAwesomeIcon icon={faClipboardCheck}/>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => selectAllClients(0)}>Избери всички от
                                                    таблицата</Dropdown.Item>
                                                <Dropdown.Item onClick={() => selectAllClients(1)}>Избери всички от
                                                    района</Dropdown.Item>
                                                <Dropdown.Item onClick={() => selectAllClients(2)}>Избери всички от
                                                    избраната група</Dropdown.Item>
                                                <Dropdown.Item onClick={() => selectAllClients(3)}>Избери
                                                    всички</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                }
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {clientsTab}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </>
    )
}
