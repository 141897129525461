import {Button, Col, Dropdown, DropdownButton, Row, Spinner} from "react-bootstrap";
import {IBVoidFunctionWithUserId} from "../../types";
import {useCollection} from "react-firebase-hooks/firestore";
import {collection, getFirestore, query} from "firebase/firestore";
import {fbDb, firebaseApp} from "../../App";
import {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLeftRight} from "@fortawesome/free-solid-svg-icons";

export interface UserDropdownSelectorProps {
    onSelectUser: IBVoidFunctionWithUserId;
}

export default function UserDropdownSelector({onSelectUser}: UserDropdownSelectorProps) {
    const [selectedUser, setSelectedUser] = useState<any>(null);
    const [users, loadingUsers, errorUsers] = useCollection(
        query(collection(fbDb, "users"))
    );

    let content = null;

    if(loadingUsers) {
        content = <Spinner animation={"border"} />
    }

    if(errorUsers) {
        content = <Row>
            <Col>
                <span className={"text-danger"}>
                    {errorUsers.message}
                </span>
            </Col>
        </Row>
    } else {
        content = users?.docs
            .map(u => <Dropdown.Item key={u.id} onClick={(e) => { e.preventDefault(); setSelectedUser(u)}}>{ u.data().name }</Dropdown.Item>)
    }


    return <Row className={"justify-content-end align-content-end"}>
        <Col xs={"auto"} className={"m-0 p-0"}>
            <Button variant={selectedUser ? "success" : "outline-success"} className={"m-0"} disabled={!selectedUser}
                    onClick={() => {
                        if(onSelectUser) onSelectUser(selectedUser.id);
                        setSelectedUser(null);
                    }}
            >
                <span className={"text-nowrap"}><FontAwesomeIcon icon={faLeftRight}/> Прехвърли на { selectedUser?.data().name || "..." }</span>
            </Button>
        </Col>
        <Col xs={"auto"} className={"m-0 p-0 pe-1"}>
        <DropdownButton variant={"success"} title={""}>
            <Dropdown.Item key={"nullitem"} onClick={(e) => { e.preventDefault(); setSelectedUser(null)}}>-- Изчисти --</Dropdown.Item>
            { content }
    </DropdownButton>
        </Col>
    </Row>
}
