import {useState} from "react";

import {DocumentData, updateDoc, addDoc, collection,} from "firebase/firestore";
import {Button, Col, Form, Modal, Row, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faSave} from "@fortawesome/free-solid-svg-icons";
import {fbDb,} from "../../../App";
import {removeEmptyFields, textToFloat} from "../../../common";
import {ServiceType} from "../../../fb-converters/service-converter";
import I18Label from "../../../i18/i18label";


export interface ServiceDetailsProps {
    zIndex?: number;
    doc: DocumentData | null;
    onDone: () => any;
}

export function ServiceDetails({zIndex, doc, onDone}: ServiceDetailsProps) {
    const [saving, setSaving] = useState(false);

    const save = async (data: any) => {
        setSaving(true);
        let _data = removeEmptyFields(data);
        if (doc) {
            await updateDoc(doc.ref, _data);
        } else {
            await addDoc(collection(fbDb, "services"), _data);
        }
        setSaving(false);

        onDone();
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();
        event.stopPropagation();

        const data: ServiceType = {
            name: event.target.elements.name.value || '',
            name_ro: event.target.elements.name_ro.value || '',
            description: event.target.elements.description.value || '',
            description_ro: event.target.elements.description_ro.value || '',
            descriptionForClient: event.target.elements.descriptionForClient.value || '',
            descriptionForClient_ro: event.target.elements.descriptionForClient_ro.value || '',
            price: textToFloat(event.target.elements.price.value, 0)!,
            descriptionForPrice: event.target.elements.descriptionForPrice.value || '',
            descriptionForPrice_ro: event.target.elements.descriptionForPrice_ro.value || '',
            isWarrantyService: event.target.elements.isWarrantyService.checked || false,
        };
        save(removeEmptyFields(data));
    }

    return (
        <><Form className={"p-2"} onSubmit={handleSubmit}>
            <Modal.Body>
                <h5 className={"mb-3"}>
                    <I18Label label={doc ? "Корекция на услуга" : "Добавяне на услуга"} />
                </h5>

                <Row>
                    <Col xs={7}>
                        <Form.Group className="mb-3">
                            <Form.Label><I18Label label={"Име"} /></Form.Label>
                            <Form.Control type="text" name="name" defaultValue={doc?.data().name}/>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label><I18Label label={"Цена"} />, EUR</Form.Label>
                            <Form.Control type="text" name="price" defaultValue={doc?.data().price} />
                        </Form.Group>
                    </Col>
                    <Col xs={3}>
                        <Form.Group className="mb-3">
                            <Form.Label>&nbsp;</Form.Label>
                            <Form.Check label={<I18Label label={"Удължаване на гаранция?"} />}
                                        name="isWarrantyService" defaultChecked={doc?.data().isWarrantyService} />
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col xs={7}>
                        <Form.Group className="mb-3">
                            <Form.Label><I18Label label={"Име"} /> RO</Form.Label>
                            <Form.Control type="text" name="name_ro" defaultValue={doc?.data().name_ro}/>
                        </Form.Group>
                    </Col>
                    <Col />
                </Row>

                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label><I18Label label={"Описание на услуга"} /></Form.Label>
                                    <Form.Control as="textarea" rows={3} name="description" defaultValue={doc?.data().description}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label><I18Label label={"Описание за клиент"} /></Form.Label>
                                    <Form.Control as="textarea" rows={3} name="descriptionForClient" defaultValue={doc?.data().descriptionForClient} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label><I18Label label={"Описание на цена за клиент"} /></Form.Label>
                                    <Form.Control as="textarea" rows={3} name="descriptionForPrice" defaultValue={doc?.data().descriptionForPrice} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label><I18Label label={"Описание на услуга"} /> RO</Form.Label>
                                    <Form.Control as="textarea" rows={3} name="description_ro" defaultValue={doc?.data().description_ro}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label><I18Label label={"Описание за клиент"} /> RO</Form.Label>
                                    <Form.Control as="textarea" rows={3} name="descriptionForClient_ro" defaultValue={doc?.data().descriptionForClient_ro} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label><I18Label label={"Описание на цена за клиент"} /> RO</Form.Label>
                                    <Form.Control as="textarea" rows={3} name="descriptionForPrice_ro" defaultValue={doc?.data().descriptionForPrice_ro} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Col>
                </Row>

            </Modal.Body>
            <Modal.Footer>
                <Row className={"w-100"}>
                    {
                        saving && <Col xs={12} className={"text-center"}>
                            <Spinner animation={"border"}/>
                        </Col>
                    }

                    {
                        !saving &&
                        <>
                            <Col xs={6}>
                                <Button variant={"outline-secondary"} onClick={() => {
                                    onDone()
                                }}>
                                    <FontAwesomeIcon icon={faArrowLeft}/> <I18Label label={"Към списъка"} />
                                </Button>
                            </Col>
                            <Col xs={6} className={"text-end"}>
                                <Button type={"submit"}>
                                    <FontAwesomeIcon icon={faSave}/> <I18Label label={"Запиши"} />
                                </Button>
                            </Col>
                        </>
                    }
                </Row>
            </Modal.Footer>
        </Form>
        </>
    )
}
