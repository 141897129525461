import {
    collection,
    FirestoreError, query,
    QueryConstraint,
} from "firebase/firestore";
import {useEffect, useState} from "react";
import {ClientLocationsType, locationConverter} from "../../../../fb-converters/location-converter";
import {useCollection} from "react-firebase-hooks/firestore";
import {fbDb} from "../../../../App";

export default function useClientsLocations(constraints?: QueryConstraint[]): [ClientLocationsType, boolean, FirestoreError | undefined] {
    const [documents, loading, error] = useCollection(
        query(
            collection(fbDb, "clientLocations"), ...(constraints || [])
        ).withConverter(locationConverter));
    const [convertedData, setConvertedData] = useState<ClientLocationsType>([]);

    useEffect(() => {
        if (documents) {
            setConvertedData(documents.docs.map(d => d.data()));
        }
    }, [documents]);

    return [convertedData, loading, error];
}
