import {Card, Col, Container, Row, Tab, Tabs} from "react-bootstrap";
import LoadingErrorContent from "../../../common/LoadingErrorContent";
import useWarehouseObjects from "../hooks/useWarehouseObjects";
import {useState} from "react";
import useProviders from "../../nomenclatures/providers/hooks/useProviders";
import WarehouseObjectOutComponent from "./warehouse-out/WarehouseObjectOutComponent";
import WarehouseObjectInComponent from "./warehouse-in/WarehouseObjectInComponent";
import WarehouseObjectRequestComponent from "./warehouse-request/WarehouseObjectRequestComponent";
import {DEFAULT_WAREHOUSE} from "../consts";

interface Props {
    mode: "in" | "out" | "request";
}

export default function WarehouseInOutPage({mode}: Props) {
    const [whObjects, loadingWHObjects, errorWHObjects] = useWarehouseObjects();
    const [allProviders, loadAllProviders, errorAllProviders] = useProviders();

    const [currentTab, setCurrentTab] = useState<string>(whObjects?.length > 0 ? whObjects[0].id : DEFAULT_WAREHOUSE);

    let wareHouse = null;
    if (whObjects?.length > 0) {
        wareHouse = whObjects.find(
            value => value.id === currentTab
        )
    }

    return <>
                <LoadingErrorContent loading={loadingWHObjects || loadAllProviders || !wareHouse}
                                     error={errorWHObjects?.message || errorAllProviders?.message}>
                    <Container className={"max-w-none"}>
                        {
                            mode === "in"  && <WarehouseObjectInComponent whObject={wareHouse!} allProviders={allProviders}/>
                        }
                        {
                            mode === "out" && <WarehouseObjectOutComponent whObject={wareHouse!}/>
                        }
                        {
                            mode === "request" && <WarehouseObjectRequestComponent whObject={wareHouse!} allProviders={allProviders} />
                        }
                    </Container>
                </LoadingErrorContent>
    </>
}
