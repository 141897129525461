import {
    FirestoreError,
    DocumentData,
    QueryDocumentSnapshot, DocumentReference, QueryConstraint, where
} from "firebase/firestore";
import {useEffect, useState} from "react";
import useFirebaseCollectionOnce from "../../../../hooks/useFirebaseCollectionOnce";

export const EMPTY_VIRTUAL_PRODUCT: VirtualProductType = {name: "", productId: "", weight: 0, isOwnProduct: true};

export interface VirtualProductType {
    id?: string;
    ref?: DocumentReference<DocumentData>;
    sku?: string;
    name: string;
    dimension?: string;
    description?: string;
    productId: string;
    isOwnProduct: boolean;
    weight: number;
}

export type VirtualProductsType = VirtualProductType[];

export function convertVirtualProductData(_objects: QueryDocumentSnapshot<DocumentData>[]): VirtualProductsType {
    if(_objects) {
        let _newObjectsCollection: VirtualProductsType = _objects.map(
            d => {
                return {
                    id: d.id,
                    ref: d.ref,
                    sku: d.get('sku') ?? "",
                    name: d.get('name') ?? "",
                    dimension: d.get('dimension'),
                    description: d.get('description'),
                    productId: d.get('productId'),
                    isOwnProduct: d.get('isOwnProduct'),
                    weight: d.get('weight'),
                } as VirtualProductType;
            }
        );
        return _newObjectsCollection;
    } else return [];
}

export default function useVirtualProductsOnce(constraints?: QueryConstraint[]): [VirtualProductsType, boolean, FirestoreError | undefined] {
    const [documents, loading, error] = useFirebaseCollectionOnce("products",
        [where("isOwnProduct", "==", true), ...(constraints || [])]);
    const [convertedData, setConvertedData] = useState<VirtualProductsType>([]);

    useEffect(() => {
        if(documents) {
            setConvertedData(convertVirtualProductData(documents));
        }
    }, [documents]);

    return [convertedData, loading, error];
}
