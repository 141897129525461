import {Card, Container} from "react-bootstrap";
import LoadingErrorContent from "../../../common/LoadingErrorContent";
import useWarehouseObjects from "../hooks/useWarehouseObjects";
import {useState} from "react";
import WarehouseObjectComponent from "./WarehouseObjectComponent";
import {DEFAULT_WAREHOUSE} from "../consts";
import I18Label from "../../../i18/i18label";

export default function WarehousePage() {
    const [whObjects, loadingWHObjects, errorWHObjects] = useWarehouseObjects();
    const [currentTab, setCurrentTab] = useState<string>(whObjects?.length > 0 ? whObjects[0].id : DEFAULT_WAREHOUSE);
    //
    // const tabs = useMemo(() => whObjects.map(
    //     who => <Tab title={who.name} eventKey={who.id} key={who.id}>
    //         <WarehouseObjectComponent whObject={who} />
    //     </Tab>
    // ), [whObjects]);
    //
    //

    let wareHouse = null;
    if (whObjects?.length > 0) {
        wareHouse = whObjects.find(
            value => value.id === currentTab
        )
    }

    return <Container>
        <Card>
            <Card.Header>
                <Card.Title><I18Label label={"Склад - складово стопанство"} /></Card.Title>
            </Card.Header>

            <Card.Body>

                <LoadingErrorContent loading={loadingWHObjects || !wareHouse}
                                     error={errorWHObjects?.message}>
                    {/*<Tabs onSelect={eventKey => setCurrentTab(eventKey || "")} activeKey={currentTab}*/}
                    {/*className={"mb-1 ps-2"} >*/}
                    {/*    { tabs }*/}
                    {/*</Tabs>*/}

                    <WarehouseObjectComponent whObject={wareHouse!}/>
                </LoadingErrorContent>

            </Card.Body>

        </Card>


    </Container>
}
