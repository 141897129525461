import {Modal,
    ModalTitle,
} from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import React, {useEffect, useState} from "react";
import {DIALOG_OPTIONS} from "../../../types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ModalBackground from "../../../common/ModalBackground";
import DialogCloseButton from "../../../common/DialogCloseButton";
import {WarehouseObjectsList} from "./WarehouseObjectsList";
import {faWarehouse} from "@fortawesome/free-solid-svg-icons";
import {WarehouseObjectDetails} from "./WarehouseObjectDetails";
import {ProductDimens} from "../product-dimens/ProductDimensesList";
import {WarehouseObjectDelete} from "./WarehouseObjectDelete";
import {WarehouseObjectType} from "../../warehouse/hooks/useWarehouseObjects";

export function WarehouseObjectsDialog({zIndex, show, onClose, onSelect}: DIALOG_OPTIONS) {

    const [viewMode, setViewMode] = useState<"VIEW_LIST" | "VIEW_ADD" | "VIEW_EDIT" | "VIEW_DELETE">('VIEW_LIST');
    const [editDocument, setEditDocument] = useState<WarehouseObjectType | null>(null);
    const [deleteDocument, setDeleteDocument] = useState<WarehouseObjectType | null>(null);
    const [addDocument, setAddDocument] = useState(false);

    useEffect(() => {
        setViewMode(editDocument ? "VIEW_EDIT" : "VIEW_LIST");
    }, [editDocument])

    useEffect(() => {
        setViewMode(deleteDocument ? "VIEW_DELETE" : "VIEW_LIST");
    }, [deleteDocument])

    useEffect(() => {
        setViewMode(addDocument ? "VIEW_ADD" : "VIEW_LIST");
    }, [addDocument])

    return (
        <ModalBackground zIndex={zIndex}>
            <Modal show={show} size={"sm"} style={{zIndex: (zIndex + 1)}}>
                <ModalHeader>
                    <ModalTitle>
                        <FontAwesomeIcon icon={faWarehouse}/> Складови обекти
                    </ModalTitle>
                    <DialogCloseButton onClose={() => onClose(null)} />
                </ModalHeader>
                {
                    <WarehouseObjectsList onClose={onClose} isVisible={viewMode === "VIEW_LIST"}
                                          onSelect={onSelect}
                                          onAdd={() => setAddDocument(true)}
                                          onEdit={(doc: WarehouseObjectType) => {
                                     setEditDocument(doc);
                                 }}
                                          onDelete={(doc: ProductDimens) => {
                                     setDeleteDocument(doc);
                                 }}
                    />
                }
                {
                    viewMode === "VIEW_EDIT" && editDocument &&
                    <WarehouseObjectDetails object={editDocument}
                                            zIndex={(zIndex || 1100) + 10}
                                            onDone={() => {
                                      setEditDocument(null)
                                  }}/>
                }
                {
                    viewMode === "VIEW_DELETE" && deleteDocument &&
                    <WarehouseObjectDelete doc={deleteDocument} onDone={() => {
                        setDeleteDocument(null)
                    }}/>
                }
                {
                    viewMode === "VIEW_ADD" && addDocument &&
                    <WarehouseObjectDetails object={null}
                                            zIndex={(zIndex || 1100) + 10}
                                            onDone={() => {
                                      setAddDocument(false)
                                  }}/>
                }

            </Modal>
        </ModalBackground>
    )
}
