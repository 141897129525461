import {useState} from "react";

import {DocumentData, updateDoc, addDoc, collection, getFirestore, setDoc} from "firebase/firestore";
import {Button, Col, Form, InputGroup, Modal, Row, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faList, faSave} from "@fortawesome/free-solid-svg-icons";
import {fbDb, firebaseApp} from "../../../App";
import {removeEmptyFields, removeUndefinedFields, textToFloat} from "../../../common";


export interface StandartDetailsProps {
    zIndex?: number;
    doc: DocumentData | null;
    onDone: () => any;
}

export function StandartDetails({zIndex, doc, onDone}: StandartDetailsProps) {
    const [saving, setSaving] = useState(false);

    const save = async (data: any) => {
        setSaving(true);
        let _data = removeEmptyFields(data);
        if (doc) {
            await setDoc(doc.ref, _data);
        } else {
            await addDoc(collection(fbDb, "standarts"), _data);
        }
        setSaving(false);
        onDone();
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();
        event.stopPropagation();

        const data: any = {
            id: event.target.elements.id.value || '',
            type: event.target.elements.type.value || '',
            name: event.target.elements.name.value || '',
            me: event.target.elements.me.value || '',
            standart: textToFloat(event.target.elements.standart.value, 0),
        };
        save(removeEmptyFields(data));
    }

    return (
        <><Form className={"p-2"} onSubmit={handleSubmit}>
            <Modal.Body>
                <h5 className={"mb-3"}>
                    {doc ? "Корекция" : "Добавяне"} на стандарт
                </h5>

                <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>ID</Form.Label>
                                    <Form.Control type="text" name="id" defaultValue={doc?.data().id}/>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Тип дейност</Form.Label>
                                    <select className={"form-select"} name="type" defaultValue={doc?.data().type}>
                                        <option value={"trading"}>Търговия</option>
                                        <option value={"production"}>Производство</option>
                                    </select>
                                </Form.Group>
                            </Col>
                        </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>Дейност</Form.Label>
                            <Form.Control type="text" name="name" defaultValue={doc?.data().name}/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>Стандарт</Form.Label>
                            <Form.Control type="text" name="standart" defaultValue={doc?.data().standart} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>М/Е</Form.Label>
                            <Form.Control type="text" name="me" defaultValue={doc?.data().me} />
                        </Form.Group>
                    </Col>
                </Row>

            </Modal.Body>
            <Modal.Footer>
                <Row className={"w-100"}>
                    {
                        saving && <Col xs={12} className={"text-center"}>
                            <Spinner animation={"border"}/>
                        </Col>
                    }

                    {
                        !saving &&
                        <>
                            <Col xs={6}>
                                <Button variant={"outline-secondary"} onClick={() => {
                                    onDone()
                                }}>
                                    <FontAwesomeIcon icon={faArrowLeft}/> Към списъка
                                </Button>
                            </Col>
                            <Col xs={6} className={"text-end"}>
                                <Button type={"submit"}>
                                    <FontAwesomeIcon icon={faSave}/> Запиши
                                </Button>
                            </Col>
                        </>
                    }
                </Row>
            </Modal.Footer>
        </Form>
        </>
    )
}
