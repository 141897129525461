import {
    Button,
    Form, InputGroup,
    ListGroup,
    Modal,
    ModalBody,
    ModalFooter,
    ModalTitle, Spinner,
} from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMoneyBillTrendUp} from "@fortawesome/free-solid-svg-icons";
import DialogCloseButton from "../../../common/DialogCloseButton";
import React, {useEffect, useMemo, useState} from "react";
import {DIALOG_OPTIONS} from "../../../types";
import ModalBackground from "../../../common/ModalBackground";
import {dateToString, removeEmptyFields, showErrorsListInToast, showMsgInToast, textToInt} from "../../../common";
import {useHookstate} from "@hookstate/core";
import appDb from "../../../global-state/global-db";
import {ProductGroup, ProductGroupsConvert} from "../product-groups/ProductGroupsList";
import {collection, writeBatch} from "firebase/firestore";
import {fbDb} from "../../../App";
import {useCollection} from "react-firebase-hooks/firestore";
import {CSVLink} from "react-csv";

export default function ProductsExpImpModal({zIndex, show, onClose}: DIALOG_OPTIONS) {
    const defaultFilename = "exported-products-" + dateToString(new Date()) + ".csv";
    const [filename, setFilename] = useState(defaultFilename);
    const allProducts = useHookstate(appDb.products);

    const [_groups, loadingGroups, errorGroups] = useCollection(
        collection(fbDb, "productGroups")
    );
    const [groups, setGroups] = useState<Array<ProductGroup>>([]);
    const [importingFlag, setImportingFlag] = useState(false);

    useEffect(
        () => {
            setGroups(ProductGroupsConvert(_groups));
        }, [_groups]
    );

    const products = useMemo(() => {
        const products = [
            ["id", "SKU", "Име", "Мин.к-во", "Продуктова група", "Описание", "Дължина", "Широчина", "Височина 1", "Височина 2", "Гаранция", "Цикли", "МЕ"]
        ];

        allProducts.get().forEach(
            (product) => {
                products.push([
                    product.id,
                    product.sku || "",
                    product.name,
                    product.minQty.toString() || "",
                    product.productGroup?.name || "",
                    product.description || "",
                    product._length?.toString() || "",
                    product.width?.toString() || "",
                    product.height1?.toString() || "",
                    product.height2?.toString() || "",
                    product.warranty || "",
                    product.liveCycles?.toString() || "",
                    product.dimens
                ])
            }
        )

        return products;
    }, []);

    if (!show) return null;


    function doImport() {
        const fileInput = document.querySelector('input[type="file"]') as HTMLInputElement;
        if (fileInput.files && fileInput.files[0]) {
            const reader = new FileReader();
            reader.onload = async function (e) {
                const csv = e.target?.result;
                if (csv) {
                    const lines = (csv as string).split("\n");
                    const products = [];
                    for (let i = 1; i < lines.length; i++) {
                        const line = lines[i];
                        const values = line.split(";");

                        const prodGroupName = values[4];
                        const prodGroup = groups.find(g => g.name === prodGroupName) || null;
                        if (!prodGroup && prodGroupName) {
                            showErrorsListInToast("Грешка", ["Продуктовата група " + prodGroupName + " не е намерена!"]);
                            return;
                        }

                        const product = {
                            id: values[0],
                            sku: values[1],
                            name: values[2],
                            minQty: textToInt(values[3]),
                            productGroup: prodGroup,
                            description: values[5],
                            _length: textToInt(values[6]),
                            width: textToInt(values[7]),
                            height1: textToInt(values[8]) || 0,
                            height2: textToInt(values[9]) || 0,
                            warranty: textToInt(values[10]) ?? undefined,
                            liveCycles: textToInt(values[11]) ?? undefined,
                            dimens: values[12],
                        }
                        products.push(product);
                    }

                    setImportingFlag(true);
                    let batchCnt = 0;
                    let batch = writeBatch(fbDb);
                    try {
                        for (const product of products) {
                            const dbProduct = allProducts.find(p => p.value && p.value.id === product.id)?.value;
                            if (dbProduct && dbProduct.ref) {
                                if (
                                    dbProduct.sku !== product.sku ||
                                    dbProduct.name !== product.name ||
                                    dbProduct.minQty !== product.minQty ||
                                    dbProduct.productGroup !== product.productGroup ||
                                    dbProduct.description !== product.description ||
                                    dbProduct._length !== product._length ||
                                    dbProduct.width !== product.width ||
                                    dbProduct.height1 !== product.height1 ||
                                    dbProduct.height2 !== product.height2 ||
                                    dbProduct.warranty !== product.warranty ||
                                    dbProduct.liveCycles !== product.liveCycles ||
                                    dbProduct.dimens !== product.dimens
                                ) {
                                    const ref = dbProduct.ref;
                                    batch.update(ref, removeEmptyFields(product));
                                    batchCnt++;
                                    if (batchCnt === 500) {
                                        await batch.commit();
                                        batchCnt = 0;
                                        batch = writeBatch(fbDb);
                                    }
                                }
                            }
                        }

                        if (batchCnt > 0) {
                            await batch.commit();
                        }
                        showMsgInToast("Импортът завърши успешно!");
                    } catch (e: any) {
                        showErrorsListInToast("Грешка", ["Грешка при импорт на продуктите!", e.toString()]);
                    } finally {
                        setImportingFlag(false);
                    }
                }
            }
            reader.readAsText(fileInput.files[0]);
        } else {
            showErrorsListInToast("Грешка", ["Не е избран файл за импорт!"]);
        }
    }

    console.log(groups);

    return <>
        <ModalBackground zIndex={zIndex}>
            <Modal show={show} size={"lg"} style={{zIndex: (zIndex + 1)}}>
                <ModalHeader>
                    <ModalTitle>
                        <FontAwesomeIcon icon={faMoneyBillTrendUp}/> Експорт / Импорт на продуктовата
                        номенклатура
                    </ModalTitle>
                    <DialogCloseButton onClose={() => onClose(false)}/>
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <ListGroup>
                            <ListGroup.Item className={"p-5"}>
                                <h5>Експорт на продуктите в CSV формат</h5>
                                <InputGroup className="mt-3">
                                    <Form.Control value={filename}
                                                  onChange={e => setFilename(e.target.value || defaultFilename)}/>
                                    <CSVLink data={products} separator={";"} className={"btn btn-primary"}
                                             filename={filename}>
                                        Експорт
                                    </CSVLink>

                                </InputGroup>
                            </ListGroup.Item>
                            <ListGroup.Item className={"p-5"}>
                                <h5>Импорт на продуктите в CSV формат</h5>
                                <InputGroup className="mt-3">
                                    <Form.Control type={"file"}/>
                                    {
                                        importingFlag ? <Spinner animation={"border"} variant={"primary"}/> :
                                            <Button variant={"primary"} onClick={() => {
                                                doImport()
                                            }}>Импорт</Button>
                                    }

                                </InputGroup>
                            </ListGroup.Item>
                        </ListGroup>
                    </Form>
                </ModalBody>

                <ModalFooter>
                    <Button onClick={() => onClose(false)} variant={"secondary"}>
                        Затвори
                    </Button>
                </ModalFooter>
            </Modal>
        </ModalBackground>
    </>

}
