import {
    FirestoreError, QueryConstraint, where
} from "firebase/firestore";
import {useEffect, useState} from "react";
import useFirebaseCollection from "../../../../hooks/useFirebaseCollection";
import {convertVirtualProductData, VirtualProductsType} from "./useVirtualProductsOnce";

export default function useVirtualProducts(constraints?: QueryConstraint[]): [VirtualProductsType, boolean, FirestoreError | undefined] {
    const [documents, loading, error] = useFirebaseCollection("products",
        [where("isOwnProduct", "==", true), ...(constraints || [])]);
    const [convertedData, setConvertedData] = useState<VirtualProductsType>([]);

    useEffect(() => {
        if(documents) {
            setConvertedData(convertVirtualProductData(documents.docs));
        }
    }, [documents]);

    return [convertedData, loading, error];
}
