import {DocumentReference} from "firebase/firestore";
import {ProductionCardProductSizeType} from "./ProductionCardProductSizeType";

export type ProductionCardMaterialType = {
    orderIndex: number;
    productId: string;
    productRef: DocumentReference | null;
    productName: string;
    productCode: string;
    productSize: ProductionCardProductSizeType;
    productQty: number;
    productDimension: string;
    supplier: string;
    supplyDate: Date | null
    supplyAddress: string;
}

export type ProductionCardMaterialsType = ProductionCardMaterialType[];

export const EMPTY_PRODUCTION_CARD_MATERIAL: ProductionCardMaterialType = {
    orderIndex: 0,
    productId: "",
    productRef: null,
    productName: "",
    productCode: "",
    productSize: {
        l: 0,
        b: 0,
        h1: 0,
        h2: 0
    },
    productQty: 0,
    productDimension: "",
    supplier: "",
    supplyDate: null,
    supplyAddress: ""
}
