import {
    Button,
    Col,
    Form,
    Modal,
    ModalBody,
    ModalFooter,
    ModalTitle,
    ProgressBar,
    Row,
    Spinner,
    Table
} from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChartLine, faMoneyBillTrendUp} from "@fortawesome/free-solid-svg-icons";
import DialogCloseButton from "../../../common/DialogCloseButton";
import React, {useState} from "react";
import {DIALOG_OPTIONS} from "../../../types";
import ModalBackground from "../../../common/ModalBackground";
import SelectValueFromDialog from "../../../common/SelectValueFromDialog";
import {dateToString, showErrorsListInToast, textToFloat, zeroToEmptyStringAsCurrency} from "../../../common";
import {StringInput} from "../../../common/StringInput";
import {convertProductGroupVariantToLabel, ProductGroup} from "../product-groups/ProductGroupsList";
import {ProductGroupsDialog} from "../product-groups/ProductGroupsDialog";
import {
    addDoc,
    collection,
    doc,
    DocumentData,
    getDocs,
    getFirestore,
    query,
    setDoc,
    updateDoc,
    where
} from "firebase/firestore";
import {fbDb, firebaseApp} from "../../../App";
import {useCollection, useCollectionData} from "react-firebase-hooks/firestore";
import FullSpaceLoader from "../../../common/FullSpaceLoader";
import LoadDataFromServerError from "../../../common/LoadDataFromServerError";

export interface ProductPriceHistoryModalProps extends DIALOG_OPTIONS {
    productId: string;
}

export default function ProductPriceHistoryModal({productId, zIndex, show, onClose}: ProductPriceHistoryModalProps) {

    const [history, loading, error] = useCollection(
        collection(fbDb, `products/${productId}/history`)
    );

    if (!show) return null;

    if (loading) return <FullSpaceLoader/>

    if (error) return <LoadDataFromServerError/>

    if(!history) return null;

    const tableContent = history.docs.map(
        (h: DocumentData) => <tr key={h.id}>
            <td>{ h.id }</td>
            <td className={"text-end"}>{ zeroToEmptyStringAsCurrency(h.get('deliveryPrice'), 'лв') }</td>
            <td className={"text-end"}>{ zeroToEmptyStringAsCurrency(h.get('sellPrice'), 'лв') }</td>
        </tr>
    )

    return <>
        <ModalBackground zIndex={zIndex}>
            <Modal show={show} style={{zIndex: (zIndex + 1)}}>
                <ModalHeader>
                    <ModalTitle>
                        <FontAwesomeIcon icon={faChartLine}/> История на промяната на цените на продукта
                    </ModalTitle>
                    <DialogCloseButton onClose={() => onClose(false)}/>
                </ModalHeader>

                <ModalBody>
                    <Table>
                        <thead>
                        <tr>
                            <th>Дата</th>
                            <th className={"text-end"}>Доставна цена</th>
                            <th className={"text-end"}>Продажна цена</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            history.docs.length === 0 ?
                            <tr>
                                <td colSpan={3}>
                                    <strong>Няма история за този продукт.</strong>
                                </td>
                            </tr> : tableContent
                        }
                        </tbody>
                    </Table>
                </ModalBody>

                <ModalFooter>
                    <Button onClick={() => onClose(false)} variant={"secondary"}>
                        Затвори
                    </Button>
                </ModalFooter>
            </Modal>
        </ModalBackground>
    </>

}
