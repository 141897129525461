import {useState} from "react";

import {updateDoc, addDoc, collection, getFirestore} from "firebase/firestore";
import {Button, Col, Form, Modal, Row, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faSave} from "@fortawesome/free-solid-svg-icons";
import {fbDb, firebaseApp} from "../../../App";
import {removeEmptyFields} from "../../../common";
import {ProviderType} from "./hooks/useProviders";


export interface ProviderDetailsProps {
    zIndex?: number;
    doc: ProviderType | null;
    onDone: () => any;
}

export function ProviderDetails({zIndex, doc, onDone}: ProviderDetailsProps) {
    const [saving, setSaving] = useState(false);

    const save = async (data: any) => {
        setSaving(true);
        let _data = removeEmptyFields(data);
        if (doc) {
            await updateDoc(doc.ref, _data);
        } else {
            await addDoc(collection(fbDb, "providers"), _data);
        }
        setSaving(false);
        onDone();
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();
        event.stopPropagation();

        const data: any = {
            name: event.target.elements.name.value || '',
            bulstat: event.target.elements.bulstat.value || '',
            mol: event.target.elements.mol.value || '',
            email: event.target.elements.email.value || '',
            address: event.target.elements.address.value || '',
            phone: event.target.elements.phone.value || '',
        };
        save(data);
    }

    return (
        <><Form className={"p-2"} onSubmit={handleSubmit}>
            <Modal.Body>
                <h5 className={"mb-3"}>
                    {doc ? "Корекция" : "Добавяне"} на доставчик
                </h5>

                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Име</Form.Label>
                            <Form.Control type="text" name="name" defaultValue={doc?.name}/>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>БУЛСТАТ</Form.Label>
                            <Form.Control type="text" name="bulstat" defaultValue={doc?.bulstat}/>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>МОЛ</Form.Label>
                            <Form.Control type="text" name="mol" defaultValue={doc?.mol}/>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>Адрес</Form.Label>
                            <Form.Control type="text" name="address" defaultValue={doc?.address}/>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>Телефон</Form.Label>
                            <Form.Control type="text" name="phone" defaultValue={doc?.phone}/>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>Е-мейл</Form.Label>
                            <Form.Control type="text" name="email" defaultValue={doc?.email} />
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Row className={"w-100"}>
                    {
                        saving && <Col xs={12} className={"text-center"}>
                            <Spinner animation={"border"}/>
                        </Col>
                    }

                    {
                        !saving &&
                        <>
                            <Col xs={6}>
                                <Button variant={"outline-secondary"} onClick={() => {
                                    onDone()
                                }}>
                                    <FontAwesomeIcon icon={faArrowLeft}/> Към списъка
                                </Button>
                            </Col>
                            <Col xs={6} className={"text-end"}>
                                <Button type={"submit"}>
                                    <FontAwesomeIcon icon={faSave}/> Запиши
                                </Button>
                            </Col>
                        </>
                    }
                </Row>
            </Modal.Footer>
        </Form>
        </>
    )
}
