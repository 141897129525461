import {
    Modal,
    ModalTitle,
} from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import React, {useEffect, useState} from "react";
import {DIALOG_OPTIONS} from "../../../types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList, faRobot} from "@fortawesome/free-solid-svg-icons";
import ModalBackground from "../../../common/ModalBackground";
import DialogCloseButton from "../../../common/DialogCloseButton";
import {VirtualProductsList} from "./VirtualProductsList";
import {VirtualProductDetails} from "./VirtualProductDetails";
import { VirtualProductDelete } from "./VirtualProductDelete";
import {EMPTY_VIRTUAL_PRODUCT, VirtualProductType} from "./hooks/useVirtualProductsOnce";

export function VirtualProductsDialog({zIndex, show, onClose, onSelect}: DIALOG_OPTIONS) {

    const [viewMode, setViewMode] = useState<"VIEW_LIST" | "VIEW_ADD" | "VIEW_EDIT" | "VIEW_DELETE">('VIEW_LIST');
    const [editDocument, setEditDocument] = useState<VirtualProductType | null>(null);
    const [deleteDocument, setDeleteDocument] = useState<VirtualProductType | null>(null);
    const [addDocument, setAddDocument] = useState(false);

    useEffect(() => {
        setViewMode(editDocument ? "VIEW_EDIT" : "VIEW_LIST");
    }, [editDocument])

    useEffect(() => {
        setViewMode(deleteDocument ? "VIEW_DELETE" : "VIEW_LIST");
    }, [deleteDocument])

    useEffect(() => {
        setViewMode(addDocument ? "VIEW_ADD" : "VIEW_LIST");
    }, [addDocument])

    if(!show) {
        return null
    }

    return (
        <ModalBackground zIndex={zIndex}>
            <Modal show={show} size={"lg"} style={{zIndex: (zIndex + 1)}} >
                <ModalHeader>
                    <ModalTitle>
                        <FontAwesomeIcon icon={faList}/> Производствена номенклатура
                    </ModalTitle>
                    <DialogCloseButton onClose={() => onClose(null)} />
                </ModalHeader>
                {
                    <VirtualProductsList onClose={onClose} isVisible={viewMode === "VIEW_LIST"}
                                         onSelect={onSelect}
                                         onAdd={() => setAddDocument(true)}
                                         onEdit={(doc: VirtualProductType) => {
                                     setEditDocument(doc);
                                 }}
                                         onDelete={(doc: VirtualProductType) => {
                                     setDeleteDocument(doc);
                                 }}
                    />
                }
                {
                    viewMode === "VIEW_EDIT" && editDocument &&
                    <VirtualProductDetails virtualProduct={editDocument}
                                           zIndex={(zIndex || 1100) + 10}
                                           onDone={() => {
                                      setEditDocument(null)
                                  }}/>
                }
                {
                    viewMode === "VIEW_DELETE" && deleteDocument &&
                    <VirtualProductDelete doc={deleteDocument} onDone={() => {
                        setDeleteDocument(null)
                    }}/>
                }
                {
                    viewMode === "VIEW_ADD" && addDocument &&
                    <VirtualProductDetails virtualProduct={EMPTY_VIRTUAL_PRODUCT}
                                           zIndex={(zIndex || 1100) + 10}
                                           onDone={() => {
                                      setAddDocument(false)
                                  }}/>
                }

            </Modal>
        </ModalBackground>
    )
}
