import {dateToString} from "../../../common";
import {FC} from "react";
import {ProductionCardExpeditionsType} from "../types/ProductionCardType";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleInfo, faInfo} from "@fortawesome/free-solid-svg-icons";

interface Props {
    expeditions: ProductionCardExpeditionsType;
    expeditionStatus: string | null;
}

export const RenderProductionCardExpeditions: FC<Props> = ({expeditions, expeditionStatus}) => {
    const exp = expeditions?.filter(expedition => expedition.expeditionStatus === expeditionStatus);
    if(expeditions.length > 0) {
        console.log(expeditions);
        console.log(exp);
    }

    return (
        <td>
            {
                expeditions?.filter(expedition => expedition.expeditionStatus === expeditionStatus)
                .map((expedition, index) => {
                const hintText = `Дата: ${dateToString(expedition.expeditionDate)}\nКоличество: ${expedition.qty}\nШофьор: ${expedition.driverName}\nКола: ${expedition.carName}`;
                return <div key={index} className={"text-nowrap"}>
            <span>{dateToString(expedition.expeditionDate)}: {expedition.qty} </span>
            <span>
                            <FontAwesomeIcon icon={faCircleInfo} className={"text-primary"} title={hintText}/>
                        </span>
        </div>
})
}
</td>
)
    ;
}
