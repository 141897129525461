import ModalHeader from "react-bootstrap/ModalHeader";
import {Modal, ModalBody, ModalTitle} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar} from "@fortawesome/free-solid-svg-icons";
import DialogCloseButton from "../../../common/DialogCloseButton";
import React from "react";
import {DIALOG_OPTIONS} from "../../../types";
import ModalBackground from "../../../common/ModalBackground";
import ProductionPlanTable from "./ProductionPlanTable";

const ProductionPlanDialog = ({zIndex, show, onClose, onSelect}: DIALOG_OPTIONS) => {
    return (
        <ModalBackground zIndex={zIndex}>
            <Modal show={show} size={"xl"} style={{zIndex: (zIndex + 1)}}>
                <ModalHeader>
                    <ModalTitle>
                        <FontAwesomeIcon icon={faCalendar}/> Производствен план
                    </ModalTitle>
                    <DialogCloseButton onClose={() => onClose(null)} />
                </ModalHeader>
                <ModalBody>
                    <ProductionPlanTable onSelect={
                        (selected) => {
                            onSelect && onSelect(selected);
                            onClose(null);
                        }
                    } />
                </ModalBody>
            </Modal>
        </ModalBackground>
    )
}

export default ProductionPlanDialog;
