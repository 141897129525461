import React, {useEffect, useState} from "react";

import {updateDoc, addDoc, collection, getFirestore} from "firebase/firestore";
import {Button, Col, Form, Modal, Row, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faSave} from "@fortawesome/free-solid-svg-icons";
import {fbDb, firebaseApp} from "../../../App";
import {removeEmptyFields, textToFloat,} from "../../../common";
import SelectValueFromDialog from "../../../common/SelectValueFromDialog";
import {ProductDimensesDialog} from "../product-dimens/ProductDimensesDialog";
import {VirtualProductType} from "./hooks/useVirtualProductsOnce";
import {ProductsDialog} from "../products/ProductsDialog";
import {WarehouseOutItemType} from "../../warehouse/hooks/useWarehouseOutItems";
import {ProductType} from "../products/hooks/useProductsOnce";
import {useHookstate} from "@hookstate/core";
import appDb from "../../../global-state/global-db";

export interface VirtualProductDetailsProps {
    zIndex?: number;
    virtualProduct: VirtualProductType;
    onDone: () => any;
}

export function VirtualProductDetails({zIndex, virtualProduct, onDone}: VirtualProductDetailsProps) {
    const [saving, setSaving] = useState(false);
    const [doc, setDoc] = useState({...virtualProduct});
    const [product, setProduct] = useState<ProductType | undefined>();

    const products = useHookstate(appDb.products);
    useEffect(() => {
        const product = products.value.find(p => p.id === doc.productId);
        if(product) {
            setProduct({...product} as ProductType);
        } else setProduct(undefined);
    }, []);

    const save = async () => {

        setSaving(true);
        let _data = removeEmptyFields({...doc,
            isOwnProduct: true,
            attributes: []
        });

        if (doc.ref) {
            await updateDoc(doc.ref, {..._data});
        } else {
            await addDoc(collection(fbDb, "products"), _data);
        }
        setSaving(false);
        onDone();
    }


    const productDimensDialog = <ProductDimensesDialog show={true} zIndex={zIndex || 1020} onClose={() => {
    }}/>
    const productsDialog = <ProductsDialog show={true} zIndex={(zIndex || 1060) + 1} onClose={() => {
    }}/>

    return (
        <>
            <Form className={"p-2"}>
                <Modal.Body>
                    <h5 className={"mb-3"}>
                        {doc && doc.ref ? "Корекция" : "Добавяне"} на продукт
                    </h5>

                    <Row>
                        <Col>
                            <Form.Label>Изберете продукт</Form.Label>
                            <SelectValueFromDialog size={"sm"} className={"mb-0"}
                                                   value={product?.name}
                                                   onChange={(item) => {
                                                       setProduct(item);
                                                       setDoc(prevState => {
                                                           return {
                                                               ...prevState,
                                                               sku: "P" + (item.sku || ""),
                                                               name: item.name,
                                                               dimension: item.dimens,
                                                               productId: item.id,
                                                               weight: item.weightWithElectrolit,
                                                           }
                                                       })
                                                   }}
                                                   dialog={productsDialog}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Row>
                                <Col xs={2}>
                                    <Form.Group>
                                        <Form.Label>SKU</Form.Label>
                                        <Form.Control type="text" value={doc.sku || ""}
                                                      onChange={e => setDoc({...doc, sku: e.target.value})}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Име</Form.Label>
                                        <Form.Control type="text" value={doc.name}
                                                      onChange={e => setDoc({...doc, name: e.target.value})}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={2}>
                                    <SelectValueFromDialog label={"Мерна единица"}
                                                           value={doc.dimension}
                                                           onChange={(productDimens: any) => setDoc({
                                                               ...doc,
                                                               dimension: productDimens?.name || ""
                                                           })}
                                                           dialog={productDimensDialog}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={2}>
                                    <Form.Group>
                                        <Form.Label>Тегло</Form.Label>
                                        <Form.Control type="text" value={doc.weight || ""}
                                                      onChange={e => setDoc({...doc, weight: textToFloat(e.target.value) || 0})}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col></Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Описание</Form.Label>
                                <Form.Control as="textarea" rows={2}
                                              onChange={e => setDoc({...doc, description: e.target.value})}
                                              value={doc.description}/>
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>

                <Modal.Footer>
                    <Row className={"w-100"}>
                        {
                            saving && <Col xs={12} className={"text-center"}>
                                <Spinner animation={"border"}/>
                            </Col>
                        }

                        {
                            !saving &&
                            <>
                                <Col xs={6}>
                                    <Button variant={"outline-secondary"} onClick={() => {
                                        onDone()
                                    }}>
                                        <FontAwesomeIcon icon={faArrowLeft}/> Към списъка
                                    </Button>
                                </Col>
                                <Col xs={6} className={"text-end"}>
                                    <Button onClick={save}>
                                        <FontAwesomeIcon icon={faSave}/> Запиши
                                    </Button>
                                </Col>
                            </>
                        }
                    </Row>
                </Modal.Footer>
            </Form>
        </>
    )
}
