import {DocumentReference, FirestoreError, QueryDocumentSnapshot,} from "firebase/firestore";
import {useEffect, useState} from "react";
import useFirebaseCollectionOnce from "../../../../hooks/useFirebaseCollectionOnce";


export interface SalesRepresentativeType {
    id?: string;
    ref?: DocumentReference;
    name: string;
}

export type SalesRepresentativesType = SalesRepresentativeType[];


export function convertSalesRepresentativesData(_objects: QueryDocumentSnapshot[]): SalesRepresentativesType {
    if(_objects) {
        return _objects.map(
            d => {
                return {
                    id: d.id,
                    ref: d.ref,
                    name: d.get('name'),
                } as SalesRepresentativeType;
            }
        );
    } else return [];
}



export default function useSalesRepresentativesOnce(): [SalesRepresentativesType, boolean, FirestoreError | undefined] {
    const [documents, loading, error] = useFirebaseCollectionOnce("salesRepresentatives");
    const [convertedData, setConvertedData] = useState<SalesRepresentativesType>([]);


    useEffect(() => {
        if(documents) {
            setConvertedData(convertSalesRepresentativesData(documents));
        }
    }, [documents]);

    return [convertedData, loading, error];
}
