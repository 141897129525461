import {useState} from "react";
import TrashIconButton from "./TrashIconButton";
import CheckIconButton from "./CheckIconButton";
import CancelIconButton from "./CancelIconButton";

export default function DeleteConfirmIconButton(props: { size?: "sm" | "lg", onClick: VoidFunction }) {
    const [mode, setMode] = useState(false);

    if (!mode) return <TrashIconButton size={props.size} onClick={() => setMode(true)}/>

    return <>
        <CheckIconButton size={props.size} onClick={() => {
            props.onClick && props.onClick();
            setMode(false);
        }}/>&nbsp;
        <CancelIconButton size={props.size}  onClick={() => setMode(false)}/>
    </>
}
