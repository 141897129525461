import {FC, useEffect, useState} from "react";
import {ClientType} from "../../nomenclatures/clients/hooks/useClients";
import {ClientsDialog} from "../../nomenclatures/clients/ClientsDialog";
import {Col, Form, Row} from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import SelectValueFromDialog from "../../../common/SelectValueFromDialog";
import CheckIconButton from "../../../common/icon-buttons/CheckIconButton";
import {UserType} from "../../../fb-converters/users-converter";
import {UsersDialog} from "../../nomenclatures/users/UsersDialog";
import {CarsDialog} from "../../nomenclatures/cars/CarsDialog";
import {CarType} from "../../../fb-converters/car-converter";
import I18Label from "../../../i18/i18label";

export interface ExpeditionPlanFilterType {
    fromDate: Date;
    toDate: Date;
    client: ClientType | null;
    driver: UserType | null;
    car: CarType | null;
}


interface Props {
    filter: ExpeditionPlanFilterType;
    onChange: (filter: ExpeditionPlanFilterType) => void;
}

export const DEFAULT_EXPEDITION_FILTER: ExpeditionPlanFilterType = {
    fromDate: new Date(),
    toDate: new Date(),
    client: null,
    driver: null,
    car: null
};

const ExpeditionPlanFilter: FC<Props> = (props) => {
    const [filter, setFilter] = useState<ExpeditionPlanFilterType>({...DEFAULT_EXPEDITION_FILTER});
    const clientsDialog = <ClientsDialog show={true} zIndex={1060} onClose={() => {
    }}/>
    const driversDialog = <UsersDialog show={true} zIndex={1060} onClose={() => {
    }}/>
    const carsDialog = <CarsDialog show={true} zIndex={1060} onClose={() => {
    }}/>

    useEffect(() => {
        setFilter({...props.filter});
    }, [props.filter]);

    function applyFilter() {
        props.onChange && props.onChange(filter);
    }

    return <Row>
        <Col xs={"auto"}><strong>Филтър:</strong></Col>
        <Col xs={"auto"}>
            <Form.Group className={"w-120px"}>
                <Form.Label className={"small"}>Начална дата</Form.Label>
                <ReactDatePicker className={"form-control"}
                                 isClearable={false}
                                 dateFormat="dd.MM.yyyy"
                                 onChange={(d) => {
                                     if (d) {
                                         d.setMinutes(0);
                                         d.setHours(0);
                                         d.setSeconds(0);
                                         d.setMilliseconds(0);
                                         setFilter(prevState => {
                                             prevState.fromDate = d;
                                             if (prevState.toDate < d) {
                                                 prevState.toDate = d;
                                             }
                                             return {...prevState};
                                         });
                                     }
                                 }}
                                 selected={filter.fromDate}
                />
            </Form.Group>
        </Col>
        <Col xs={"auto"}>
            <Form.Group className={"w-120px"}>
                <Form.Label className={"small"}>Крайна дата</Form.Label>
                <ReactDatePicker className={"form-control"}
                                 isClearable={false}
                                 dateFormat="dd.MM.yyyy"
                                 onChange={(d) => {
                                     if (d) {
                                         d.setMinutes(59);
                                         d.setHours(23);
                                         d.setSeconds(59);
                                         d.setMilliseconds(999);
                                         setFilter(prevState => {
                                             prevState.toDate = d
                                             return {...prevState};
                                         });
                                     }
                                 }}
                                 selected={filter.toDate}
                                 minDate={filter.fromDate}
                />
            </Form.Group>
        </Col>
        <Col>
            <Form.Group className={"w-100"}>
                <Form.Label>Шофьор:</Form.Label>
                <SelectValueFromDialog value={filter.driver?.name || ''}
                                       onChange={(driver) => {
                                           setFilter(prevState => {
                                               return {...prevState, driver: driver}
                                           })
                                       }}
                                       dialog={driversDialog}/>
            </Form.Group>
        </Col>
        <Col>
            <Form.Group className={"w-100"}>
                <Form.Label>Превозно средство:</Form.Label>
                <SelectValueFromDialog value={`${filter.car?.name || ''} ${filter.car?.regPlate || ''}`}
                                       onChange={(car) => {
                                           setFilter(prevState => {
                                               return {...prevState, car: car}
                                           })
                                       }}
                                       dialog={carsDialog}/>
            </Form.Group>
        </Col>
        <Col>
            <Form.Group className={"w-100"}>
                <Form.Label><I18Label label={"Клиент"} />:</Form.Label>
                <SelectValueFromDialog value={filter.client?.name || ''}
                                       onChange={(client) => {
                                           setFilter(prevState => {
                                               return {...prevState, client: client}
                                           })
                                       }}
                                       dialog={clientsDialog}/>
            </Form.Group>
        </Col>

        <Col xs={"auto"}>
            <div style={{paddingTop: "30px"}}>
                <CheckIconButton onClick={applyFilter}/>
            </div>
        </Col>
    </Row>
}

export default ExpeditionPlanFilter;
