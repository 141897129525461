import {FirestoreError, QueryConstraint, where} from "firebase/firestore";
import {useEffect, useState} from "react";
import {convertProducts, ProductsType} from "./useProductsOnce";
import useFirebaseCollection from "../../../../hooks/useFirebaseCollection";

export default function useProducts(constraints?: QueryConstraint[]): [ProductsType, boolean, FirestoreError | undefined] {
    const [documents, loading, error] = useFirebaseCollection("products",
        [where("isOwnProduct", "==", false), ...(constraints || [])]);
    const [convertedData, setConvertedData] = useState<ProductsType>([]);

    useEffect(() => {
        if(documents) {
            setConvertedData(convertProducts(documents.docs));
        }
    }, [documents]);

    return [convertedData, loading, error];
}
