import {DocumentData, DocumentReference, QueryDocumentSnapshot, SnapshotOptions} from "firebase/firestore";

export type BankAccountType = {
    id?: string;
    ref?: DocumentReference;
    iban: string;
    bic: string;
    bank: string;
}

export const BankAccountConverter = {
    toFirestore(bankAccount: BankAccountType): DocumentData {
        return {
            iban: bankAccount.iban,
            bic: bankAccount.bic,
            bank: bankAccount.bank,
        };
    },
    fromFirestore(
        snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions
    ): BankAccountType {
        const bankAccount = snapshot.data(options)!;
        return {
            id: snapshot.id,
            ref: snapshot.ref,
            iban: bankAccount.iban,
            bic: bankAccount.bic,
            bank: bankAccount.bank,
        } as BankAccountType;
    }
};
