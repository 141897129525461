import {ClientDetailsGeneral} from "./ClientDetailsGeneral";
import {Tab, Tabs} from "react-bootstrap";
import {ClientDetailsLocations} from "./ClientDetailsLocations";
import {ClientType} from "../hooks/useClients";
import {SalesRepresentativesType} from "../hooks/useSalesRepresentativesOnce";
import {BusinessSizeTypes} from "../hooks/useBusinessSizesOnce";
import {useEffect, useMemo, useState} from "react";
import {ClientDetailsCrmActivities} from "./ClientDetailsCrmActivities";
import {ClientLocationType} from "../../../../fb-converters/location-converter";
import CustomersInquiriesPage from "../../../crm/customers-inquiries/CustomersInquiriesPage";
import I18Label from "../../../../i18/i18label";


export interface ClientDetailsProps {
    zIndex?: number;
    doc: ClientType | null;
    sizeGroups: BusinessSizeTypes | null | undefined;
    salesRepresentatives: SalesRepresentativesType | null | undefined;
    onDone: () => any;
    onSelect?: (location: ClientLocationType) => any;
}

export function ClientDetail(props: ClientDetailsProps) {
    const [activeTab, setActiveTab] = useState<string>("tab1");
    const [tab2Visible, setTab2Visible] = useState(false);
    const [tab3Visible, setTab3Visible] = useState(false);
    const [tab4Visible, setTab4Visible] = useState(false);

    useEffect(() => {
        if (activeTab === "tab2") {
            setTab2Visible(true);
        } else if (activeTab === "tab3") {
            setTab3Visible(true);
        } else if (activeTab === "tab4") {
            setTab4Visible(true);
        }
    }, [activeTab]);

    const tab2Content = useMemo(() =>
            tab2Visible ?
                <ClientDetailsLocations doc={props.doc!} zIndex={props.zIndex || 1022} onDone={props.onDone}
                                        onSelect={props.onSelect}/>
                : null
        , [tab2Visible]);

    const tab3Content = useMemo(() =>
            tab3Visible ?
                <ClientDetailsCrmActivities doc={props.doc!} zIndex={props.zIndex || 1022} onDone={props.onDone}
                                            onSelect={props.onSelect}/>
                : null
        , [tab3Visible]);

    const tab4Content = useMemo(() =>
            tab4Visible ?
                <CustomersInquiriesPage client={props.doc} onDone={props.onDone} />
                : null
        , [tab4Visible]);

    return (
        <Tabs activeKey={activeTab} className={"mt-1 ms-2"}
              onSelect={eventKey => setActiveTab(eventKey || "tab1")}
        >
            <Tab eventKey={"tab1"} title={<I18Label label={"Основна информация за клиента"} /> }>
                <ClientDetailsGeneral {...props} />
            </Tab>
            {
                props.doc &&
                <Tab eventKey={"tab2"} title={<I18Label label={"Локации"} />}>
                    {tab2Content}
                </Tab>
            }
            {
                props.doc &&
                <Tab eventKey={"tab3"} title={<I18Label label={"CRM активности"} />}>
                    {tab3Content}
                </Tab>
            }
            {
                props.doc &&
                <Tab eventKey={"tab4"} title={<I18Label label={"Запитвания и оферти"} />}>
                    {tab4Content}
                </Tab>
            }
        </Tabs>
    )


}
