import {DocumentData, DocumentReference, QueryDocumentSnapshot, SnapshotOptions,} from "firebase/firestore";
import {ClientLocationType} from "./location-converter";
import {ProductType} from "../pages/nomenclatures/products/hooks/useProductsOnce";
import {removeEmptyFields} from "../common";
import {ClientType} from "../pages/nomenclatures/clients/hooks/useClients";
import {OwnCompanyType} from "./own-company-converter";

export type CustomerInquiryProductType = {
    product: ProductType | null;
    name: string | null;
    size: {
        l: string | null;
        b: string | null;
        h: string | null;
        h1: string | null;
    } | null,
    buyback: boolean;
    machineDescription: string;
    comments: string;
}
export type CustomerInquiryProductsType = CustomerInquiryProductType[];

export type InqueryOfferListItemType = {
    offerId: string;
    offerDate: Date;
    offerNumber: number;
}

export type CustomerInquiryType = {
    id?: string;
    ref?: DocumentReference;
    inquiryNumber: number;
    inquiryDate: Date;
    location: ClientLocationType | null;
    client: ClientType | null;
    ownCompany: OwnCompanyType | null;
    inquiryContent: string | null;
    inquiryProducts: CustomerInquiryProductsType;
    services: string[];
    fileUrls: string[];
    additionalInfo: string | null;
    bestPriceProductIndex: number | null;
    offers: InqueryOfferListItemType[];
}

export const customerInquiryConverter = {
    toFirestore(inquiry: CustomerInquiryType): DocumentData {
        const _inquiry = {
            inquiryNumber: inquiry.inquiryNumber || null,
            inquiryDate: inquiry.inquiryDate || null,
            location: removeEmptyFields(inquiry.location),
            client: removeEmptyFields(inquiry.client),
            ownCompany: removeEmptyFields(inquiry.ownCompany),
            inquiryContent: inquiry.inquiryContent || null,
            inquiryProducts: inquiry.inquiryProducts.map(ip => ip && {
                ...removeEmptyFields(ip),
                product: removeEmptyFields(ip.product)
            }),
            services: inquiry.services || null,
            fileUrls: inquiry.fileUrls || null,
            additionalInfo: inquiry.additionalInfo || null,
            bestPriceProduct: inquiry.bestPriceProductIndex || null,
            offers: inquiry.offers || [],
        }
        return _inquiry;
    },
    fromFirestore(
        snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions
    ): CustomerInquiryType {
        const inquiry = snapshot.data(options)!;
        return {
            id: snapshot.id,
            ref: snapshot.ref,
            inquiryNumber: inquiry.inquiryNumber,
            inquiryDate: inquiry.inquiryDate.toDate(),
            location: inquiry.location,
            client: inquiry.client,
            ownCompany: inquiry.ownCompany,
            inquiryContent: inquiry.inquiryContent,
            inquiryProducts: inquiry.inquiryProducts,
            services: inquiry.services,
            fileUrls: inquiry.fileUrls,
            additionalInfo: inquiry.additionalInfo,
            bestPriceProductIndex: inquiry.bestPriceProductIndex || null,
            offers: (inquiry.offers || []).map(
                (o: any) => ({
                    offerId: o.offerId,
                    offerDate: o.offerDate.toDate(),
                    offerNumber: o.offerNumber
                })
            ),
        } as CustomerInquiryType;
    }
};
