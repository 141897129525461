import {Button, Form, ModalBody, ModalFooter, Spinner, Tab, Table, Tabs} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCancel, faCircleArrowDown, faEdit, faPlus, faSearch, faTrash} from "@fortawesome/free-solid-svg-icons";
import {useEffect, useState} from "react";
import LoadDataFromServerError from "../../../common/LoadDataFromServerError";
import useProviders, {ProvidersType, ProviderType} from "./hooks/useProviders";


export interface ClientsListProps {
    isVisible: boolean;
    onAdd: () => any;
    onEdit: (item: ProviderType) => any;
    onDelete: (item: ProviderType) => any;
    onClose: (result: ProviderType | null) => any;
    onSelect?: (result: ProviderType) => any;
}

export function ProvidersList({isVisible, onAdd, onEdit, onDelete, onClose, onSelect}: ClientsListProps) {

    const [filterName, setFilterName] = useState('')
    const [filterBulstat, setFilterBulstat] = useState('')
    const [filterMol, setFilterMol] = useState('')
    const [filterEmail, setFilterEmail] = useState('')
    const [filterPhone, setFilterPhone] = useState('')
    const [filterAddress, setFilterAddress] = useState('')

    const [providers, loading, error] = useProviders();

    const [filtered, setFilteredUsers] = useState<ProvidersType>([])

    useEffect(() => {
        if (providers) {
            setFilteredUsers(providers.filter(
                r => (!filterName || filterName.length === 0 || r.name.toLowerCase().indexOf(filterName.toLowerCase()) > -1) &&
                    (!filterBulstat || filterBulstat.length === 0 || !r.bulstat || r.bulstat?.toLowerCase().indexOf(filterEmail.toLowerCase()) > -1) &&
                    (!filterMol || filterMol.length === 0 || !r.mol || r.mol?.toLowerCase().indexOf(filterEmail.toLowerCase()) > -1) &&
                    (!filterEmail || filterEmail.length === 0 || !r.email || r.email?.toLowerCase().indexOf(filterEmail.toLowerCase()) > -1) &&
                    (!filterPhone || filterPhone.length === 0 || !r.phone || r.phone?.toLowerCase().indexOf(filterPhone.toLowerCase()) > -1) &&
                    (!filterAddress || filterAddress.length === 0 || !r.address || r.address.toLowerCase().indexOf(filterAddress.toLowerCase()) > -1)
            ));
        } else setFilteredUsers([]);
    }, [providers, filterName, filterEmail, filterPhone, filterAddress]);

    function isLoading() {
        return loading;
    }

    return (
        <>
            {isLoading() && <Spinner animation={"border"}/>}

            {
                !isLoading() && error && <LoadDataFromServerError />
            }

            {
                !loading && !error && isVisible &&
                <>
                    <ModalBody>
                        <div className={"max-h-60vh scrollable"}>
                            <Table size={"sm"} borderless hover>
                                <thead style={{backgroundColor: "white"}}>
                            <tr>
                                <th style={{width: onSelect ? 120: 90}}></th>
                                <th>Име</th>
                                <th>Булстат</th>
                                <th>Мол</th>
                                <th>Имейл</th>
                                <th>Телефон</th>
                                <th>Адрес</th>
                            </tr>
                            <tr>
                                <td className={"text-start"}>
                                    <FontAwesomeIcon icon={faSearch}/>
                                </td>
                                <td>
                                    <Form.Control size={"sm"} value={filterName}
                                                  onChange={(e) => setFilterName(e.target.value)}/>
                                </td>
                                <td>
                                    <Form.Control size={"sm"} value={filterBulstat}
                                                  onChange={(e) => setFilterBulstat(e.target.value)}/>
                                </td>
                                <td>
                                    <Form.Control size={"sm"} value={filterMol}
                                                  onChange={(e) => setFilterMol(e.target.value)}/>
                                </td>
                                <td>
                                    <Form.Control size={"sm"} value={filterEmail}
                                                  onChange={(e) => setFilterEmail(e.target.value)}/>
                                </td>
                                <td>
                                    <Form.Control size={"sm"} value={filterPhone}
                                                  onChange={(e) => setFilterPhone(e.target.value)}/>
                                </td>
                                <td>
                                    <Form.Control size={"sm"} value={filterAddress}
                                                  onChange={(e) => setFilterAddress(e.target.value)}/>
                                </td>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                loading &&
                                <tr>
                                    <td colSpan={6} className={"text-center fw-bold"}>
                                        <Spinner animation={"border"}/>
                                    </td>
                                </tr>
                            }
                            {
                                filtered && filtered.length === 0 &&
                                <tr>
                                    <td colSpan={6} className={"text-center fw-bold"}>Няма доставчици</td>
                                </tr>
                            }
                            {
                                filtered && filtered.length > 0 && filtered.map(
                                    r =>
                                        <tr key={r.id} className={"tr-bordered"}>
                                            <td>
                                                <Button size={"sm"} variant={"outline-secondary"}
                                                        onClick={() => onEdit(r)}
                                                >
                                                    <FontAwesomeIcon icon={faEdit}/>
                                                </Button>&nbsp;
                                                <Button size={"sm"} variant={"outline-danger"}
                                                        onClick={() => {
                                                            onDelete(r)
                                                        }}
                                                >
                                                    <FontAwesomeIcon icon={faTrash}/>
                                                </Button>&nbsp;
                                                {
                                                    onSelect &&
                                                    <Button size={"sm"} variant={"outline-success"}
                                                            onClick={() => {onSelect(r)}}
                                                    >
                                                        <FontAwesomeIcon icon={faCircleArrowDown}/>
                                                    </Button>
                                                }
                                            </td>
                                            <td>
                                                {r.name}
                                            </td>
                                            <td>
                                                {r.bulstat}
                                            </td>
                                            <td>
                                                {r.mol}
                                            </td>
                                            <td>
                                                {r.email}
                                            </td>
                                            <td>
                                                {r.phone}
                                            </td>
                                            <td>
                                                {r.address}
                                            </td>
                                        </tr>
                                )
                            }
                            </tbody>

                        </Table>
                        </div>
                    </ModalBody>

                    <ModalFooter>
                        <Button onClick={() => onAdd()}>
                            <FontAwesomeIcon icon={faPlus}/> Добави нов
                        </Button>
                        <Button variant={"outline-secondary"} onClick={() => {
                            onClose(null)
                        }}>
                            <FontAwesomeIcon icon={faCancel}/> Затвори
                        </Button>
                    </ModalFooter>
                </>
            }
        </>
    )
}
