import {Account} from "./types";


export const API_CLASS = class {
    public user: Account|null = null;
    public userId: string | null = null;
    public currentUser: any = null;
}

export const API = new API_CLASS();
