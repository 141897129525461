import {
    Button,
    Card, Col, Container, Row, Tab, Table, Tabs,
} from "react-bootstrap";
import React, {useEffect, useMemo} from "react";
import LoadingErrorContent from "../../../common/LoadingErrorContent";
import {useCollection, useDocument} from "react-firebase-hooks/firestore";
import {addDoc, collection, deleteDoc, doc, getDoc, query, setDoc, updateDoc, where} from "firebase/firestore";
import {fbDb} from "../../../App";
import {
    EMPTY_WAREHOUSE_REVISION_INDEX_ITEM,
    warehouseRevisionIndexConverter,
    WarehouseRevisionIndexItemType, warehouseRevisionItemConverter,
    WarehouseRevisionItemsType,
    WarehouseRevisionItemType,
} from "../../../fb-converters/warehouse-revision-converter";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import WarehouseRevisionDetails from "./WarehouseRevisionDetails";
import RightAlignedCell from "../../../common/RightAlignedCell";
import {dateToString, zeroToEmptyStringAsCurrency} from "../../../common";
import EditIconButton from "../../../common/icon-buttons/EditIconButton";
import DeleteConfirmIconButton from "../../../common/icon-buttons/DeleteConfirmIconButton";
import UseRecalcWarehouseItemStatus from "../hooks/useRecalcWarehouseItemQty";
import {DEFAULT_WAREHOUSE} from "../consts";
import PrintIconButton from "../../../common/icon-buttons/PrintIconButton";
import WarehouseRevisionPrintPreview from "../print/warehouse-revision/WarehouseRevisionPrintPreview";

const WarehouseRevisionsPage = () => {
    const [selectedYear, setSelectedYear] = React.useState<string>(new Date().getFullYear().toString());
    const [editRevision, setEditRevision] = React.useState<WarehouseRevisionIndexItemType | null>(null);
    const [printRevision, setPrintRevision] = React.useState<WarehouseRevisionIndexItemType | null>(null);

    const [indexData, indexLoading, indexError] =
        useDocument(doc(fbDb, `warehouseRevisions/index`).withConverter(warehouseRevisionIndexConverter));

    const recalcWarehouseItemQty = UseRecalcWarehouseItemStatus();

    useEffect(() => {
        if (indexData && !indexData.exists()) {
            const newIndex = {
                items: []
            };
            setDoc(doc(fbDb, "warehouseRevisions", "index"), newIndex);
        }
    }, [indexData]);

    const index = useMemo(() => {
        if (indexData) {
            return indexData.data();
        }
        return null;
    }, [indexData]);

    const years = useMemo(() => {
        const newYears = new Set<string>();
        newYears.add(new Date().getFullYear().toString());
        if (index) {

            index.items.forEach(item => {
                const year = item.revisionDate.getFullYear().toString();
                newYears.add(year);
            });
        }
        const yearList = Array.from(newYears).sort((a, b) => parseInt(b) - parseInt(a));
        if (!yearList.includes(selectedYear)) {
            setSelectedYear(yearList[0]);
        }
        return yearList;
    }, [index]);

    const isLoading = indexLoading;
    const errors = indexError ? [indexError] : [];

    function addNew() {
        setEditRevision({
            ...EMPTY_WAREHOUSE_REVISION_INDEX_ITEM
        });
    }

    async function onDone(revision: WarehouseRevisionIndexItemType | null, revisionItems: WarehouseRevisionItemType[], updatedItems: WarehouseRevisionItemType[]) {
        if (revision) {
            let newIndex = index ? {...index} : {items: []};
            let revisionId = revision.id;
            const revisionItemsData: WarehouseRevisionItemsType = {
                revisionDate: revision.revisionDate,
                items: revisionItems,
                productIds: revisionItems.map(item => item.productId ?? ""),
            };
            if (!revisionId) {
                revision.revisionNumber = newIndex.items.reduce((max, item) => Math.max(max, item.revisionNumber), 0) + 1;
                const newRevisionRef = await addDoc(collection(fbDb, "warehouseRevisions"), revisionItemsData);
                revision.id = newRevisionRef.id;
                newIndex.items.push(revision);
            } else {
                await updateDoc(doc(fbDb, `warehouseRevisions/${revisionId}`), revisionItemsData);
                const indexItem = newIndex.items.find(item => item.id === revisionId);
                if (indexItem) {
                    indexItem.revisionNumber = revision.revisionNumber;
                    indexItem.revisionDate = revision.revisionDate;
                    indexItem.revisionAmount = revision.revisionAmount;
                    indexItem.createdBy = revision.createdBy;
                    indexItem.receivedBy = revision.receivedBy;
                }
            }

            await updateDoc(doc(fbDb, "warehouseRevisions", "index"), newIndex);
            updatedItems.forEach(async item => {
                if (item.productId) {
                    await recalcWarehouseItemQty(DEFAULT_WAREHOUSE, item.productId, item.productIsOwnProduction, undefined);
                }
            });
        }

        setEditRevision(null);
    }

    async function onDelete(itemIndex: number) {
        const itemId = index!.items[itemIndex].id;
        const newIndex = {...index};
        newIndex.items!.splice(itemIndex, 1);

        await setDoc(doc(fbDb, "warehouseRevisions", "index"), newIndex);

        const revisionRef = doc(fbDb, `warehouseRevisions/${itemId}`).withConverter(warehouseRevisionItemConverter);
        const revisionData = await getDoc<WarehouseRevisionItemsType>(revisionRef);
        const revisionItems = revisionData.data()?.items ?? [];

        await deleteDoc(revisionRef);

        revisionItems.forEach(async item => {
            if (item.productId) {
                await recalcWarehouseItemQty(DEFAULT_WAREHOUSE, item.productId, item.productIsOwnProduction, undefined);
            }
        });
    }

    return (
        <Container>
            <Card>
                <Card.Header>
                    <Row>
                        <Col>
                            <Card.Title>Склад - складови ревизии</Card.Title>
                        </Col>
                        <Col className={"text-end"}>
                            <Button variant={"primary"} onClick={addNew}>
                                <FontAwesomeIcon icon={faPlus}/>&nbsp;Добави ревизия</Button>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    <LoadingErrorContent loading={isLoading}
                                         error={errors.length > 0 ? errors.toString() : undefined}>
                        <>
                            <Tabs activeKey={selectedYear} onSelect={k => setSelectedYear(k ?? years[0])}>
                                {years.map(year => (
                                    <Tab key={year} eventKey={year} title={year}></Tab>
                                ))}
                            </Tabs>
                            <Table striped bordered hover>
                                <thead>
                                <tr>
                                    <th className={"text-center w-100px"}></th>
                                    <RightAlignedCell header>Номер</RightAlignedCell>
                                    <th>Дата</th>
                                    <RightAlignedCell header>Сума</RightAlignedCell>
                                    <th>Създал</th>
                                    <th>Получил</th>
                                </tr>
                                </thead>
                                <tbody>
                                {index && index.items.filter(item =>
                                    item.revisionDate.getFullYear().toString() === selectedYear).map((item, itemIndex) => (
                                    <tr key={item.id}>
                                        <td className={"text-center"}>
                                            <PrintIconButton size={"sm"} onClick={() => setPrintRevision(item)}/>&nbsp;
                                            <EditIconButton onClick={() => setEditRevision(item)}/>&nbsp;
                                            <DeleteConfirmIconButton size={"sm"} onClick={() => onDelete(itemIndex)}/>
                                        </td>
                                        <RightAlignedCell className={"w-100px"}>{item.revisionNumber}</RightAlignedCell>
                                        <td className={"w-100px"}>{dateToString(item.revisionDate)}</td>
                                        <RightAlignedCell
                                            className={"w-100px"}>{zeroToEmptyStringAsCurrency(item.revisionAmount)}</RightAlignedCell>
                                        <td>{item.createdBy}</td>
                                        <td>{item.receivedBy}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        </>
                    </LoadingErrorContent>
                </Card.Body>
            </Card>

            {
                editRevision && <WarehouseRevisionDetails zIndex={1060}
                                                          editRevision={editRevision}
                                                          onDone={onDone}
                />
            }

            {
                printRevision && <WarehouseRevisionPrintPreview zIndex={1060}
                                                                warehouseRevison={printRevision}
                                                                onDismiss={() => setPrintRevision(null)}
                />
            }
        </Container>
    )
}

export default WarehouseRevisionsPage;
