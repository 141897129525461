import {useEffect, useState} from "react";
import {Pagination} from "react-bootstrap";

export default function UsePaginatorPages(recordCount, pageSize, activePage, onSelectPage) {
    const [pages, setPages] = useState([]);

    useEffect(
        () => {
            const _pages = [];
            const pageCnt = Math.ceil(recordCount / pageSize) - 1;

            for (let i = 0; i <= pageCnt; i++) {
                _pages.push(
                    <Pagination.Item key={i} active={i === activePage} onClick={() => onSelectPage(i)}>
                        {(i + 1)}
                    </Pagination.Item>
                );
            }

            setPages(_pages);
        }, [recordCount, pageSize, activePage]
    );

    return pages;
}
