import {DocumentReference, FirestoreError, QueryDocumentSnapshot,} from "firebase/firestore";
import {useEffect, useState} from "react";
import useFirebaseCollectionOnce from "../../../../hooks/useFirebaseCollectionOnce";


export interface CountryType {
    id: string;
    ref: DocumentReference;
    name: string;
}

export type CountryTypes = CountryType[];


export function convertCountryData(_objects: QueryDocumentSnapshot[]): CountryTypes {
    if(_objects) {
        return _objects.map(
            d => {
                return {
                    id: d.id,
                    ref: d.ref,
                    name: d.get('name'),
                } as CountryType;
            }
        );
    } else return [];
}



export default function useCountriesOnce(): [CountryTypes, boolean, FirestoreError | undefined] {
    const [documents, loading, error] = useFirebaseCollectionOnce("countries");
    const [convertedData, setConvertedData] = useState<CountryTypes>([]);


    useEffect(() => {
        if(documents) {
            setConvertedData(convertCountryData(documents));
        }
    }, [documents]);

    return [convertedData, loading, error];
}
