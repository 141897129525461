import {Card, Container} from "react-bootstrap";
import ProductionPlanTable from "./ProductionPlanTable";

const ProductionPlan = () => {
    // const [showDetails, setShowDetails] = useState(false);
    //
    // function addNewCard() {
    //     setShowDetails(true);
    // }

    return (
        <Container>
            <Card>
                <Card.Header>
                    <Card.Title>Производствен план</Card.Title>
                </Card.Header>

                <Card.Body>
                    <ProductionPlanTable />
                </Card.Body>

                <Card.Footer className={"text-end"}>
                    {/*<Button onClick={() => addNewCard()}>*/}
                    {/*    <FontAwesomeIcon icon={faPlus*/}
                    {/*    }/>&nbsp;*/}
                    {/*    Добави производствен план</Button>*/}
                </Card.Footer>
            </Card>



        </Container>
    )
}

export default ProductionPlan;
