import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useState} from "react";
import {faUser, faAngleRight, faUserLock, faArrowLeft, faAngleLeft} from "@fortawesome/free-solid-svg-icons";
import {useCollection} from "react-firebase-hooks/firestore";
import {getFirestore, collection} from 'firebase/firestore';
import {auth, fbDb, firebaseApp} from "../App";
import {Button, Col, Row, Spinner} from "react-bootstrap";
import {
    signInWithEmailAndPassword as firebaseSignInWithEmailAndPassword,
    AuthError,
} from 'firebase/auth';
import {API} from "../api";

interface SelectedUser {
    email: string;
    name: string;
}

export function LoginPage(props: any) {
    const [filter, setFilter] = useState('');
    const [password, setPassword] = useState('');
    const [selectedUser, setSelectedUser] = useState<SelectedUser | null>(null);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<AuthError>();

    const [users, usersLoading, usersError] = useCollection(
        collection(fbDb, 'users'),
        {
            snapshotListenOptions: {includeMetadataChanges: true},
        }
    );

    const doLogin = async () => {
        const email = selectedUser?.email || '';
        setLoading(true);
        setError(undefined);
        try {
            const user = await firebaseSignInWithEmailAndPassword(
                auth,
                email,
                password
            );
            API.userId = user.user.uid;
            API.currentUser = selectedUser;
            window.location.href = "/";
        } catch (err) {
            console.error(error);
            setError(err as AuthError);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div className="h-100 row align-items-center">
                <div className="col p-3 row justify-content-center">

                    <div className="col-lg-5 col-12">
                        <div className="card shadow ">
                            <div className="card-header row m-0">
                                <h4 className="col-auto pl-0">Вход в системата</h4>
                                <div className="col text-right pt-2">
                                    <FontAwesomeIcon icon={faUserLock} size="lg"/>
                                </div>
                            </div>
                            <div className="card-body overflow-auto" style={{maxHeight: "60vh"}}>

                                {
                                    usersLoading && <> Зареждам списъка със служители ... </>
                                }

                                {
                                    usersError && <>
                                        <div className="text-warning">Възникна грешка при зареждане на списъка.</div>
                                        <div
                                            className={"fs-italic small text-white-50 text-center"}> {usersError.message} </div>
                                    </>
                                }

                                {
                                    selectedUser &&
                                    <>
                                        <div className="card-title">
                                            Здравейте,
                                            &nbsp;<FontAwesomeIcon icon={faUser} />
                                            &nbsp;<strong>{selectedUser.name}</strong>!
                                        </div>
                                        <div className="card-subtitle pb-3 pt-2">Моля, въведете Вашата парола...</div>
                                        <form onSubmit={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            doLogin()
                                        }}>
                                            <input className="form-control" type="password"
                                                   onChange={e => setPassword(e.target.value)}/>
                                            {
                                                error &&
                                                <div className={"text-danger small"}>
                                                    Това не е валидна парола. Моля, опитайте пак...
                                                </div>
                                            }
                                        </form>
                                        <Row className={"mt-3"}>
                                            {
                                                loading ?
                                                    <Col className={"text-center"}>
                                                        <Spinner animation={"border"}/>
                                                    </Col>
                                                    :
                                                    <>
                                                        <Col>
                                                            <Button onClick={() => setSelectedUser(null)}
                                                                    variant={"outline-secondary"}>
                                                                <FontAwesomeIcon icon={faAngleLeft} /> Обратно</Button>
                                                        </Col>
                                                        <Col xs={"auto"}>
                                                            <Button onClick={() => doLogin()}
                                                                    variant={"outline-primary"}
                                                                    disabled={password?.length < 6}
                                                            >Вход <FontAwesomeIcon icon={faAngleRight} /></Button>
                                                        </Col>
                                                    </>
                                            }
                                        </Row>
                                    </>
                                }

                                {
                                    !usersLoading && !usersError && !selectedUser &&
                                    <>
                                        {
                                            <form>
                                                <input className="form-control m-0 mb-3"
                                                       placeholder={"Търси в списъка..."}
                                                       onChange={(e) => {
                                                           setFilter(e.target.value)
                                                       }}/>

                                                <div className="list-group">
                                                    {
                                                        users?.docs.filter(
                                                            (u: any) => {
                                                                return filter.length === 0 || u.data.name.toLowerCase().indexOf(filter.toLowerCase()) > -1;
                                                            }
                                                        ).map(
                                                            (u: any, idx: number) =>
                                                                <UserItemComponent
                                                                    key={u.id}
                                                                    user={u.data()}
                                                                    onSelectUser={(user: any) => {
                                                                        setSelectedUser({
                                                                            email: user.email,
                                                                            name: user.name
                                                                        })
                                                                    }}
                                                                />
                                                        )
                                                    }
                                                </div>
                                            </form>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


const UserItemComponent = function (props: any) {
    return (
        <div className="row align-items-center border p-2 pr-0 m-0 mb-2 shadow-sm">
            <div className="col">
                <h5 className="list-group-item-heading">
                    <FontAwesomeIcon icon={faUser} /> {props.user.name}</h5>
            </div>
            <div className="col-auto text-right">
                <a href="#" className="btn btn-outline-primary list-group-item"
                   onClick={() => props.onSelectUser(props.user)}>
                    <FontAwesomeIcon icon={faAngleRight}/></a>
            </div>
        </div>
    )
}
