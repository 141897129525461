import {FC, PropsWithChildren, useEffect, useState} from "react";
import {Button, Col, FormControl, InputGroup, Row, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";

export interface EditableTableCellProps extends PropsWithChildren{
    className?: string;
    inputClassName?: string;
    inEditMode: boolean;
    isWorking: boolean;
    value: string;
    onEdit: () => void;
    onBlur: (newValue: string) => void;
    onDone: () => void;
}

const EditableTableCell: FC<EditableTableCellProps> = (props: EditableTableCellProps) => {
    const [currentValue, setCurrentValue] = useState(props.value);
    const [currentEditMode, setCurrentEditMode] = useState(props.inEditMode);

    useEffect(() => {
        if(currentEditMode && !props.inEditMode && currentValue !== props.value) {
            props.onBlur(currentValue);
        }

        setCurrentEditMode(props.inEditMode);
    }, [props.inEditMode]);

    useEffect(() => {
        setCurrentValue(props.value);
    }, [props.value])

    if(props.isWorking) return <Spinner animation={"border"} />;

    return <td className={props.className}>
        <Row>
            <Col>
                {
                    props.inEditMode ?
                        <InputGroup>
                            <FormControl as={"input"} value={currentValue} size={"sm"} autoFocus
                                            className={props.inputClassName}
                                         onFocus={(e: any) => e.target.select()}
                                         onBlur={() => props.onBlur(currentValue)}
                                         onChange={e => setCurrentValue(e.target.value)}
                                         onKeyDown={(e: any) => {
                                             if (e.key === "Escape") {
                                                 setCurrentValue("");
                                                 props.onDone();
                                             }
                                             if (e.key === "Enter") {
                                                 // setCurrentValue("");
                                                 props.onBlur(currentValue);
                                                 props.onDone();
                                             }
                                         }}
                            />
                            {/*<Button size={"sm"} onClick={() => {*/}
                            {/*    props.onBlur(currentValue);*/}
                            {/*    props.onDone();*/}
                            {/*}}>*/}
                            {/*    <FontAwesomeIcon icon={faCheck} />*/}
                            {/*</Button>*/}
                        </InputGroup>
                        :
                        <div className={"editable-table-cell w-100 h-100 " + props.className} onClick={props.onEdit}>
                            {props.value}&nbsp;
                        </div>
                }
            </Col>
            { props.children && <Col xs={"auto"} className={"ps-0 ms-0"}>{props.children}</Col> }
        </Row>
    </td>
}

export default EditableTableCell;
