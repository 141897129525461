import {Col, Row, Table} from "react-bootstrap";
import RightAlignedCell from "../../../common/RightAlignedCell";
import EditableTableCell from "../../../common/EditableTableCell";
import {textToFloat, zeroToEmptyStringAsCurrencyWithCommas, zeroToEmptyStringAsNumber} from "../../../common";
import React, {useState} from "react";
import {
    CustomerOfferProductionAttributesType,
} from "../../../fb-converters/cutomerOffer-converter";
import I18Label, {getI18Text, getI18Price} from "../../../i18/i18label";

interface Props {
    zIndex?: number;
    discount_price: number;
    eurExchangeRate: number;
    qty: number
    attributes: CustomerOfferProductionAttributesType;
    setAttributes: (attributes: CustomerOfferProductionAttributesType) => void;
}

export default function CustomerOfferDetailsAttributes({
                                                           zIndex,
                                                           discount_price,
                                                           eurExchangeRate,
                                                           qty,
                                                           attributes,
                                                           setAttributes
                                                       }: Props) {
    const [editCell, setEditCell] = useState("");

    return (
        <>
            <Row>
                <Col xs={"12"}>
                    <Col xs={"12"}><strong><I18Label label={"Допълнителни разходи"}/></strong></Col>
                    <Table className={"border"}>
                        <thead>
                        <tr>
                            <RightAlignedCell header className={"w-75px"}>No:</RightAlignedCell>
                            <th><I18Label label={"Вид"}/></th>
                            <RightAlignedCell header><I18Label label={"Ед.цена"}/><br/>(<I18Label
                                label={"лв"}/>)</RightAlignedCell>
                            <RightAlignedCell header><I18Label label={"Отстъпка"}/><br/>(%)</RightAlignedCell>
                            <RightAlignedCell header><I18Label label={"Цена след"}/><br/><I18Label
                                label={"отст"}/>., <I18Label label={"лв"}/></RightAlignedCell>
                            <RightAlignedCell header><I18Label label={"Кол."}/></RightAlignedCell>
                            <RightAlignedCell header><I18Label label={"Цена"}/><br/>(<I18Label
                                label={"лева"}/>)</RightAlignedCell>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            attributes?.map(
                                (a, aIdx) => <tr key={aIdx}>
                                    <RightAlignedCell> {1 + aIdx} </RightAlignedCell>
                                    <td> {getI18Text(a.name)} </td>
                                    <RightAlignedCell>
                                        {zeroToEmptyStringAsCurrencyWithCommas(getI18Price(a.singlePrice, eurExchangeRate).toString())} </RightAlignedCell>

                                    <EditableTableCell inEditMode={editCell === `${aIdx}-attr-discount`}
                                                       className={"w-120px text-end"}
                                                       isWorking={false}
                                                       value={zeroToEmptyStringAsCurrencyWithCommas(a.discount.toString())}
                                                       onEdit={() => setEditCell(`${aIdx}-attr-discount`)}
                                                       onBlur={newValue => {
                                                           const _attributes = [...attributes];
                                                           const price = _attributes[aIdx].singlePrice || 0;
                                                           const discount = textToFloat(newValue) || 0;
                                                           const finPrice = price - (price * discount / 100);

                                                           _attributes[aIdx].discount = discount;
                                                           _attributes[aIdx].finalPrice = finPrice;
                                                           setAttributes(_attributes);
                                                           setEditCell("");
                                                       }}
                                                       onDone={() => setEditCell("")}/>

                                    <RightAlignedCell> {zeroToEmptyStringAsCurrencyWithCommas(getI18Price(a.finalPrice, eurExchangeRate).toString())} </RightAlignedCell>
                                    <RightAlignedCell> {zeroToEmptyStringAsNumber(qty)} </RightAlignedCell>
                                    <RightAlignedCell> {zeroToEmptyStringAsCurrencyWithCommas(getI18Price(a.finalPrice * qty, eurExchangeRate).toString())}</RightAlignedCell>
                                </tr>
                            )
                        }
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </>
    )
}
