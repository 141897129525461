import {DocumentData, DocumentReference, QueryDocumentSnapshot, SnapshotOptions} from "firebase/firestore";

export type OwnCompanyType = {
    id?: string;
    ref?: DocumentReference;
    name: string;
    listIndex: number;
    address: string;
    bulstat: string;
    ddsNumber: string;
    city: string;
    mol: string;
    phone: string;
    email: string;
}

export const ownCopanyConverter = {
    toFirestore(ownCompany: OwnCompanyType): DocumentData {
        return {
            name: ownCompany.name || "",
            listIndex: ownCompany.listIndex || "",
            bulstat: ownCompany.bulstat || "",
            ddsNumber: ownCompany.ddsNumber || "",
            address: ownCompany.address || "",
            city: ownCompany.city || "",
            mol: ownCompany.mol || "",
            phone: ownCompany.phone || "",
            email: ownCompany.email || "",
        };
    },
    fromFirestore(
        snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions
    ): OwnCompanyType {
        const ownCompany = snapshot.data(options)!;
        return {
            id: snapshot.id,
            ref: snapshot.ref,
            listIndex: ownCompany.listIndex,
            name: ownCompany.name,
            bulstat: ownCompany.bulstat,
            ddsNumber: ownCompany.ddsNumber,
            address: ownCompany.address,
            city: ownCompany.city,
            mol: ownCompany.mol,
            phone: ownCompany.phone,
            email: ownCompany.email,
        } as OwnCompanyType;
    }
};
