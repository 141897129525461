import {FC, useMemo, useState} from "react";
import {customerInquiryConverter, CustomerInquiryType} from "../../../fb-converters/cutomerInquiry-converter";
import {Container, Form, Spinner, Table} from "react-bootstrap";
import RightAlignedCell from "../../../common/RightAlignedCell";
import {useCollection} from "react-firebase-hooks/firestore";
import {collection, deleteDoc, orderBy, query, QueryConstraint, where} from "firebase/firestore";
import {fbDb, fStorage} from "../../../App";
import LoadingErrorContentTable from "../../../common/LoadingErrorContentTable";
import {dateToString, showErrorsListInToast} from "../../../common";
import EditIconButton from "../../../common/icon-buttons/EditIconButton";
import TrashIconButton from "../../../common/icon-buttons/TrashIconButton";
import CheckIconButton from "../../../common/icon-buttons/CheckIconButton";
import CancelIconButton from "../../../common/icon-buttons/CancelIconButton";
import FilesIconButton from "../../../common/icon-buttons/FilesIconButton";
import {deleteObject, listAll, ref} from "firebase/storage";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import FolderIconButton from "../../../common/icon-buttons/FolderIconButton";
import {CustomerOffersDialog} from "../customers-offers/CustomerOffersDialog";
import {ClientType} from "../../nomenclatures/clients/hooks/useClients";
import {locationConverter} from "../../../fb-converters/location-converter";
import I18Label from "../../../i18/i18label";

interface Props {
    client?: ClientType;
    forMonth: number;
    forYear: number;
    onEdit: (inquiry: CustomerInquiryType) => void;
    onEditFiles: (inquiry: CustomerInquiryType) => void;
}


const CustomerInquiryList: FC<Props> = (props) => {

    const [editOffers, setEditOffers] = useState<CustomerInquiryType | undefined>();
    const [filterNumber, setFilterNumber] = useState("");
    const [filterDate, setFilterDate] = useState("");
    const [filterCompany, setFilterCompany] = useState("");
    const [filterClient, setFilterClient] = useState("");
    const [filterRegion, setFilterRegion] = useState("");

    const [working, setWorking] = useState(false);
    const [removeInquiry, setRemoveInquiry] = useState<CustomerInquiryType | undefined>(undefined);

    console.log("CustomerInquiryList", props);

    const [locations, loadingLocations, errorLocations] = useCollection(
        props.client?.id ?
            query(
                collection(fbDb, "clientLocations"),
                where("clientId", "==", props.client?.id),
            ).withConverter(locationConverter) : null
    );

    const constraints: QueryConstraint[] = [];
    if (props.client)
        constraints.push(where("client.id", "in", [props.client.id].concat(locations?.docs.map(d => d.id) ?? [])));

    if(!props.client) {
        constraints.push(where("inquiryDate", ">=", new Date(props.forYear, props.forMonth, 1)));
        constraints.push(where("inquiryDate", "<", new Date(props.forYear, props.forMonth + 1, 1)));
    }

    const [inquiriesData, inquiriesLoading, inquiriesError] = useCollection(
        !props.client || (locations && !loadingLocations && !errorLocations) ?
            query(collection(fbDb, "customerInquiries"),
                ...constraints,
                orderBy("inquiryDate", "desc")
            ).withConverter(customerInquiryConverter) : null
    );

    if (inquiriesError) {
        console.error(inquiriesError);
    }

    const inquiries = useMemo(() => {
        if (inquiriesData) {
            return inquiriesData.docs
        } else return [];
    }, [inquiriesData]);

    async function removeInquiryFiles() {
        if (!removeInquiry) return;

        try {
            setWorking(true);
            const docId = removeInquiry.id;
            const listRef = ref(fStorage, `/customer_inquiries/${docId}`);
            const list = await listAll(listRef);
            for (const file of list.items) {
                await deleteObject(file);
            }
        } catch (e: any) {
            showErrorsListInToast("Грешка", [`Възникна грешка при опит да се изтрият прикачените документи. Грешка:\n${e.message}`]);
            console.error(e);
        } finally {
            setWorking(false);
        }
    }

    async function removeSelected() {
        try {
            setWorking(true);
            if (removeInquiry) {
                await deleteDoc(removeInquiry.ref!);
                removeInquiryFiles();
            }
        } catch (e: any) {
            console.error(e.message);
            showErrorsListInToast("Грешка", [`Възникна проблем:\n${e.message}`]);
        } finally {
            setWorking(false);
        }
    }

    const filteredInquiries = useMemo(() => {
        const filterCompanyLower = filterCompany.toLowerCase();
        const filterClientLower = filterClient.toLowerCase();
        const filterRegionLower = filterRegion.toLowerCase();

        return inquiries.filter(
            i => (!filterNumber || (i.data().inquiryNumber.toString().indexOf(filterNumber) > -1)) &&
                (!filterDate || (dateToString(i.data().inquiryDate).indexOf(filterDate) > -1)) &&
                (!filterCompany || ((i.data().ownCompany?.name || "").toLowerCase().indexOf(filterCompanyLower) > -1)) &&
                (!filterClient || ((i.data().location?.name || "").toLowerCase().indexOf(filterClientLower) > -1)) &&
                (!filterRegion || ((i.data().location?.region || "").toLowerCase().indexOf(filterRegionLower) > -1)))
    }, [inquiries, filterRegion, filterCompany, filterClient, filterNumber, filterDate]);

    return (
        <Container fluid>
            <Table size={"sm"} bordered hover className={"w-100 max-h-60vh d-block overflow-auto"}>
                <thead>
                <tr>
                    <RightAlignedCell header className={"w-100px"}>No:</RightAlignedCell>
                    <th><I18Label label={"Дата"} /></th>
                    <th><I18Label label={"Компания"} /></th>
                    <th className={"w-50"}><I18Label label={"Клиент"} /></th>
                    <th><I18Label label={"Регион"} /></th>
                    <th/>
                </tr>
                <tr>
                    <td className={"w-100px"}>
                        <Form.Control size={"sm"} value={filterNumber} className={"text-end"}
                                      onChange={(e) => setFilterNumber(e.target.value)}/>
                    </td>
                    <td className={"w-100px"}>
                        <Form.Control size={"sm"} value={filterDate}
                                      onChange={(e) => setFilterDate(e.target.value)}/>
                    </td>
                    <td>
                        <Form.Control size={"sm"} value={filterCompany}
                                      onChange={(e) => setFilterCompany(e.target.value)}/>
                    </td>
                    <td>
                        <Form.Control size={"sm"} value={filterClient}
                                      onChange={(e) => setFilterClient(e.target.value)}/>
                    </td>
                    <td>
                        <Form.Control size={"sm"} value={filterRegion}
                                      onChange={(e) => setFilterRegion(e.target.value)}/>
                    </td>
                    <td>
                    </td>
                </tr>
                </thead>
                <tbody>
                <LoadingErrorContentTable
                    loading={inquiriesLoading}
                    error={inquiriesError?.message}
                    colCount={6}
                >
                    <>
                        {
                            filteredInquiries.map(
                                i => <tr key={i.id}>
                                    <RightAlignedCell className={"w-100px"}> {i.data().inquiryNumber}</RightAlignedCell>
                                    <td> {dateToString(i.data().inquiryDate)} </td>
                                    <td className={"text-nowrap w-20"}> {i.data().ownCompany?.name} </td>
                                    <td className={"text-nowrap"}> {i.data().location?.name} </td>
                                    <td className={"text-nowrap"}> {i.data().location?.region} </td>
                                    <RightAlignedCell className={"w-120px text-nowrap"}>
                                        {
                                            removeInquiry?.id === i.id && working && <Spinner animation={"border"}/>
                                        }
                                        {
                                            removeInquiry?.id === i.id ?
                                                <>
                                                    {
                                                        !working &&
                                                        <>
                                                            <CheckIconButton onClick={() => removeSelected()}/>&nbsp;
                                                            <CancelIconButton
                                                                onClick={() => setRemoveInquiry(undefined)}/>
                                                        </>
                                                    }
                                                </> :
                                                <>
                                                    <FilesIconButton size={"sm"} title={"Оферти"}
                                                                     onClick={() => setEditOffers(i.data())}/>&nbsp;
                                                    <FolderIconButton size={"sm"} title={"Прикачени документи"}
                                                                      onClick={() => props.onEditFiles && props.onEditFiles(i.data())}/>&nbsp;
                                                    <EditIconButton size={"sm"}
                                                                    onClick={() => props.onEdit && props.onEdit(i.data())}/>&nbsp;
                                                    <TrashIconButton size={"sm"}
                                                                     onClick={() => setRemoveInquiry(i.data())}/>
                                                </>
                                        }
                                    </RightAlignedCell>
                                </tr>
                            )
                        }
                    </>
                </LoadingErrorContentTable>
                </tbody>
            </Table>

            {
                editOffers && <CustomerOffersDialog zIndex={1100}
                                                    show={true}
                                                    customerInquiry={editOffers}
                                                    onClose={() => setEditOffers(undefined)}/>
            }
        </Container>
    );
}

export default CustomerInquiryList;
