import {
    Modal,
    ModalTitle,
} from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import React, {useEffect, useState} from "react";
import {DIALOG_OPTIONS} from "../../../types";
import {DocumentData} from "firebase/firestore";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPersonDigging} from "@fortawesome/free-solid-svg-icons";
import ModalBackground from "../../../common/ModalBackground";
import { ServicesList } from "./ServicesList";
import { ServiceDetails } from "./ServiceDetails";
import { ServiceDelete } from "./ServiceDelete";
import DialogCloseButton from "../../../common/DialogCloseButton";
import I18Label from "../../../i18/i18label";

export function ServicesDialog({zIndex, show, onClose, onSelect}: DIALOG_OPTIONS) {

    const [viewMode, setViewMode] = useState<"VIEW_LIST" | "VIEW_ADD" | "VIEW_EDIT" | "VIEW_DELETE">('VIEW_LIST');
    const [editDocument, setEditDocument] = useState<DocumentData | null>(null);
    const [deleteDocument, setDeleteDocument] = useState<DocumentData | null>(null);
    const [addDocument, setAddDocument] = useState(false);

    useEffect(() => {
        setViewMode(editDocument ? "VIEW_EDIT" : "VIEW_LIST");
    }, [editDocument])

    useEffect(() => {
        setViewMode(deleteDocument ? "VIEW_DELETE" : "VIEW_LIST");
    }, [deleteDocument])

    useEffect(() => {
        setViewMode(addDocument ? "VIEW_ADD" : "VIEW_LIST");
    }, [addDocument])

    if(!show) return null;

    return (
        <ModalBackground zIndex={zIndex}>
            <Modal show={show} size={"lg"} style={{zIndex: (zIndex + 1)}} >
                <ModalHeader>
                    <ModalTitle>
                        <FontAwesomeIcon icon={faPersonDigging}/> <I18Label label={"Услуги"} />
                    </ModalTitle>
                    <DialogCloseButton onClose={() => onClose(null)} />
                </ModalHeader>
                {
                    <ServicesList onClose={onClose} isVisible={viewMode === "VIEW_LIST"}
                                  onSelect={onSelect}
                                  onAdd={() => setAddDocument(true)}
                                  onEdit={(doc: DocumentData) => {
                                     setEditDocument(doc);
                                 }}
                                  onDelete={(doc: DocumentData) => {
                                     setDeleteDocument(doc);
                                 }}
                    />
                }
                {
                    viewMode === "VIEW_EDIT" && editDocument &&
                    <ServiceDetails doc={editDocument}
                                    zIndex={(zIndex || 1100) + 10}
                                    onDone={() => {
                                      setEditDocument(null)
                                  }}/>
                }
                {
                    viewMode === "VIEW_DELETE" && deleteDocument &&
                    <ServiceDelete doc={deleteDocument} onDone={() => {
                        setDeleteDocument(null)
                    }}/>
                }
                {
                    viewMode === "VIEW_ADD" && addDocument &&
                    <ServiceDetails doc={null}
                                    zIndex={(zIndex || 1100) + 10}
                                    onDone={() => {
                                      setAddDocument(false)
                                  }}/>
                }

            </Modal>
        </ModalBackground>
    )
}
