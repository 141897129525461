import {Text, View} from "@react-pdf/renderer";
import {styles} from "./OfferPdfStyles";
import React from "react";

const OfferPdfStaticPage2 = () => (
    <View style={{ fontSize: 8 }} wrap={false}>

        <View style={[styles.card, {marginBottom: 0}]}>
            <View style={{ flexDirection: "row" }}>
                <Text style={{ width: "85%"}}>Подробно описание на услугата „УДЪЛЖЕНА ГАРАНЦИЯ“</Text>
                <Text style={{ width: "15%", textAlign: "center"}}>Цена</Text>
            </View>
        </View>

        <View>
            <View style={{ flexDirection: "row" }}>
                <View style={{ width: "85%"}}>
                    <View style={{ flexDirection: "column" }} >
                        <View>
                            <Text style={{ fontWeight: "bold" }}>
                                Условия на удължената гаранция:
                            </Text>
                        </View>
                        <View style={{ padding: 10 }}>
                            <Text>
                                - Удължената гаранция се отнася само за тягови батерии.
                            </Text>
                            <Text></Text>
                            <Text>

                            </Text><Text>
                            - Удължената гаранция се предлага на новозакупени продукти или закупени до шест месеца от дата на сключване на договор за удължаване на гаранцията.
                        </Text><Text>
                            - Удължената гаранция е за продукти закупени заедно със зарядно устройство от Индустриални Батерии ЕООД.
                        </Text><Text>
                            - При експлоатация на продуктите от лек до тежък едносменен дневен режим на работа.
                        </Text><Text>
                            - Стриктно спазване на предписанията за експлоатиране на продуктите.
                        </Text><Text>
                            - Задължително извършване на ежегодно обслужване на продуктите от сервиз на Индустриални Батерии ЕООД.
                        </Text><Text>
                            - При нужда от сервизиране на тягова батерия в сервиз на Индустриални Батерии ЕООД, важат условия и цени по стандартна ценова листа за сервизни услуги.
                        </Text>
                        </View>
                    </View>
                </View>
                <Text style={{ width: "15%", textAlign: "center"}}>
                    Всички описани услуги са включени в цената за ежегодно, еднократно посещение
                </Text>
            </View>
        </View>



        <View style={[styles.card, {marginBottom: 0}]}>
            <View style={{ flexDirection: "row" }}>
                <Text style={{ width: "85%"}}>Подробно описание на услугата „АБОНАМЕНТНА ПОДДРЪЖКА“</Text>
                <Text style={{ width: "15%", textAlign: "center"}}>Цена</Text>
            </View>
        </View>

        <View>
            <View style={{ flexDirection: "row" }}>
                <View style={{ width: "85%"}}>
                    <View style={{ flexDirection: "column" }} >
                        <View>
                            <Text style={{ fontWeight: "bold" }}>
                                Описание на включените услуги в абонаментно обслужване:
                            </Text>
                        </View>
                        <View style={{ padding: 10 }}>
                            <Text>
                                - Първоначално след подписване на договор за абонаментно обслужване:
                            </Text>
                            <View style={{ paddingLeft: 10}}>
                                <Text>
                                    - Първоначален анализ на текущата експлоатация и поддръжка на тяговите батерии.
                                </Text>
                                <Text>
                                    - Консултация и предписание относно правилна експлоатация на продуктите, нужда от превантивни и коригиращи действия.
                                </Text>
                                <Text>
                                    - Обучение на обслужващия персонал относно правилна експлоатация на продуктите.
                                </Text>
                            </View>
                            <Text></Text>
                            <Text></Text>
                            <Text>- При всяко посещение:</Text>
                            <View style={{ paddingLeft: 10}}>
                                <Text>
                                    &bull; Акумулаторни батерии (по-долу в текста АБ):
                                </Text>
                                <View style={{ paddingLeft: 10}}>
                                    <Text>
                                        - Пускане в експлоатация на нова АБ.
                                    </Text>
                                    <Text>
                                        - Проверка на техническото състояние на АБ.
                                    </Text>
                                    <Text>
                                        - Ремонт АБ.
                                    </Text>
                                </View>

                                <Text>
                                    &bull;  Зарядни устройства (по-долу в текста ЗУ).
                                </Text>
                                <View style={{ paddingLeft: 10}}>
                                    <Text>
                                        - Пускане в експлоатация (настройка на конфигурацията, проверка на работоспособността).
                                    </Text>
                                    <Text>
                                        - Почистване, включително и на скрити кухини.
                                    </Text>
                                    <Text>
                                        - Ремонт на ЗУ.
                                    </Text>
                                    <Text>
                                        - Снемане на данните в паметта, изготвяне на отчет/справка.
                                    </Text>
                                </View>
                            </View>
                            <Text>- Транспорт на сервизния екип до адрес на клиента.</Text>
                            <Text>- При нужда от сервизиране на тягова батерия в сервиз на Индустриални Батерии ЕООД, важат условия и цени по стандартна ценова листа за сервизни услуги, Приложение 5 към Договор за техническо абонаментно обслужване.</Text>
                        </View>
                    </View>
                </View>
                <View style={{ width: "15%", textAlign: "center"}}>
                    <Text>Цена за еднократно посещение:</Text>
                    <Text style={{ marginTop: 15 }}>
                        __ V батерия:</Text>
                    <Text style={{ marginTop: 5 }}>
                        __.__ в./бр.
                    </Text>
                </View>
            </View>
        </View>
    </View>

);

export default OfferPdfStaticPage2;
