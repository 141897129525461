import {
    DocumentData,
    DocumentReference,
    FirestoreError,
    QueryConstraint,
    QueryDocumentSnapshot,
    where
} from "firebase/firestore";
import {useEffect, useState} from "react";
import {BusinessSizeType} from "./useBusinessSizesOnce";
import useFirebaseCollection from "../../../../hooks/useFirebaseCollection";
import {SalesRepresentativeType} from "./useSalesRepresentativesOnce";

export interface ClientType {
    id: string;
    ref: DocumentReference | null;
    address?: string;
    bulstat?: string;
    country?: string;
    discount_charge: number;
    discount_eco: number;
    discount_price: number;
    discount_transport: number;
    email?: string;
    mol?: string;
    name: string;
    phone?: string;
    region?: string;
    sector?: string;
    sizeGroup?: BusinessSizeType;
    salesRepresentative?: SalesRepresentativeType;
    sku?: string;
    website?: string;
}

export type ClientsType = ClientType[];


export function convertClientData(d: DocumentData): ClientType | null {
    if (d) {
        return {
            id: d.id,
            ref: d.ref,
            name: d.get('name'),
            sku: d.get('sku'),
            address: d.get('address'),
            bulstat: d.get('bulstat'),
            country: d.get('country'),
            discount_charge: d.get('discount_charge'),
            discount_eco: d.get('discount_eco'),
            discount_price: d.get('discount_price'),
            discount_transport: d.get('discount_transport'),
            email: d.get('email'),
            mol: d.get('mol'),
            phone: d.get('phone'),
            region: d.get('region'),
            sector: d.get('sector'),
            sizeGroup: {name: d.get('sizeGroup')} as BusinessSizeType,
            website: d.get('website'),
            salesRepresentative: d.get('salesRepresentative'),
        } as ClientType;
    } else return null;
}

export function convertClientsData(_objects: QueryDocumentSnapshot[]): ClientsType {
    if (_objects) {
        const clients: ClientsType = [];
        _objects.forEach(d => {
            const _d = convertClientData(d);
            if (_d) clients.push(_d);
        });
        return clients;
    } else return [];
}


export default function useClients(constraints?: QueryConstraint[]): [ClientsType, boolean, FirestoreError | undefined] {
    const [documents, loading, error] = useFirebaseCollection("clients", constraints);
    const [convertedData, setConvertedData] = useState<ClientsType>([]);

    useEffect(() => {
        if (documents) {
            setConvertedData(convertClientsData(documents.docs));
        }
    }, [documents]);

    return [convertedData, loading, error];
}
