import {Button, Col, ModalBody, ModalFooter, Row, Spinner, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCancel, faCircleArrowDown, faEdit, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import {useCollection} from "react-firebase-hooks/firestore";
import {collection} from "firebase/firestore";
import {fbDb} from "../../../App";
import {useEffect, useState} from "react";
import LoadDataFromServerError from "../../../common/LoadDataFromServerError";
import {CarConverter, CarType} from "../../../fb-converters/car-converter";
import {zeroToEmptyString} from "../../../common";
import RightAlignedCell from "../../../common/RightAlignedCell";
import I18Label from "../../../i18/i18label";

export interface CarsListProps {
    isVisible: boolean;
    onAdd: () => any;
    onEdit: (item: CarType) => any;
    onDelete: (item: CarType) => any;
    onClose: (result: any) => any;
    onSelect?: (result: any) => any;
}

export function CarsList({isVisible, onAdd, onEdit, onDelete, onClose, onSelect}: CarsListProps) {
    const [carsData, loadingCars, errorCars] = useCollection(
        collection(fbDb, "cars").withConverter(CarConverter)
    );
    const [cars, setCars] = useState<CarType[]>([]);

    useEffect(() => {
        setCars(carsData?.docs.map(item => item.data() as CarType) || []);
    }, [carsData]);

    const isLoading = loadingCars;

    return (
        <>
            {isLoading && <Spinner animation={"border"}/>}

            {
                !isLoading && errorCars && <LoadDataFromServerError/>
            }

            {
                !isLoading && !errorCars && cars && isVisible && cars.length === 0 &&
                <Row className={"m-5"}>
                    <Col className={"text-center fw-bold"}>Няма въведени превозни средства</Col>
                </Row>
            }


            {
                !isLoading && !errorCars && cars && isVisible && cars.length > 0 &&
                <>
                    <ModalBody>
                        <div className={"max-h-60vh scrollable"}>
                            <Table size={"sm"} borderless hover>
                                <thead style={{backgroundColor: "white"}}>
                                <tr>
                                    <th style={{width: onSelect ? 120 : 90}}></th>
                                    <th>Марка, модел</th>
                                    <th>Рег.номер</th>
                                    <RightAlignedCell header>Макс. товароносимост</RightAlignedCell>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    cars.length > 0 && cars.map(
                                        d =>
                                            <tr key={d.id} className={"tr-bordered"}>
                                                <td>
                                                    <Button size={"sm"} variant={"outline-secondary"}
                                                            onClick={() => onEdit(d)}
                                                    >
                                                        <FontAwesomeIcon icon={faEdit}/>
                                                    </Button>&nbsp;
                                                    <Button size={"sm"} variant={"outline-danger"}
                                                            onClick={() => {
                                                                onDelete(d)
                                                            }}
                                                    >
                                                        <FontAwesomeIcon icon={faTrash}/>
                                                    </Button>&nbsp;
                                                    {
                                                        onSelect &&
                                                        <Button size={"sm"} variant={"outline-success"}
                                                                onClick={() => {
                                                                    onSelect(d)
                                                                }}
                                                        >
                                                            <FontAwesomeIcon icon={faCircleArrowDown}/>
                                                        </Button>
                                                    }
                                                </td>
                                                <td>
                                                    {d.name}
                                                </td>
                                                <td>
                                                    {d.regPlate}
                                                </td>
                                                <RightAlignedCell>
                                                    {zeroToEmptyString(d.maxWeight)}
                                                </RightAlignedCell>
                                            </tr>
                                    )
                                }
                                </tbody>

                            </Table>
                        </div>
                    </ModalBody>
                </>
            }

            {
                isVisible &&
                <ModalFooter>
                    <Button onClick={() => onAdd()}>
                        <FontAwesomeIcon icon={faPlus}/> <I18Label label={"Добави ново"} />
                    </Button>
                    <Button variant={"outline-secondary"} onClick={() => {
                        onClose(null)
                    }}>
                        <FontAwesomeIcon icon={faCancel}/> <I18Label label={"Затвори"} />
                    </Button>
                </ModalFooter>
            }
        </>
    )
}
