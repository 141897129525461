import {Text, View} from "@react-pdf/renderer";
import React from "react";
import { TableRow } from "../product-table/OfferProductPdfTableHeader";
import {StyleSheet} from "@react-pdf/renderer";
import {zeroToEmptyStringAsCurrencyWithCommas} from "../../../../../../../common";

const styles = StyleSheet.create({
    col1H: { width: 1150, backgroundColor: "white", textAlign: "right", padding: 2, paddingRight: 22 },
    col7H: { width: 180, backgroundColor: "white", textAlign: "right", padding: 2, paddingRight: 18 },
});

interface OfferProductPdfTableTotalProps {
    total: number;
}

const OfferProductPdfTableTotal = ({total}: OfferProductPdfTableTotalProps) => {
    return (
        <View style={{ fontWeight: "bold" }}>
            <TableRow cols={[
                <View style={styles.col1H}>
                    <Text>Общо с допълнителни разходи:</Text>
                </View>,
                <View style={styles.col7H}>
                    <Text>{zeroToEmptyStringAsCurrencyWithCommas(total)}</Text>
                </View>
            ]}/>
        </View>
    );
}

export default OfferProductPdfTableTotal;
