import {Button, Form, InputGroup} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList, faXmark} from "@fortawesome/free-solid-svg-icons";
import React, {ReactElement, useState} from "react";

export interface SelectValueFromDialogProps {
    label?: string | React.ReactNode;
    value: string | undefined | null;
    onChange?: (newValue: any) => any;
    onLocationChange?: (newValue: any) => any;
    dialog: ReactElement;
    size?: "sm" | "lg";
    className?: string;
}

export default function SelectValueFromDialog({
                                                  label,
                                                  value,
                                                  onChange,
                                                  onLocationChange,
                                                  dialog,
                                                  size,
                                                  className
                                              }: SelectValueFromDialogProps) {
    const [showDialog, setShowDialog] = useState(false);

    const dialogWrapper = React.cloneElement(dialog, {
        onClose: () => setShowDialog(false),
        onSelect: onChange ? (value: any) => {
            onChange(value);
            setShowDialog(false)
        } : undefined,
        onSelectLocation: onLocationChange ? (value: any) => {
            onLocationChange(value);
            setShowDialog(false)
        } : undefined,
    });

    return (
        <>
            <Form.Group className={className || " mb-3"} onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}>
                {label && <Form.Label>{label}</Form.Label>}
                <InputGroup>
                    <Form.Control type={"text"} disabled={true} value={value || ''} size={size}/>
                    <Button variant="outline-secondary" size={size}
                            onClick={() => {
                                onChange && onChange(undefined);
                                onLocationChange && onLocationChange(undefined);
                            }}>
                        <FontAwesomeIcon icon={faXmark}/></Button>
                    <Button variant="outline-secondary" onClick={() => setShowDialog(true)} size={size}>
                        <FontAwesomeIcon icon={faList}/></Button>
                </InputGroup>
            </Form.Group>

            {showDialog && dialogWrapper}
        </>

    )
}
