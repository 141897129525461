import {Button, Form, ModalBody, ModalFooter, Spinner, Tab, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCancel, faCircleArrowDown, faEdit, faPlus, faSearch, faTrash} from "@fortawesome/free-solid-svg-icons";
import {useCollection} from "react-firebase-hooks/firestore";
import {collection, getFirestore, DocumentData, QueryDocumentSnapshot, DocumentReference, SnapshotOptions} from "firebase/firestore";
import {fbDb, firebaseApp} from "../../../App";
import {useEffect, useState} from "react";
import LoadDataFromServerError from "../../../common/LoadDataFromServerError";
import {zeroToEmptyStringAsNumber} from "../../../common";

export interface ProductGroupsListProps {
    isVisible: boolean;
    onAdd: () => any;
    onEdit: (item: ProductGroup) => any;
    onDelete: (item: ProductGroup) => any;
    onClose: (result: any) => any;
    onSelect?: (result: any) => any;
}

export function convertProductGroupVariantToLabel(variant: number | undefined | null): string {
    switch (variant) {
        case 0: return "Вариант 1";
        case 1: return "Вариант 2";
    }
    return "Вариант 3";
}

export interface ProductGroup {
    id: string;
    ref: DocumentReference<DocumentData>;
    name: string;
    variant: number;
    percent: number;
}

export function ProductGroupsConvert(_groups: any): Array<ProductGroup> {
    let _g: Array<ProductGroup> = [];

    if(_groups) {
        _g = _groups.docs.map(
            (g: any) => {
                return {
                    id: g.id,
                    ref: g.ref,
                    name: g.data().name || "",
                    variant: g.data().variant ?? 2,
                    percent: g.data().percent || 0,
                } as ProductGroup
            }
        )
    }

    return _g;
}

export function ProductGroupsList({isVisible, onAdd, onEdit, onDelete, onClose, onSelect}: ProductGroupsListProps) {

    const [filterName, setFilterName] = useState('');

    const [_groups, loadingGroups, errorGroups] = useCollection(
        collection(fbDb, "productGroups")
    );
    const [groups, setGroups] = useState<Array<ProductGroup>>([]);
    const [filtered, setFiltered] = useState<any[]>([])

    useEffect(
        () => {
            setGroups(ProductGroupsConvert(_groups));
        }, [_groups]
    );

    useEffect(() => {
        if (groups) {
            setFiltered(groups.filter(
                r => (!filterName || filterName.length === 0 || r.name.toLowerCase().indexOf(filterName.toLowerCase()) > -1)
            ));
        } else setFiltered([]);
    }, [groups, filterName]);

    function isLoading() {
        return loadingGroups;
    }


    return (
        <>
            {isLoading() && <Spinner animation={"border"}/>}

            {
                !isLoading() && errorGroups && <LoadDataFromServerError />
            }

            {
                !loadingGroups && !errorGroups && groups && isVisible &&
                <>
                    <ModalBody>
                        <div className={"max-h-60vh scrollable"}>
                            <Table size={"sm"} borderless hover>
                                <thead style={{backgroundColor: "white"}}>
                            <tr>
                                <th style={{width: onSelect ? 120: 90}}></th>
                                <th>Име</th>
                                <th>Вариант</th>
                                <th className={"text-end"}>Процент</th>
                            </tr>
                            <tr>
                                <td className={"text-start"}>
                                    <FontAwesomeIcon icon={faSearch}/>
                                </td>
                                <td>
                                    <Form.Control size={"sm"} value={filterName}
                                                  onChange={(e) => setFilterName(e.target.value)}/>
                                </td>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                loadingGroups &&
                                <tr>
                                    <td colSpan={6} className={"text-center fw-bold"}>
                                        <Spinner animation={"border"}/>
                                    </td>
                                </tr>
                            }
                            {
                                filtered && filtered.length === 0 &&
                                <tr>
                                    <td colSpan={6} className={"text-center fw-bold"}>Няма дефинирани групи</td>
                                </tr>
                            }
                            {
                                filtered && filtered.length > 0 && filtered.map(
                                    r =>
                                        <tr key={r.id} className={"tr-bordered"}>
                                            <td>
                                                <Button size={"sm"} variant={"outline-secondary"}
                                                        onClick={() => onEdit(r)}
                                                >
                                                    <FontAwesomeIcon icon={faEdit}/>
                                                </Button>&nbsp;
                                                <Button size={"sm"} variant={"outline-danger"}
                                                        onClick={() => {
                                                            onDelete(r)
                                                        }}
                                                >
                                                    <FontAwesomeIcon icon={faTrash}/>
                                                </Button>&nbsp;
                                                {
                                                    onSelect &&
                                                    <Button size={"sm"} variant={"outline-success"}
                                                            onClick={() => {onSelect(r)}}
                                                    >
                                                        <FontAwesomeIcon icon={faCircleArrowDown}/>
                                                    </Button>
                                                }
                                            </td>
                                            <td>
                                                {r.name}
                                            </td>
                                            <td>
                                                {convertProductGroupVariantToLabel(r.variant)}
                                            </td>
                                            <td className={"text-end"}>
                                                {zeroToEmptyStringAsNumber(r.percent, "%")}
                                            </td>
                                        </tr>
                                )
                            }
                            </tbody>

                        </Table>
                        </div>
                    </ModalBody>

                    <ModalFooter>
                        <Button onClick={() => onAdd()}>
                            <FontAwesomeIcon icon={faPlus}/> Добави нова
                        </Button>
                        <Button variant={"outline-secondary"} onClick={() => {
                            onClose(null)
                        }}>
                            <FontAwesomeIcon icon={faCancel}/> Затвори
                        </Button>
                    </ModalFooter>
                </>
            }
        </>
    )
}
