import {DIALOG_OPTIONS} from "../../../types";
import ModalHeader from "react-bootstrap/ModalHeader";
import {Modal, ModalTitle} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFile} from "@fortawesome/free-solid-svg-icons";
import DialogCloseButton from "../../../common/DialogCloseButton";
import React from "react";
import ModalBackground from "../../../common/ModalBackground";
import {ProductionPlanDetails} from "./ProductionPlanDetails";
import {
    CustomerInvoiceItemType,
    CustomerInvoiceType
} from "../../../fb-converters/cutomerInvoice-converter";
import {CustomerInquiryType} from "../../../fb-converters/cutomerInquiry-converter";
import I18Label from "../../../i18/i18label";

interface Props extends DIALOG_OPTIONS {
    inquiry: CustomerInquiryType;
    invoice: CustomerInvoiceType;
    product: CustomerInvoiceItemType;
}

const ProductionPlanDetailsPopup = ({zIndex, show, onClose, invoice, inquiry, product}: Props) => {

    return (
        <ModalBackground zIndex={zIndex}>
            <Modal show={show} size={"xl"} style={{zIndex: (zIndex + 1)}}>
                <ModalHeader>
                    <ModalTitle>
                        <FontAwesomeIcon icon={faFile}/> <I18Label label={"Карта за възлагане към сервиза"} />
                    </ModalTitle>
                    <DialogCloseButton onClose={() => onClose(null)}/>
                </ModalHeader>
                {
                    <ProductionPlanDetails invoice={invoice}
                                           inquiry={inquiry}
                                           product={product}
                                           onDone={() => onClose(null)}/>
                }
            </Modal>
        </ModalBackground>
    )
        ;
}

export default ProductionPlanDetailsPopup;
