import {collection, DocumentData, getFirestore, orderBy, query, where} from "firebase/firestore";
import {Button, Col, Modal, Row, Spinner, Tab, Table, Tabs} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faCircleArrowDown, faEdit, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import {ClientLocationDialog} from "./ClientLocationDialog";
import {useCollection} from "react-firebase-hooks/firestore";
import {firebaseApp} from "../../../../App";
import LoadDataFromServerError from "../../../../common/LoadDataFromServerError";
import {ClientDelete} from "../ClientDelete";
import {ClientLocationDelete} from "./ClientLocationDelete";
import {ClientType} from "../hooks/useClients";
import useClientCrmActivities, {convertCrmActivitiesData} from "../hooks/useClientCrmActivities";
import LoadingErrorContent from "../../../../common/LoadingErrorContent";
import {dateToString} from "../../../../common";
import {ClientLocationType} from "../../../../fb-converters/location-converter";
import {ActivitiesType} from "../../../../types";
import I18Label from "../../../../i18/i18label";

export interface ClientDetailsCrmActivitiesProps {
    zIndex?: number;
    doc: ClientType;
    onDone: () => {}
    onSelect?: (result: any) => any;
}

export function ClientDetailsCrmActivities({
                                               doc,
                                               onDone,
                                           }: ClientDetailsCrmActivitiesProps) {
    const [selectedLocationId, setSelectedLocationId] = useState<string | null>(null);
    const [selectedLocation, setSelectedLocation] = useState<ClientLocationType | null>(null);

    const [clientLocations, loadingClientLocations, errorClientLocations] = useCollection(
        query(collection(getFirestore(firebaseApp), "clientLocations"),
            where("clientId", "==", doc?.id || "not set")
        )
    );

    const [activitiesData, loadingActivities, errorActivities] = useCollection(
        query(collection(getFirestore(firebaseApp), "activities"),
            where("clientId", "==", selectedLocationId || "not set"),
            orderBy("date", "desc")
        )
    );
    const [activities, setActivities] = useState<ActivitiesType>([]);

    useEffect(() => {
        if (activitiesData) {
            setActivities(convertCrmActivitiesData(activitiesData.docs));
        }
    }, [activitiesData]);

    useEffect(
        () => {
            if (clientLocations && clientLocations.docs.length > 0) {
                setSelectedLocationId(clientLocations.docs[0].id);
            }
        },
        [clientLocations]
    );

    useEffect(() => {
        if (selectedLocationId) {
            setSelectedLocation(clientLocations?.docs.find(l => l.id === selectedLocationId)?.data() || null);
        } else {
            setSelectedLocation(null);
        }
    }, [selectedLocationId]);

    return <LoadingErrorContent loading={loadingActivities} error={errorActivities?.message}>
        <>
            <Modal.Body>
                <Tabs
                    variant={"pills"}
                    onSelect={(k) => setSelectedLocationId(k)}
                    activeKey={selectedLocationId ?? undefined}
                    className="mb-3"
                >
                    {
                        clientLocations && clientLocations.docs.map(
                            l =>
                                <Tab key={l.id} eventKey={l.id}
                                     title={`${l.data().name} - ${l.data().region}`}
                                     className={"text-light"}
                                />
                        )
                    }
                </Tabs>
                <Table striped size={"sm"} bordered hover>
                    <thead>
                    <tr>
                        <th></th>
                        <th><I18Label label={"Дата"} /></th>
                        <th><I18Label label={"Кампания"} /></th>
                        <th><I18Label label={"Вид"} /></th>
                        <th><I18Label label={"Статус"} /></th>
                        <th><I18Label label={"Регион"} /></th>
                        <th><I18Label label={"Представител"} /></th>
                        <th><I18Label label={"Детайли"} /></th>
                    </tr>
                    </thead>
                    <tbody>


                    {
                        activities.length === 0 && <tr>
                            <td colSpan={8} className={"text-center fw-bold"}><I18Label label={"Няма въведени активности"} /></td>
                        </tr>
                    }

                    {
                        activities.length > 0 &&
                        activities.map(
                            l => <tr key={l.id}>
                                <td>
                                    {/*<Button size={"sm"} variant={"outline-secondary"}*/}
                                    {/*        onClick={() => showLocationDialog(l)}*/}
                                    {/*>*/}
                                    {/*    <FontAwesomeIcon icon={faEdit}/>*/}
                                    {/*</Button>&nbsp;*/}
                                </td>
                                <td> {dateToString(l.date)} </td>
                                <td className={"text-nowrap"}> {l.campaignName} </td>
                                <td className={"text-nowrap"}> {l.activityType} </td>
                                <td className={"text-nowrap"}> {l.activityStatus} </td>
                                <td className={"text-nowrap"}> {l.region} </td>
                                <td className={"text-nowrap"}> {l.userName} </td>
                                <td> {l.details} </td>
                            </tr>
                        )
                    }
                    </tbody>
                </Table>
            </Modal.Body>

            <Modal.Footer>
                <Row className={"w-100"}>
                    <Col xs={6}>
                        <Button variant={"outline-secondary"} onClick={() => {
                            onDone()
                        }}>
                            <FontAwesomeIcon icon={faArrowLeft}/> <I18Label label={"Към списъка"} />
                        </Button>
                    </Col>
                    <Col xs={6} className={"text-end"}>
                    </Col>
                </Row>
            </Modal.Footer>
        </>

    </LoadingErrorContent>
}
