import {Text, View} from "@react-pdf/renderer";
import {styles} from "./OfferPdfStyles";
import {API} from "../../../../../../api";
import React, {FC} from "react";
import {HeaderLine} from "./OfferPdfDocument";

interface OfferPdfDeliveryConditionsProps {
    comment: string;
    paymentMethod: string;
    validityPeriod: string;
}

const OfferPdfDeliveryConditions: FC<OfferPdfDeliveryConditionsProps> =
    ({paymentMethod, validityPeriod, comment}) => (
        <View wrap={false} style={{marginTop: 10, marginBottom: 10}}>
            <HeaderLine text1={`УСЛОВИЯ ЗА ДОСТАВКА:`}/>
            <View wrap={false} style={{flexDirection: "row"}}>
                <Text style={styles.deliveryItem}>
                    &bull; НАЧИН НА ПЛАЩАНЕ: </Text>
                <Text style={{fontWeight: "bold"}}> {paymentMethod}</Text>
            </View>
            <View wrap={false} style={{flexDirection: "row"}}>
                <Text style={styles.deliveryItem}>
                    &bull; ВАЛИДНОСТ НА ОФЕРТАТА: </Text>
                <Text style={{fontWeight: "bold"}}> {validityPeriod}</Text>
            </View>
            <Text style={styles.deliveryItem}>
                &bull; ВСИЧКИ ПОСОЧЕНИ ЦЕНИ СА В ЛЕВА БЕЗ ВКЛЮЧЕН ДДС
            </Text>
            <Text style={styles.deliveryItem}>
                &bull; СРОКЪТ НА ДОСТАВКА Е В РАБОТНИ ДНИ
            </Text>
            <Text style={styles.deliveryItem}>
                &bull; ЗАБЕЛЕЖКИ:
            </Text>
            <View style={{paddingTop: 5, paddingLeft: 10}}>
                <Text style={styles.deliveryItem}>
                    {comment}
                </Text>
            </View>
            <View style={{padding: 10}}>

                <Text style={styles.deliveryItem}>
                    МОЛЯ, НЕ СЕ КОЛЕБАЙТЕ ДА ЗАДАДЕТЕ ВСИЧКИ ВЪЗНИКНАЛИ ВЪПРОСИ.
                </Text>
                <Text style={styles.deliveryItem}>
                    ОСТАВАМЕ В ОЧАКВАНЕ НА ВАШЕТО РЕШЕНИЕ.
                </Text>
                <Text style={styles.deliveryItem}> </Text>
                <Text style={styles.deliveryItem}>
                    С УВАЖЕНИЕ,
                </Text>
                <Text style={styles.deliveryItem}> </Text>
                <Text style={styles.deliveryItem}>
                    {API.user?.positionName.toUpperCase()}: {API.user?.name?.toUpperCase()} ( {API.user?.phone} )
                </Text>
            </View>
        </View>
    );

export default OfferPdfDeliveryConditions;
