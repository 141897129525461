import {DocumentData, DocumentReference, FirestoreError, QueryDocumentSnapshot} from "firebase/firestore";
import {useEffect, useState} from "react";
import useFirebaseCollectionOnce from "../../../hooks/useFirebaseCollectionOnce";


export interface WarehouseType {
    id: string;
    name: string;
}
export type WarehousesType = WarehouseType[];


export interface WarehouseObjectType {
    id: string;
    ref: DocumentReference;
    name: string;
}
export type WarehouseObjectsType = WarehouseObjectType[];

export function convertWharehouseObjectData(_objects: QueryDocumentSnapshot<DocumentData>[]): WarehouseObjectsType {
    if(_objects) {
        return _objects.map(
            d => {
                return {
                    id: d.id,
                    ref: d.ref,
                    name: d.get('name') ?? ""
                } as WarehouseObjectType;
            }
        );
    } else return [];
}



export default function useWarehouseObjects(): [WarehouseObjectsType, boolean, FirestoreError | undefined] {
    const [documents, loading, error] = useFirebaseCollectionOnce("warehouseObjects");
    const [warehouseObjects, setWarehouseObjects] = useState<WarehouseObjectsType>([]);

    useEffect(() => {
        if(documents) {
            setWarehouseObjects(convertWharehouseObjectData(documents));
        }
    }, [documents]);

    return [warehouseObjects, loading, error];
}
