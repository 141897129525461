import {Modal,
    ModalTitle,
} from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import React, {useEffect, useState} from "react";
import {DIALOG_OPTIONS} from "../../../types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ModalBackground from "../../../common/ModalBackground";
import DialogCloseButton from "../../../common/DialogCloseButton";
import {CarsList} from "./CarsList";
import {faTruck} from "@fortawesome/free-solid-svg-icons";
import {CarsDetails} from "./CarsDetails";
import {CarsDelete} from "./CarsDelete";
import {CarType} from "../../../fb-converters/car-converter";

export function CarsDialog({zIndex, show, onClose, onSelect}: DIALOG_OPTIONS) {

    const [viewMode, setViewMode] = useState<"VIEW_LIST" | "VIEW_ADD" | "VIEW_EDIT" | "VIEW_DELETE">('VIEW_LIST');
    const [editDocument, setEditDocument] = useState<CarType | null>(null);
    const [deleteDocument, setDeleteDocument] = useState<CarType | null>(null);
    const [addDocument, setAddDocument] = useState(false);

    useEffect(() => {
        setViewMode(editDocument ? "VIEW_EDIT" : "VIEW_LIST");
    }, [editDocument])

    useEffect(() => {
        setViewMode(deleteDocument ? "VIEW_DELETE" : "VIEW_LIST");
    }, [deleteDocument])

    useEffect(() => {
        setViewMode(addDocument ? "VIEW_ADD" : "VIEW_LIST");
    }, [addDocument])

    return (
        <ModalBackground zIndex={zIndex}>
            <Modal show={show} style={{zIndex: (zIndex + 1)}}>
                <ModalHeader>
                    <ModalTitle>
                        <FontAwesomeIcon icon={faTruck}/> Превозни средства
                    </ModalTitle>
                    <DialogCloseButton onClose={() => onClose(null)} />
                </ModalHeader>
                {
                    <CarsList onClose={onClose} isVisible={viewMode === "VIEW_LIST"}
                              onSelect={onSelect}
                              onAdd={() => setAddDocument(true)}
                              onEdit={doc => {
                                     setEditDocument(doc);
                                 }}
                              onDelete={doc => {
                                     setDeleteDocument(doc);
                                 }}
                    />
                }
                {
                    viewMode === "VIEW_EDIT" && editDocument &&
                    <CarsDetails car={editDocument}
                                 zIndex={(zIndex || 1100) + 10}
                                 onDone={() => {
                                      setEditDocument(null)
                                  }}/>
                }
                {
                    viewMode === "VIEW_DELETE" && deleteDocument &&
                    <CarsDelete car={deleteDocument} onDone={() => {
                        setDeleteDocument(null)
                    }}/>
                }
                {
                    viewMode === "VIEW_ADD" && addDocument &&
                    <CarsDetails car={null}
                                 zIndex={(zIndex || 1100) + 10}
                                 onDone={() => {
                                      setAddDocument(false)
                                  }}/>
                }

            </Modal>
        </ModalBackground>
    )
}
