export default function CenterAlignedCell(props: any) {
    const className = (props.className || "") + " text-center";

    return props.header ?
        <th {...props} className={className}>
            { props.children }
        </th>
        :
        <td {...props} className={className}>
            { props.children }
        </td>
}
