import {useState} from "react";

import {deleteDoc} from "firebase/firestore";
import {Button, Col, Modal, Row, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faSave} from "@fortawesome/free-solid-svg-icons";
import {WarehouseObjectType} from "../../warehouse/hooks/useWarehouseObjects";


export interface WarehouseObjectDeleteProps {
    doc: WarehouseObjectType;
    onDone: () => any;
}

export function WarehouseObjectDelete({doc, onDone}: WarehouseObjectDeleteProps) {
    const [deleting, setDeleting] = useState(false);

    const deleteDimens = async () => {
        setDeleting(true);
        await deleteDoc(doc.ref);
        setDeleting(false);
        onDone();
    }

    return (
        <>
            <Modal.Body>
                <h5 className={"mb-3"}>
                    Изтриване на складов обект
                </h5>

                <div className={"text-danger"}>
                    Моля, потвърдете изтриването на складов обект <strong className={"text-primary"}>{doc.name}</strong>!
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Row className={"w-100"}>
                    {
                        deleting && <Col xs={12} className={"text-center"}>
                            <Spinner animation={"border"}/>
                        </Col>
                    }

                    {
                        !deleting &&
                        <>
                            <Col xs={6}>
                                <Button variant={"outline-secondary"} onClick={() => {
                                    onDone()
                                }}>
                                    <FontAwesomeIcon icon={faArrowLeft}/> Към списъка
                                </Button>
                            </Col>
                            <Col xs={6} className={"text-end"}>
                                <Button onClick={() => deleteDimens()} variant={"danger"}>
                                    <FontAwesomeIcon icon={faSave}/> Изтрий
                                </Button>
                            </Col>
                        </>
                    }
                </Row>
            </Modal.Footer>
        </>
    )
}
