import {
    FirestoreError,
    DocumentData,
    QueryDocumentSnapshot, DocumentReference, QueryConstraint
} from "firebase/firestore";
import {useEffect, useState} from "react";
import useFirebaseCollection from "../../../../hooks/useFirebaseCollection";

export interface ProviderType {
    id: string;
    ref: DocumentReference<DocumentData>;
    name: string;
    bulstat?: string;
    mol?: string;
    address?: string;
    phone?: string;
    email?: string;
}

export type ProvidersType = ProviderType[];

export default function useProviders(constraints?: QueryConstraint[]): [ProvidersType, boolean, FirestoreError | undefined] {

    const [documents, loading, error] = useFirebaseCollection("providers", constraints);
    const [convertedData, setConvertedData] = useState<ProvidersType>([]);


    function convertData(_objects: QueryDocumentSnapshot<DocumentData>[]): ProvidersType {
        if(_objects) {
            let _newObjectsCollection: ProvidersType = _objects.map(
                d => {
                    return {
                        id: d.id,
                        ref: d.ref,
                        name: d.get('name') ?? "",
                        bulstat: d.get('bulstat') ?? "",
                        mol: d.get('mol') ?? "",
                        email: d.get('email'),
                        phone: d.get('phone'),
                        address: d.get('address'),
                    } as ProviderType;
                }
            );
            return _newObjectsCollection;
        } else return [];
    }

    useEffect(() => {
        if(documents) {
            setConvertedData(convertData(documents.docs));
        }
    }, [documents]);

    return [convertedData, loading, error];
}
