import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFile,} from "@fortawesome/free-solid-svg-icons";
import {Button} from "react-bootstrap";

export default function FilesIconButton(
    props:
        {
            title?: string,
            variant?: string,
            size?: "sm" | "lg",
            onClick: VoidFunction,
            disabled?: boolean
        }) {
    return <Button variant={props.variant || "primary"} size={props.size || "sm"} {...props} >
        <FontAwesomeIcon icon={faFile}/>
    </Button>;
}
