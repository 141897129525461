import {Col, Row} from "react-bootstrap";

export interface LoadDataFromServerErrorProps {
    msg?: string
}

export default function LoadDataFromServerError({ msg }: LoadDataFromServerErrorProps) {

    return (
        <Row className={"text-danger font-waight-bold p-3 m-3"}>
            <Col className={"text-center"}>
                {
                    msg || "Възникна грешка при опит да се заредят данните от сървъра."
                }
            </Col>
        </Row>
        )
}