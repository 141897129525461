export default function RightAlignedCell(props: any) {
    const className = (props.className || "") + " text-end";

    return props.header ?
        <th {...props} className={(props.className || "") + " text-end"}>
            { props.children }
        </th>
        :
        <td {...props} className={(props.className || "") + " text-end"}>
            { props.children }
        </td>
}
