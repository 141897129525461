import React from "react";
import OfferInvestmentPdfTableHeader from "./OfferInvestmentPdfTableHeader";
import OfferInvestmentPdfTableRow from "./OfferInvestmentPdfTableRow";
import {CustomerOfferType} from "../../../../../../../fb-converters/cutomerOffer-converter";
import {ProductSummaryType} from "../../../../CustomerOfferDetailsSummary";

interface OfferInvestmentPdfTableProps {
    offer: CustomerOfferType,
    summary?: ProductSummaryType[]
}

const OfferInvestmentPdfTable = ({offer, summary}: OfferInvestmentPdfTableProps) => {
    return (<>
        <OfferInvestmentPdfTableHeader/>
        <OfferInvestmentPdfTableRow offer={offer} summary={summary}/>
    </>);
};

export default OfferInvestmentPdfTable;
