import {Button, Col, Row, Tab, Tabs} from "react-bootstrap";
import ExpeditionPlanTable from "./ExpeditionPlanTable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import LoadingErrorContent from "../../../common/LoadingErrorContent";
import {useState} from "react";
import ExpeditionPlanFilter, {DEFAULT_EXPEDITION_FILTER, ExpeditionPlanFilterType} from "./ExpeditionPlanFilter";
import {EMPTY_EXPEDITION, ExpeditionType} from "../../../fb-converters/expedition-converter";
import ExpeditionDetails from "./ExpeditionDetails";
import {addDoc, collection, doc, FirestoreError, getDoc, updateDoc} from "firebase/firestore";
import {fbDb} from "../../../App";
import {showErrorsListInToast} from "../../../common";
import {productionCardConverter, ProductionCardExpeditionType} from "../types/ProductionCardType";
import useExpedition from "./useExpedition";

const ExpeditionPlan = () => {
    const [filter, setFilter] = useState<ExpeditionPlanFilterType>({...DEFAULT_EXPEDITION_FILTER});
    const [addExpedition, setAddExpedition] = useState<ExpeditionType | undefined>();
    const [viewMode, setViewMode] = useState<"active" | "completed">("active");

    function addNew() {
        setAddExpedition({...EMPTY_EXPEDITION, expeditionDate: new Date()});
    }

    const { saveExpedition, saving, errors } = useExpedition();

    function doSaveExpedition(expedition: ExpeditionType) {
        saveExpedition(expedition).then(() => {
            if(errors.length === 0) {
                setAddExpedition(undefined);
            }
        });
    }

    return (
        <>
            <LoadingErrorContent loading={false} error={undefined}>
                <div className={"mt-1 p-3 border shadow rounded bg-light"}>
                    <Row>
                        <Col>
                            <h3>Дневен план за експедиция</h3>
                        </Col>
                        <Col xs={"auto"} className={"text-end"}>
                            <Button onClick={addNew}>
                                <FontAwesomeIcon icon={faPlus}/> Добави
                            </Button>
                        </Col>
                    </Row>
                    <Row className={"bg-secondary-light p-2 m-0 mt-2 mb-2 rounded"}>
                        <Col>
                            <ExpeditionPlanFilter
                                filter={
                                    filter
                                }
                                onChange={
                                    (newFilter) => setFilter(newFilter)
                                }
                            />
                        </Col>
                    </Row>
                    <Tabs activeKey={viewMode} onSelect={(k) => setViewMode(k as "active" | "completed")}>
                        <Tab eventKey={"active"} title={"Планирани"}></Tab>
                        <Tab eventKey={"completed"} title={"Завършени"}></Tab>
                    </Tabs>
                    <div className={"max-h-65vh scrollable bg-white"}>
                        <ExpeditionPlanTable filter={filter} viewMode={viewMode} zIndex={1050}/>
                    </div>
                </div>
            </LoadingErrorContent>

            {addExpedition &&
                <ExpeditionDetails show={true}
                                   saving={saving}
                                   zIndex={1050}
                                   expeditionToEdit={addExpedition}
                                   onDone={doSaveExpedition}
                                   onCancel={() => setAddExpedition(undefined)}
                />
            }

        </>
    )
}

export default ExpeditionPlan;
