import {
    Modal,
    ModalTitle,
} from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import React, {useEffect, useState} from "react";
import {DIALOG_OPTIONS} from "../../../types";
import {UsersList} from "./UsersList";
import {DocumentData} from "firebase/firestore";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUsers} from "@fortawesome/free-solid-svg-icons";
import {UserDelete} from "./UserDelete";
import {UserDetails} from "./UserDetails";
import ModalBackground from "../../../common/ModalBackground";
import DialogCloseButton from "../../../common/DialogCloseButton";

export function UsersDialog({zIndex, show, onClose, onSelect}: DIALOG_OPTIONS) {

    const [viewMode, setViewMode] = useState<"VIEW_LIST" | "VIEW_ADD" | "VIEW_EDIT" | "VIEW_DELETE">('VIEW_LIST');
    const [editDocument, setEditDocument] = useState<DocumentData | null>(null);
    const [deleteDocument, setDeleteDocument] = useState<DocumentData | null>(null);
    const [addDocument, setAddDocument] = useState(false);


    useEffect(() => {
        setViewMode(editDocument ? "VIEW_EDIT" : "VIEW_LIST");
    }, [editDocument])

    useEffect(() => {
        setViewMode(deleteDocument ? "VIEW_DELETE" : "VIEW_LIST");
    }, [deleteDocument])

    useEffect(() => {
        setViewMode(addDocument ? "VIEW_ADD" : "VIEW_LIST");
    }, [addDocument])

    return (
        <ModalBackground zIndex={zIndex}>
            <Modal show={show} size={"xl"} style={{zIndex: (zIndex + 1)}}>
                <ModalHeader>
                    <ModalTitle>
                        <FontAwesomeIcon icon={faUsers}/> Потребители
                    </ModalTitle>
                    <DialogCloseButton onClose={() => onClose(null)} />
                </ModalHeader>
                {
                    <UsersList onClose={onClose} isVisible={viewMode === "VIEW_LIST"}
                                onSelect={onSelect}
                                onAdd={() => setAddDocument(true)}
                                onEdit={(doc: DocumentData) => {
                                     setEditDocument(doc);
                                 }}
                                onDelete={(doc: DocumentData) => {
                                     setDeleteDocument(doc);
                                 }}
                    />
                }
                {
                    viewMode === "VIEW_EDIT" && editDocument &&
                    <UserDetails document={editDocument}
                                  zIndex={(zIndex || 1100) + 10}
                                  onDone={() => {
                                      setEditDocument(null)
                                  }}/>
                }
                {
                    viewMode === "VIEW_DELETE" && deleteDocument &&
                    <UserDelete doc={deleteDocument} onDone={() => {
                        setDeleteDocument(null)
                    }}/>
                }
                {
                    viewMode === "VIEW_ADD" && addDocument &&
                    <UserDetails document={null}
                                  zIndex={(zIndex || 1100) + 10}
                                  onDone={() => {
                                      setAddDocument(false)
                                  }}/>
                }

            </Modal>
        </ModalBackground>
    )
}