import {collection, documentId, getDocs, orderBy, query, where} from "firebase/firestore";
import {fbDb} from "../App";


export async function loadClientsLocationsById(clientIds: Array<string>) {
    let finalList: any[] = [];
    const clientsCnt = clientIds.length;
    let slice = 0;
    while(slice <= clientsCnt ) {
        const ids = clientIds.slice(slice, slice + 10);
        if(ids.length > 0) {
            const q = query(collection(fbDb, "clientLocations"),
                where(documentId(), 'in', ids));
            const docs =  await getDocs(q);
            finalList = finalList.concat(docs.docs);
        }
        slice += 10;
    }

    return finalList;
}

export async function loadClient(id: string) {
    const q = query(collection(fbDb, "clients"),
        where(documentId(), '==', id));
    const res = await getDocs(q);
    return res.empty ? undefined : res.docs[0];
}
export async function loadClientLocation(id: string) {
    const q = query(collection(fbDb, "clientLocations"),
        where(documentId(), '==', id));
    const res = await getDocs(q);
    return res.empty ? undefined : res.docs[0];
}

export async function loadAllClientsLocations() {
    const q = query(collection(fbDb, "clientLocations"));
    return await getDocs(q);
}
export async function loadAllClients() {
    const q = query(collection(fbDb, "clients"));
    return await getDocs(q);
}

export async function loadAllCountries() {
    const q = query(collection(fbDb, "countries"), orderBy("name", "asc"));
    return await getDocs(q);
}

export async function loadAllRegions() {
    const q = query(collection(fbDb, "regions"),
        orderBy("name", "asc")
    );
    return await getDocs(q);
}
