import {updateDoc, addDoc, collection, doc} from "firebase/firestore";
import {Button, Col, Form, Modal, Row, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faSave} from "@fortawesome/free-solid-svg-icons";
import {fbDb} from "../../../App";
import {removeEmptyFields, showErrorsListInToast} from "../../../common";
import {useState} from "react";
import {BankAccountType} from "../../../fb-converters/bank-accounts-converter";


export interface BankAccountDetailsProps {
    zIndex?: number;
    bankAcc: BankAccountType | null;
    onDone: () => any;
}

export function BankAccountDetails({
                                       zIndex,
                                       bankAcc,
                                       onDone
                                   }: BankAccountDetailsProps) {
    const [saving, setSaving] = useState(false);

    const save = async (data: any) => {
        const objectsRef = collection(fbDb, "bankAccounts");
        setSaving(true);
        let _data = removeEmptyFields(data);
        if (bankAcc?.id) {
            await updateDoc(doc(objectsRef, bankAcc?.id), _data);
        } else {
            await addDoc(objectsRef, _data);
        }
        setSaving(false);
        onDone();
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        const data: any = {
            bank: event.target.bank.value,
            iban: event.target.iban.value,
            bic: event.target.bic.value,
        };

        const errors = [];
        if (!data.bank || data.bank.trim().length === 0) {
            errors.push("Моля, въведете име на банката");
        }
        if (!data.iban || data.iban.trim().length === 0) {
            errors.push("Моля, въведете IBAN");
        }
        if (!data.bic || data.bic.trim().length === 0) {
            errors.push("Моля, въведете BIC");
        }
        if (errors.length === 0) {
            save(data);
        } else {
            showErrorsListInToast("Внимание", errors);
        }
    }

    return (
        <><Form className={"p-2"} onSubmit={handleSubmit}>
            <Modal.Body>
                <h5 className={"mb-3"}>
                    {bankAcc ? "Корекция" : "Добавяне"} на банкова сметка
                </h5>

                <Form.Group>
                    <Form.Label>IBAN</Form.Label>
                    <Form.Control type="text" name="iban" defaultValue={bankAcc?.iban}/>
                </Form.Group>

                <Form.Group>
                    <Form.Label>BIC</Form.Label>
                    <Form.Control type="text" name="bic" defaultValue={bankAcc?.bic}/>
                </Form.Group>

                <Form.Group>
                    <Form.Label>Банка</Form.Label>
                    <Form.Control type="text" name="bank" defaultValue={bankAcc?.bank}/>
                </Form.Group>

            </Modal.Body>
            <Modal.Footer>
                <Row className={"w-100"}>
                    {
                        saving && <Col xs={12} className={"text-center"}>
                            <Spinner animation={"border"}/>
                        </Col>
                    }

                    {
                        !saving &&
                        <>
                            <Col xs={6}>
                                <Button variant={"outline-secondary"} onClick={() => {
                                    onDone()
                                }}>
                                    <FontAwesomeIcon icon={faArrowLeft}/> Към списъка
                                </Button>
                            </Col>
                            <Col xs={6} className={"text-end"}>
                                <Button type={"submit"}>
                                    <FontAwesomeIcon icon={faSave}/> Запиши
                                </Button>
                            </Col>
                        </>
                    }
                </Row>
            </Modal.Footer>
        </Form>
        </>
    )
}
