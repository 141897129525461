import {styles} from "../OfferPdfStyles";
import {Text, View} from "@react-pdf/renderer";
import React from "react";
import {zeroToEmptyStringAsCurrencyWithCommas} from "../../../../../../../common";


export const TableRow = (props: any) => (<View style={styles.tableRowWrapper}>
    {
        props.cols.map((item: any) => {
            return item
        })
    }
</View>);

const OfferProductPdfTableHeader = ({savedPrice, isStarred}: { savedPrice: number, isStarred: boolean }) => {
    return (
        <View>
            {
                isStarred &&
                <TableRow cols={[
                    <View style={styles.col1}></View>,
                    <View style={styles.col2}></View>,
                    <View style={styles.col3}></View>,
                    <View style={styles.col4}></View>,
                    <View style={styles.col5}></View>,
                    <View style={styles.col6}></View>,
                    <View style={styles.col7}></View>,
                    <View style={styles.col8}></View>,
                    <View style={[styles.col9_10_11, styles.col1Yellow]}>
                        <Text>Най-добра цена</Text>
                    </View>,
                ]}/>
            }
            {
                savedPrice > 0 &&
                <TableRow cols={[
                    <View style={styles.col1}></View>,
                    <View style={styles.col2}></View>,
                    <View style={styles.col3}></View>,
                    <View style={styles.col4}></View>,
                    <View style={styles.col5}></View>,
                    <View style={styles.col6}></View>,
                    <View style={styles.col7}></View>,
                    <View style={styles.col8}></View>,
                    <View style={[styles.col9_10, styles.col11Green]}>
                        <Text>Общо спестени:</Text></View>,
                    <View style={[styles.col11Green, {fontWeight: "bold"}]}>
                        <Text>
                            {zeroToEmptyStringAsCurrencyWithCommas(savedPrice, " лв.")}
                        </Text>
                    </View>
                ]}/>
            }
            <TableRow cols={[
                <View style={styles.col1H}>
                    <Text>№</Text>
                </View>,
                <View style={styles.col2H}>
                    <Text>Тип на стоката (вкл. опции)</Text>
                </View>,
                <View style={styles.col3H}>
                    <Text>Размери</Text>
                    <Text>L/B/H1/H2</Text>
                </View>,
                <View style={styles.col4H}>
                    <Text>Срок на доставка</Text>
                </View>,
                <View style={styles.col5H}>
                    <Text>Гаранция</Text>
                    <Text>(месеци)</Text>
                </View>,
                <View style={styles.col6H}>
                    <Text>Живот</Text>
                    <Text>(цикли)</Text>
                </View>,
                <View style={styles.col7H}>
                    <Text>Ед.цена</Text>
                </View>,
                <View style={styles.col8H}>
                    <Text>Цена скрап</Text>
                </View>,
                <View style={styles.col9H}>
                    <Text>Крайна цена</Text>
                </View>,
                <View style={styles.col10H}>
                    <Text>Кол.</Text>
                    <Text></Text>
                </View>,
                <View style={styles.col11H}>
                    <Text>Цена</Text>
                </View>
            ]}/>
        </View>
    );
}

export default OfferProductPdfTableHeader;
