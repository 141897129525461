import {DocumentData, DocumentReference, QueryDocumentSnapshot, SnapshotOptions} from "firebase/firestore";

export type ServiceType = {
    id?: string;
    ref?: DocumentReference;
    name: string;
    name_ro: string;
    description: string;
    description_ro: string;
    descriptionForClient: string;
    descriptionForClient_ro: string;
    price: number;
    descriptionForPrice: string;
    descriptionForPrice_ro: string;
    isWarrantyService: boolean;
}

export const serviceConverter = {
    toFirestore(service: ServiceType): DocumentData {
        return {
            name: service.name,
            name_ro: service.name_ro,
            description: service.description,
            description_ro: service.description_ro,
            descriptionForClient: service.descriptionForClient,
            descriptionForClient_ro: service.descriptionForClient_ro,
            descriptionForPrice: service.descriptionForPrice,
            descriptionForPrice_ro: service.descriptionForPrice_ro,
            price: service.price,
            isWarrantyService: service.isWarrantyService,
        };
    },
    fromFirestore(
        snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions
    ): ServiceType {
        const service = snapshot.data(options)!;
        return {
            id: snapshot.id,
            ref: snapshot.ref,
            name: service.name ?? "",
            name_ro: service.name_ro ?? "",
            description: service.description ?? "",
            description_ro: service.description_ro ?? "",
            descriptionForClient: service.descriptionForClient ?? "",
            descriptionForClient_ro: service.descriptionForClient_ro ?? "",
            descriptionForPrice: service.descriptionForPrice ?? "",
            descriptionForPrice_ro: service.descriptionForPrice_ro ?? "",
            price: service.price,
            isWarrantyService: service.isWarrantyService || false,
        } as ServiceType;
    }
};
