import {Col, FormControl, Modal, ModalBody, ModalTitle, Row, Tab, Tabs} from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBatteryCar} from "@fortawesome/free-solid-svg-icons";
import DialogCloseButton from "../../../../common/DialogCloseButton";
import ModalBackground from "../../../../common/ModalBackground";
import React, {useMemo, useState} from "react";
import useWarehouseItemsForObject from "../../hooks/useWarehouseItemsForObject";
import LoadingErrorContent from "../../../../common/LoadingErrorContent";
import SmallBorderedHooveredTable from "../../../../common/SmallBorderedHooveredTable";
import RightAlignedCell from "../../../../common/RightAlignedCell";
import {zeroToEmptyString, zeroToEmptyStringAsNumber} from "../../../../common";
import useWarehouseInItems, {WarehouseItemType} from "../../hooks/useWarehouseInItems";
import CaretDownIconButton from "../../../../common/icon-buttons/CaretDownIconButton";
import {where} from "firebase/firestore";
import SelectIconButton from "../../../../common/icon-buttons/SelectIconButton";
import {WarehouseOutItemType} from "../../hooks/useWarehouseOutItems";

type  WarehouseOutSelectItemDialogTabTypes = "PRODUCTS" | "VIRTUAL_PRODUCTS";

export interface WarehouseOutSelectItemDialogProps {
    zIndex?: number;
    show: boolean;
    warehouseId: string;
    onSelect: (item: WarehouseOutItemType | null) => void;
}


function WarehouseOutSelectItemTransaction(props: {
    warehouseId: string | undefined,
    productId: string | undefined
}) {
    const [items, loadItems, errorItems] = useWarehouseInItems(undefined,
        [
            where("warehouseId", "==", props.warehouseId),
            where("productId", "==", props.productId),
            where("availableQty", ">", 0)
        ])

    return <LoadingErrorContent loading={loadItems} error={errorItems?.message}>
        <>
            {
                items.map(
                    value => <tr key={value.id} className={"bg-secondary-light"}>
                        <td>
                        </td>
                        <td> {value.sku} </td>
                        <td> {value.name} </td>
                        <td> {value.dimens} </td>
                        <td> {zeroToEmptyString(value.availableQty)} </td>
                        <td> {zeroToEmptyStringAsNumber(value.singlePrice)} </td>
                    </tr>
                )
            }
            {
                items.length === 0 && <tr>
                    <td colSpan={7} className={"text-center"}><strong>Няма количество</strong></td>
                </tr>
            }
            <tr>
                <td colSpan={6} style={{lineHeight: "7px"}}>&nbsp;</td>
            </tr>
        </>
    </LoadingErrorContent>;
}

export function WarehouseOutSelectItemDialog(props: WarehouseOutSelectItemDialogProps) {
    const zIndex = props.zIndex || 1050;
    const show = props.show;

    const [warehouseItems, loadWarehouseItems, errorWarehouseItems] = useWarehouseItemsForObject(props.warehouseId);

    const [selectedTab, setSelectedTab] = useState<WarehouseOutSelectItemDialogTabTypes>("PRODUCTS");
    const [expandedItem, setExpandedItem] = useState<WarehouseItemType | null>(null);

    const [skuFilter, setSkuFilter] = useState<string | undefined>();
    const [nameFilter, setNameFilter] = useState<string | undefined>();

    function onClose() {
        props.onSelect(null);
    }

    function onSelect(outItem: WarehouseOutItemType) {
        props.onSelect(outItem);
    }

    function onExpand(value: WarehouseItemType) {
        if (expandedItem === value) setExpandedItem(null)
        else setExpandedItem(value);
    }

    function convertItemToOutItem(value: WarehouseItemType): WarehouseOutItemType {
        return {
            warehouseId: value.warehouseId,
            warehouseItemId: value.id,
            productId: value.productId,
            isOwnProduction: value.isOwnProduction || false,
            itemId: value.id,
            name: value.name,
            sku: value.sku,
            dimens: value.dimens,
            singlePrice: value.singlePrice,
            transactionId: value.transactionId,
            transactionDate: value.transactionDate,
            price: value.deliveryPrice,
            itemType: "PRODUCT",
            availableQty: value.availableQty ?? undefined,
            qty: 1,
        } as WarehouseOutItemType;
    }


    const tableProductsBody = useMemo(
        () => {
            const filteredItems = warehouseItems?.filter(
                value => {
                    if (skuFilter && !value.sku?.toUpperCase().includes(skuFilter.toUpperCase())) return false;
                    if (nameFilter && !value.name?.toUpperCase().includes(nameFilter.toUpperCase())) return false;

                    return true;
                }
            );

            if (!filteredItems) return null;

            return <tbody>
            {
                filteredItems.map(
                    value => <>
                        <tr className={expandedItem === value ? "bg-secondary-light fw-bold" : ""}>
                            <td>
                                <CaretDownIconButton onClick={() => onExpand(value)}/>&nbsp;
                                <SelectIconButton onClick={() => {
                                    onSelect(convertItemToOutItem(value))
                                }}/>
                            </td>
                            <td> {value.sku} </td>
                            <td> {value.name} </td>
                            <td> {value.dimens} </td>
                            <RightAlignedCell> {zeroToEmptyString(value.availableQty)} </RightAlignedCell>
                            <RightAlignedCell> {zeroToEmptyStringAsNumber(value.singlePrice)} </RightAlignedCell>
                        </tr>
                        {
                            expandedItem === value &&
                            <WarehouseOutSelectItemTransaction productId={value.productId}
                                                               warehouseId={props.warehouseId}
                            />
                        }
                    </>
                )
            }
            </tbody>
        }, [warehouseItems, expandedItem, skuFilter, nameFilter]);

    return <ModalBackground zIndex={zIndex}>
        <Modal show={show} size={"lg"} style={{zIndex: (zIndex + 1)}}>
            <ModalHeader>
                <ModalTitle>
                    <FontAwesomeIcon icon={faBatteryCar}/> Материали и продукти в склада
                </ModalTitle>
                <DialogCloseButton onClose={onClose}/>
            </ModalHeader>
            <ModalBody>
                <Tabs activeKey={selectedTab}
                      onSelect={eventKey => setSelectedTab((eventKey || "PRODUCTS") as WarehouseOutSelectItemDialogTabTypes)}
                >
                    <Tab title={"Стоки"} eventKey={"PRODUCTS"}/>
                    <Tab title={"Виртуални продукти"} eventKey={"VIRTAL_PRODUCTS"}/>
                </Tabs>

                <LoadingErrorContent loading={loadWarehouseItems}
                                     error={errorWarehouseItems?.message}>
                    <Row>
                        <Col>
                            <SmallBorderedHooveredTable>
                                <thead>
                                <tr>
                                    <th></th>
                                    <th>SKU</th>
                                    <th>Наименование</th>
                                    <th>М.Е.</th>
                                    <RightAlignedCell header className={"w-100px"}>Налично кол.</RightAlignedCell>
                                    <RightAlignedCell header className={"w-100px"}>Ед.цена</RightAlignedCell>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <FormControl
                                            size={"sm"}
                                            placeholder={"Филтър SKU"}
                                            value={skuFilter}
                                            onChange={e => setSkuFilter(e.target.value)}
                                        />
                                    </td>
                                    <td>
                                        <FormControl
                                            size={"sm"}
                                            placeholder={"Филтър наименование"}
                                            value={nameFilter}
                                            onChange={e => setNameFilter(e.target.value)}
                                        />
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                </thead>

                                {tableProductsBody}
                            </SmallBorderedHooveredTable>
                        </Col>
                    </Row>
                </LoadingErrorContent>

            </ModalBody>
        </Modal>
    </ModalBackground>
}
