import {CrmReportActivitiesFilterType} from "./CrmReportActivitiesFilter";
import {FC, useEffect, useState} from "react";
import {Col, Pagination, Row, Spinner, Table} from "react-bootstrap";
import {dateToString, dateWithZeroTime, firebaseDateToDate, showErrorsListInToast} from "../../../../common";
import {
    collection, DocumentData,
    getCountFromServer,
    getDocs,
    limit,
    orderBy,
    query,
    QueryConstraint, QuerySnapshot,
    startAt, where
} from "firebase/firestore";
import {fbDb} from "../../../../App";
import I18Label from "../../../../i18/i18label";

interface Props {
    filter: CrmReportActivitiesFilterType
}

const CrmReportActivitiesResults: FC<Props> = (props) => {
    const PAGE_SIZE = 20;
    const [working, setWorking] = useState(false);
    const [pageIndex, setPageIndex] = useState(0);
    const [activities, setActivities] = useState<any[]>([]);
    const [pages, setPages] = useState<any[]>([]);
    const [totalCount, setTotalCount] = useState(0);

    function getConstraints(): QueryConstraint[] {
        const constr: QueryConstraint[] = [];

        if(props.filter.fromDate) {
            constr.push(where("date", ">=", dateWithZeroTime(props.filter.fromDate)));
        }

        if(props.filter.toDate) {
            const toDate = dateWithZeroTime(props.filter.toDate)!;
            toDate?.setDate(toDate.getDate() + 1);
            constr.push(where("date", "<", toDate));
        }

        if(props.filter.status) {
            constr.push(where("activityStatus", "==", props.filter.status))
        }


        if(props.filter.campaign) {
            console.log(props.filter.campaign)
            constr.push(where("campaignId", "==", props.filter.campaign.id))
        }


        if(props.filter.client) {
            constr.push(where("clientName", "==", props.filter.client.name))
        }


        if(props.filter.user) {
            constr.push(where("userName", "==", props.filter.user.name))
        }

        return constr;
    }

    async function loadData() {
        try {
            setWorking(true);
            const _wheres: QueryConstraint[] = getConstraints();
            _wheres.push(orderBy("date", "asc"));
            const collectionRef = query(collection(fbDb, "activities"), ..._wheres);

            const docs = await getDocs(collectionRef);
            setActivities(docs.docs.map(d => d.data()));
            setTotalCount(docs.size);
        } catch (e: any) {
            showErrorsListInToast("Грешка", [`Възникна грешка: ${e.message}`]);
            console.error(e);
        } finally {
            setWorking(false);
        }
    }

    useEffect(() => {
        loadData();
    }, [props.filter]);

    useEffect(
        () => {
            const _pages: React.ReactElement[] = [];
            const pageCnt = Math.floor(totalCount / PAGE_SIZE);
            for (let i = 0; i < pageCnt; i++) {
                _pages.push(
                    <Pagination.Item key={i} active={i === pageIndex} onClick={() => setPageIndex(i)}>
                        {(i + 1)}
                    </Pagination.Item>
                );
            }

            setPages(_pages);
        }, [activities, pageIndex, totalCount]
    )

    return (
        <>
            <Row>
                <Col>
                    <Table striped>
                        <thead>
                        <tr>
                            <th><I18Label label={"Дата"} /></th>
                            <th><I18Label label={"Кампания"} /></th>
                            <th><I18Label label={"Клиент"} /></th>
                            <th><I18Label label={"Регион"} /></th>
                            <th><I18Label label={"Тип"} /></th>
                            <th><I18Label label={"Статус"} /></th>
                            <th><I18Label label={"Търговски представител"} /></th>
                            <th><I18Label label={"Детайли"} /></th>
                        </tr>
                        </thead>
                        <tbody>
                        {working ? <tr>
                                <td colSpan={8} className={"text-center"}><Spinner animation={"border"}/></td>
                            </tr> :
                            activities
                                .slice(pageIndex * PAGE_SIZE, pageIndex * PAGE_SIZE + PAGE_SIZE -1)
                                .map(
                                activity => <tr key={activity.id}>
                                    <td> {dateToString(firebaseDateToDate(activity.date))} </td>
                                    <td className={"text-nowrap"}> {activity.campaignName} </td>
                                    <td className={"text-nowrap"}> {activity.clientName} </td>
                                    <td className={"text-nowrap"}> {activity.region} </td>
                                    <td className={"text-nowrap"}> {activity.activityType} </td>
                                    <td className={"text-nowrap"}> {activity.activityStatus} </td>
                                    <td className={"text-nowrap"}> {activity.userName} </td>
                                    <td><small>{activity.details}</small></td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Col>
            </Row><Row>
            <Col className={"m-0 mt-2"}>
                <Pagination className="justify-content-center">
                    {pages}
                </Pagination>
            </Col>
        </Row>
        </>
    )
}

export default CrmReportActivitiesResults;
