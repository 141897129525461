import {
    collection,
    getFirestore,
    FirestoreError,
    query,
    DocumentData,
    getDocs,
    QueryConstraint, QueryDocumentSnapshot
} from "firebase/firestore";
import {useEffect, useState} from "react";
import {fbDb, firebaseApp} from "../App";

export default function useFirebaseCollectionOnce(path: string, constraints?: QueryConstraint[]): [QueryDocumentSnapshot<DocumentData>[], boolean, FirestoreError | undefined] {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<FirestoreError | undefined>(undefined);
    const [documents, setDocuments] = useState<QueryDocumentSnapshot<DocumentData>[]>([]);

    async function loadData() {
        let ref = constraints ? query(collection(fbDb, path), ...constraints) :
            query(collection(fbDb, path));

        try {
            let data = await getDocs(ref);
            setDocuments(data.docs);
            setError(undefined);
        } catch (e) {
            setDocuments([]);
            setError(e as FirestoreError);
        } finally {
            setLoading(false);
        }
    }


    useEffect(() => { loadData() }, []);

    return [documents, loading, error];
}
