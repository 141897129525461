import {useState} from "react";

import {updateDoc, addDoc, collection, getFirestore, where, query, getDocs} from "firebase/firestore";
import {Button, Col, Form, Modal, Row, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faSave} from "@fortawesome/free-solid-svg-icons";
import {fbDb, firebaseApp} from "../../../../App";
import {RegionsDialog} from "../../regions/RegionsDialog";
import {removeEmptyFields, showErrorsListInToast, textToFloat} from "../../../../common";
import SelectValueFromDialog from "../../../../common/SelectValueFromDialog";
import {ClientDetailsProps} from "./ClientDetails";
import {SalesRepresentativeType} from "../hooks/useSalesRepresentativesOnce";
import I18Label, {getI18Text} from "../../../../i18/i18label";

export function ClientDetailsGeneral({
                                         zIndex,
                                         doc,
                                         sizeGroups,
                                         salesRepresentatives,
                                         onDone,
                                     }: ClientDetailsProps) {
    const [saving, setSaving] = useState(false);
    const [region, setRegion] = useState<{ country: string, name: string }>({
        country: doc?.country || "",
        name: doc?.region || ""
    })

    const save = async (data: any) => {
        setSaving(true);

        try {
            let _data = removeEmptyFields(data);
            let docId = "";
            if (doc && doc.ref) {
                docId = doc.id;
                await updateDoc(doc.ref, _data);
                const snapshot = await getDocs(query(collection(fbDb, 'clients'),
                    where('clientId', '==', doc.id)));
                if(snapshot) {
                    snapshot.forEach(
                        d => {
                            updateDoc(d.ref, {clientName: data.name, sizeGroup: data.sizeGroup});
                        }
                    )
                }
            } else {
                const newDoc = await addDoc(collection(fbDb, "clients"), _data);
                docId = newDoc.id;
            }

            const locationsRef = collection(fbDb, "clientLocations");
            const locations = await getDocs(query(locationsRef, where("clientId", "==", docId)));
            if(locations.empty) {
                addDoc(locationsRef, {
                    clientId: docId,
                    name: data.name,
                    clientName: data.name,
                    sizeGroup: data.sizeGroup,
                    address: data.address,
                    email: data.email,
                    mol: data.mol,
                    phone: data.phone,
                    religion: "",
                    machinesCnt: 0,
                    machines: "",
                    country: data.country,
                    region: data.region,
                    trader: "",
                })
            } else {
                locations.forEach(
                    async (l: any) => {
                        const _data: any = {
                            sizeGroup: data.sizeGroup || "Малък клиент",
                        };
                        if(!l.data().country) {
                            _data.country = data.country;
                        }
                        if(!l.data().region) {
                            _data.region = data.region;
                        }

                        if(Object.keys(_data).length > 0 ) {
                            console.log(_data);
                            await updateDoc(l.ref, _data);
                        }
                    }
                )
            }
            onDone();
        } catch (e: any) {
            showErrorsListInToast("Грешка", [e.message])
        } finally {
            setSaving(false);
        }
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();
        event.stopPropagation();

        const errors: string[] = [];
        if(!region || !region.country || !region.name) {
            errors.push("Моля, изберете държава и регион.");
        }

        if(!event.target.elements.name.value) {
            errors.push("Моля, попълнете името на клиента.")
        }

        if(!event.target.elements.sizeGroup.value) {
            errors.push("Моля, изберете клиентска група.")
        }

        if(errors.length > 0) {
            showErrorsListInToast("Грешка", errors);
            return;
        }

        const data: any = {
            address: event.target.elements.address.value || '',
            bulstat: event.target.elements.bulstat.value || '',
            country: region.country || '',
            email: event.target.elements.email.value || '',
            mol: event.target.elements.mol.value || '',
            name: event.target.elements.name.value || '',
            phone: event.target.elements.phone.value || '',
            region: region.name || '',
            // salesRepresentative: {name: event.target.elements.salesRepresentative.value || ''} as SalesRepresentativeType,
            sector: event.target.elements.sector.value || '',
            sizeGroup: event.target.elements.sizeGroup.value || '',
            website: event.target.elements.website.value || '',
            discount_price: textToFloat(event.target.elements.discount_price.value) || 0,
            discount_eco: textToFloat(event.target.elements.discount_eco.value) || 0,
            discount_charge: textToFloat(event.target.elements.discount_charge.value) || 0,
            discount_transport: textToFloat(event.target.elements.discount_transport.value) || 0
        };
        save(removeEmptyFields(data));
    }

    const regionsDialog = <RegionsDialog show={true} zIndex={zIndex || 1010} onClose={() => {
    }}/>

    return (
        <><Form onSubmit={handleSubmit} >
            <Modal.Body>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label><I18Label label={"Клиент"} /></Form.Label>
                            <Form.Control type="text" name="name" defaultValue={doc?.name}/>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label><I18Label label={"Булстат"} /></Form.Label>
                            <Form.Control type="text" name="bulstat" defaultValue={doc?.bulstat}/>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label><I18Label label={"Адрес по регистрация"} /></Form.Label>
                            <Form.Control type="text" name="address" defaultValue={doc?.address}/>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label><I18Label label={"МОЛ"} /></Form.Label>
                            <Form.Control type="text" name="mol" defaultValue={doc?.mol}/>
                        </Form.Group>
                    </Col>
                    <Col>
                        <SelectValueFromDialog label={<I18Label label={"Регион"} />}
                                               value={region?.name}
                                               onChange={(region) => setRegion(region)}
                                               dialog={regionsDialog}/>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label><I18Label label={"Бранш"} /></Form.Label>
                            <Form.Control type="text" name="sector" defaultValue={doc?.sector}/>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label><I18Label label={"Уебсайт"} /></Form.Label>
                            <Form.Control type="text" name="website" defaultValue={doc?.website}/>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label><I18Label label={"Телефон"} /></Form.Label>
                            <Form.Control type="text" name="phone" defaultValue={doc?.phone}/>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label><I18Label label={"Клиентска група"} /></Form.Label>
                            <select className={"form-select"} defaultValue={doc?.sizeGroup?.name} name="sizeGroup">
                                <option></option>
                                {
                                    sizeGroups?.map(
                                        g => <option>{getI18Text(g.name)}</option>
                                    )
                                }
                            </select>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label><I18Label label={"Е-мейл"} /></Form.Label>
                            <Form.Control type="text" name="email" defaultValue={doc?.email}/>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label><I18Label label={"Търговски представител"} /></Form.Label>
                            <Form.Control value={doc?.salesRepresentative?.toString() || ""} readOnly />
                        </Form.Group>
                    </Col>
                </Row>


                <h5 className={"m-0 mt-3"}>
                    <I18Label label={"Договорени условия"} />
                </h5>

                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label><I18Label label={"Търговска отстъпка"} /></Form.Label>
                            <Form.Control type="text" name="discount_price"
                                          defaultValue={doc?.discount_price || '0'}/>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label><I18Label label={"Отстъпка Еко такса"} /></Form.Label>
                            <Form.Control type="text" name="discount_eco"
                                          defaultValue={doc?.discount_eco || '0'}/>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label><I18Label label={"Отстъпка на заряд"} /></Form.Label>
                            <Form.Control type="text" name="discount_charge"
                                          defaultValue={doc?.discount_charge || '0'}/>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label><I18Label label={"Отстъпка на транспорт"} /></Form.Label>
                            <Form.Control type="text" name="discount_transport"
                                          defaultValue={doc?.discount_transport || '0'}/>
                        </Form.Group>
                    </Col>
                </Row>


            </Modal.Body>
            <Modal.Footer>
                <Row className={"w-100"}>
                    {
                        saving && <Col xs={12} className={"text-center"}>
                            <Spinner animation={"border"}/>
                        </Col>
                    }

                    {
                        !saving &&
                        <>
                            <Col xs={6}>
                                <Button variant={"outline-secondary"} onClick={() => {
                                    onDone()
                                }}>
                                    <FontAwesomeIcon icon={faArrowLeft}/> <I18Label label={"Към списъка"} />
                                </Button>
                            </Col>
                            <Col xs={6} className={"text-end"}>
                                <Button type={"submit"}>
                                    <FontAwesomeIcon icon={faSave}/> <I18Label label={"Запиши"} />
                                </Button>
                            </Col>
                        </>
                    }
                </Row>
            </Modal.Footer>
        </Form>
        </>
    )
}
