import {DocumentData, DocumentReference, QueryDocumentSnapshot, SnapshotOptions} from "firebase/firestore";
import {firebaseDateToDate} from "../common";

export type UserType = {
    id?: string;
    ref?: DocumentReference;
    activeTo?: Date;
    email?: string;
    isAdmin?: boolean;
    isManager?: boolean;
    isSalesRepresentative?: boolean;
    isServiceWorker?: boolean;
    name?: string;
    phone?: string;
    position?: string;
    role?: string;
}

export const usersConverter = {
    toFirestore(user: UserType): DocumentData {
        return {
            activeTo: user.activeTo || null,
            email: user.email || "",
            isAdmin: user.isAdmin || false,
            isManager: user.isManager || false,
            isSalesRepresentative: user.isSalesRepresentative || false,
            isServiceWorker: user.isServiceWorker || false,
            name: user.name || "",
            phone: user.phone || "",
            position: user.position || "",
            role: user.role || "",
        };
    },
    fromFirestore(
        snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions
    ): UserType {
        const user = snapshot.data(options)!;
        return {
            id: snapshot.id,
            ref: snapshot.ref,
            activeTo: firebaseDateToDate(user.activeTo),
            email: user.email || "",
            isAdmin: user.isAdmin || false,
            isManager: user.isManager || false,
            isSalesRepresentative: user.isSalesRepresentative || false,
            isServiceWorker: user.isServiceWorker || false,
            name: user.name || "",
            phone: user.phone || "",
            position: user.position || "",
            role: user.role || "",
        } as UserType;
    }
};
