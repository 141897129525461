import {
    collection,
    FirestoreError,
    getDocs,
    query,
    QueryConstraint,
    QueryDocumentSnapshot,
    where
} from "firebase/firestore";
import {useEffect, useState} from "react";
import useFirebaseCollection from "../../../../hooks/useFirebaseCollection";
import {ActivitiesType, Activity} from "../../../../types";
import {fbDb} from "../../../../App";

export function convertCrmActivitiesData(_objects: QueryDocumentSnapshot[]): ActivitiesType {
    if(_objects) {
        return _objects.map(
            d => {
                return {
                    id: d.id,
                    ref: d.ref,
                    userId: d.get('userId'),
                    pospondActivityId: d.get('pospondActivityId'),
                    date: d.get('date')?.toDate(),
                    activityStatus: d.get('activityStatus'),
                    pospondDate: d.get('pospondDate')?.toDate(),
                    clientId: d.get('clientId'),
                    withPriority: d.get('withPriority'),
                    duration: d.get('duration'),
                    campaignId: d.get('campaignId'),
                    campaignName: d.get('campaignName'),
                    clientName: d.get('clientName'),
                    activityType: d.get('activityType'),
                    userName: d.get('userName'),
                    details: d.get('details'),
                    region: d.get('region'),
                } as Activity;
            }
        );
    } else return [];
}

async function loadClientLocations(clientId: string) {
    const locations = await getDocs(query(collection(fbDb, "clientLocations"), where("clientId", "==", clientId)));
    return locations;
}

export default function UseClientCrmActivities(clientId?: string, constraints?: QueryConstraint[]): [ActivitiesType, boolean, FirestoreError | undefined] {
    const [finalConstraints, setFinalConstraints] =
        useState(constraints || [where("clientId", "==", clientId)]);

    if (clientId && (finalConstraints.length === (constraints?.length || 0))) {
        loadClientLocations(clientId).then(
            locations => {
                const ids: string[] = ["-1"];
                if(locations && !locations.empty) {
                    locations.docs.forEach(d => ids.push(d.id));
                }
                setFinalConstraints([...(constraints || []), where("clientId", "in", ids)]);
            }
        );
    }

    const [documents, loading, error] = useFirebaseCollection("activities", finalConstraints);
    const [convertedData, setConvertedData] = useState<ActivitiesType>([]);

    useEffect(() => {
        if (documents) {
            setConvertedData(convertCrmActivitiesData(documents.docs));
        }
    }, [documents]);

    return [convertedData, loading, error];
}
