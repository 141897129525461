import {FC, useEffect} from "react";
import {Col, Container, Row, Table} from "react-bootstrap";
import {dateToString, firebaseDateToDate, zeroToEmptyString, zeroToEmptyStringAsCurrency} from "../../../common";
import RightAlignedCell from "../../../common/RightAlignedCell";
import "../../../App.css";

interface Props {
    window: any;
    protocol: any;
}

const PrintProtocolFrame: FC<Props> = ({window, protocol}) => {
    const fontSize = "8pt";
    useEffect(() => {
        if (protocol) {
            window.print();
        }
    }, [protocol]);

    if(!protocol) return null;

    let total = 0;
    return <>
        <Container fluid className={"print-content"}>
            <Row>
                <Col className={"border border-4 text-center pt-3"} xs={"6"}  style={{fontSize: "14px"}}>
                    <h4>ПРОТОКОЛ</h4>
                    <h6 className={"mb-3"}>за производство на стоки</h6>
                    <hr/>
                    <Row>
                        <Col className={"text-start ps-3"}>
                            Номер: <strong>{protocol?.number}</strong>
                        </Col>
                        <Col className={"text-end pe-5"}>
                            Дата: <strong>{dateToString(firebaseDateToDate(protocol?.productionDate))}</strong>
                        </Col>
                    </Row>
                </Col>
                <Col className={"border border-4 text-start"}>
                    <Table size={"sm"}  style={{fontSize: fontSize}}>
                        <tbody>
                        <tr className={"text-nowrap"}>
                            <td>Фирма</td>
                            <th>Индустриални батерии ЕООД</th>
                        </tr>
                        <tr>
                            <td>Идент. No:</td>
                            <th>202274991</th>
                        </tr>
                        <tr>
                            <td>Град</td>
                            <th>Търговище</th>
                        </tr>
                        <tr>
                            <td>Адрес</td>
                            <th>Индустриална зона, ул."Христо Смирненски" 32</th>
                        </tr>
                        <tr>
                            <td>МОЛ</td>
                            <th>Георги Тонков</th>
                        </tr>
                        <tr>
                            <td>Телефон</td>
                            <th>0601 61 61 2</th>
                        </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Row className={"mt-3"}>
                <h6>Суровини</h6>
                <Table size={"sm"} bordered style={{fontSize: fontSize}}>
                    <thead>
                    <tr className={"text-center"}>
                        <th>Код</th>
                        <th>Стока</th>
                        <th>Мярка</th>
                        <th>Кол.</th>
                        <th>Цена</th>
                        <th>Стойност</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        protocol.items.map(
                            (item: any) => {
                                total += item.price;
                                return (
                                    <tr key={item.productId}>
                                        <td> {item.sku} </td>
                                        <td> {item.name} </td>
                                        <td> {item.dimension} </td>
                                        <RightAlignedCell> {zeroToEmptyString(item.qty)} </RightAlignedCell>
                                        <RightAlignedCell> {zeroToEmptyStringAsCurrency(item.singlePrice)} </RightAlignedCell>
                                        <RightAlignedCell> {zeroToEmptyStringAsCurrency(item.price)} </RightAlignedCell>
                                    </tr>
                                );
                            }
                        )
                    }
                    </tbody>
                </Table>
            </Row>
            <Row>
                <Col className={"text-end"}>
                    <h6>Всичко:{'   '}{ zeroToEmptyStringAsCurrency(total.toString())}</h6>
                </Col>
            </Row>
            <Row className={"mt-3"}>
                <h6>Продукт</h6>
                <Table size={"sm"} className={"border-2"} bordered style={{fontSize: fontSize}}>
                    <thead>
                    <tr className={"text-center"}>
                        <th>Код</th>
                        <th>Стока</th>
                        <th>Мярка</th>
                        <th>Кол.</th>
                        <th>Цена</th>
                        <th>Стойност</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td> {protocol.sku} </td>
                        <td> {protocol.name} </td>
                        <td> {protocol.dimension} </td>
                        <RightAlignedCell> {zeroToEmptyString(protocol.qty)} </RightAlignedCell>
                        <RightAlignedCell> {zeroToEmptyStringAsCurrency(protocol.price)} </RightAlignedCell>
                        <RightAlignedCell> {zeroToEmptyStringAsCurrency((protocol.price * protocol.qty).toString())} </RightAlignedCell>
                    </tr>
                    </tbody>
                </Table>
            </Row>
            <Row>
                <Col className={"text-end"}>
                    <h6>Всичко:{'   '}{ zeroToEmptyStringAsCurrency((protocol.price * protocol.qty).toString()) }</h6>
                </Col>
            </Row>
            <Row className={"mt-5"}>
                <Row className={"mt-5"}>
                    <Col>
                        Получил: ......................................................
                    </Col>
                    <Col className={"text-end"}>
                        Съставил: ......................................................
                    </Col>
                </Row>
            </Row>
        </Container>
    </>
}

export default PrintProtocolFrame;
