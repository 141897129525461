import React, {FC, useState} from "react";
import {Button, Col, Form, Row, Spinner} from "react-bootstrap";
import {API} from "../../api";
import {addDoc, collection} from "firebase/firestore";
import {fbDb} from "../../App";
import {showErrorsListInToast, showMsgInToast} from "../../common";
import I18Label, {getI18Text} from "../../i18/i18label";

export type FileAttachmentType = {
    filename: string;
    blob: string;
}
interface CustomPrintSendEmailProps {
    initialSendToEmail: string;
    email: string;
    attachments?: FileAttachmentType;
    templateName?: string;
    subject?: string;
}

const CustomPrintSendEmail: FC<CustomPrintSendEmailProps> = ({ initialSendToEmail, email, subject, templateName, attachments }) => {
    const [working, setWorking] = useState(false);
    const [sendToEmail, setSendToEmail] = useState(initialSendToEmail);

    const handleSendEMail = async () => {
        try {
            setWorking(true);
            const data = {
                from: API.user?.name.trim()+"<office@industrialbatteries.bg>",
                to: sendToEmail,
                replyTo: `${API.user?.name}<${API.user?.email}>`,
                message: {
                    html: email,
                    subject: subject ? subject : "",
                    attachments: attachments ? [{
                        filename: attachments.filename,
                        content: attachments.blob,
                        encoding: "base64",
                        contentType: 'application/pdf'
                    },] : null,
                },
                template: templateName ? {
                    name: templateName,
                    data: attachments ? {
                        subject: subject ? subject : "",
                        filename: attachments.filename,
                        file_path: `data:application/pdf;base64,${attachments.blob}`,
                    } : null,
                }: null,
            }

            await addDoc(collection(fbDb, "mail"), data);
            showMsgInToast(getI18Text("Имейлът е изпратен успешно"));
        } catch (e: any) {
            console.error(e);
            showErrorsListInToast(getI18Text("Грешка при изпращане на имейл"), [e.message]);
        } finally {
            setWorking(false);
        }
    }

    if (working) {
        return <Spinner animation={"border"} variant={"primary"} />
    }

    return (
        <Row className={"w-480px"}>
            <Col className={"pe-0"}>
                <Form.Control
                    value={sendToEmail}
                    onChange={event => setSendToEmail(event.target.value)}
                    title={getI18Text("Имейл адрес на получателя")}
                    placeholder={getI18Text("Имейл адрес на получателя") ?? ""}
                />
            </Col>
            <Col xs={"auto"} className={"ps-0"}>
                <Button
                    onClick={handleSendEMail}
                    disabled={sendToEmail?.length === 0}
                > <I18Label label={"Изпрати на имейл"} /> </Button>&nbsp;
            </Col>
        </Row>
    )
}

export default CustomPrintSendEmail
