import {DocumentData, DocumentReference, QueryDocumentSnapshot, SnapshotOptions,} from "firebase/firestore";
import {ClientType} from "../pages/nomenclatures/clients/hooks/useClients";
import {VirtualProductType} from "../pages/nomenclatures/virtual-products/hooks/useVirtualProductsOnce";
import {ServiceType} from "./service-converter";
import {ProductType} from "../pages/nomenclatures/products/hooks/useProductsOnce";

export type InvoiceType = {
    id?: string;
    ref?: DocumentReference;
    offerId: string;
    offerNumber: number;
    offerDate: Date;
    inquiryId: string;
    inquiryNumber: number;
    inquiryDate: Date;
    isProforma: boolean;
    client: ClientType;
    products: CustomerOfferProductionsType;
    price: number
} | null;

export type CustomerOfferProductionAttributeType = {
    name: string;
    singlePrice: number;
    discount: number;
    finalPrice: number;
}

export type CustomerOfferProductionAttributesType = CustomerOfferProductionAttributeType[];


export type CustomerOfferProductionType = {
    product: ProductType;
    deliveryDays: string;
    singlePrice: number;
    discount: number;
    finalSinglePrice: number;
    finalSingleTotalPrice: number;
    skrubPrice: number;
    comments: string;
    attributes: CustomerOfferProductionAttributesType;
    services: CustomerOfferServicesType;
    extendedWarranty: number;
    qty: number;
}
export type CustomerOfferProductionsType = CustomerOfferProductionType[];

export type CustomerOfferServiceType = {
    service: ServiceType;
    singlePrice: number;
    discount: number;
    qty: number;
    finalPrice: number;
}
export type CustomerOfferServicesType = CustomerOfferServiceType[];

export type CustomerOfferType = {
    comment: string | null;
    validityPeriod: string | null;
    paymentMethod: string | null;
    id?: string;
    ref?: DocumentReference;
    offerNumber: number;
    offerDate: Date;
    inquiryId: string;
    inqueryNumber: number;
    inqueryDate: Date;
    products: CustomerOfferProductionsType;
    bestProductPriceIdx: number | null;
    proformaInvoice: InvoiceType;
    invoice: InvoiceType;
    eurExchangeRate: number;
}

export type CustomerOffersType = CustomerOfferType[];

export const customerOfferConverter = {
    toFirestore(offer: CustomerOfferType): DocumentData {
        const _offer = {
            id: offer.id || null,
            ref: offer.ref || null,

            offerNumber: offer.offerNumber || 1,
            offerDate: offer.offerDate || new Date(),

            inquiryId: offer.inquiryId,
            inqueryNumber: offer.inqueryNumber ?? null,
            inqueryDate: offer.inqueryDate ?? null,

            products: offer.products,
            bestProductPriceIdx: offer.bestProductPriceIdx,
            eurExchangeRate: offer.eurExchangeRate || 1,
            comment: offer.comment || null,
            validityPeriod: offer.validityPeriod || null,
            paymentMethod: offer.paymentMethod || null,
        };

        return _offer;
    },
    fromFirestore(
        snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions
    ): CustomerOfferType {
        const offer = snapshot.data(options)!;
        return {
            id: snapshot.id,
            ref: snapshot.ref,
            offerNumber: offer.offerNumber || 1,
            offerDate: offer.offerDate.toDate() || new Date(),

            inquiryId: offer.inquiryId,
            inqueryNumber: offer.inqueryNumber ?? null,
            inqueryDate: offer.inqueryDate?.toDate() ?? null,

            products: offer.products || [],
            bestProductPriceIdx: offer.bestProductPriceIdx,
            eurExchangeRate: offer.eurExchangeRate || 1,
            comment: offer.comment || null,
            validityPeriod: offer.validityPeriod || null,
            paymentMethod: offer.paymentMethod || null,
        } as CustomerOfferType;
    }
};

export function productToOfferMapper(product: ProductType | null, discount_price: number, eurExchangeRate: number): CustomerOfferProductionType | null {
    if (!product) return null;

    const singlePrice = (product?.price || 0) * (eurExchangeRate);
    const finalSinglePrice = singlePrice - (singlePrice * discount_price / 100);
    let finalSingleTotalPrice = finalSinglePrice * 1;
    const attributes = product?.attributes
            .filter(a => a.name && a.price)
            .map(
                a => {
                    const price = (a.price || 0) * (eurExchangeRate);
                    const finalPrice = price - (price * discount_price / 100);
                    finalSingleTotalPrice += finalPrice;
                    return {
                        name: a.name,
                        discount: discount_price,
                        singlePrice: price,
                        finalPrice: finalPrice,
                    } as CustomerOfferProductionAttributeType;
                }
            ) || [];

    return {
        product: product,
        deliveryDays: "",
        comments: "",
        discount: discount_price,
        qty: 1,
        singlePrice:  singlePrice,
        finalSinglePrice: finalSinglePrice,
        finalSingleTotalPrice: finalSingleTotalPrice,
        skrubPrice: 0,
        attributes: attributes,
    } as CustomerOfferProductionType;
}
