function convertPriceToText(price) {
    // Речник с текстовете за числата от 0 до 19
    var numbers = [
        "нула",
        "едно",
        "две",
        "три",
        "четири",
        "пет",
        "шест",
        "седем",
        "осем",
        "девет",
        "десет",
        "единадесет",
        "дванадесет",
        "тринадесет",
        "четиринадесет",
        "петнадесет",
        "шестнадесет",
        "седемнадесет",
        "осемнадесет",
        "деветнадесет"
    ];

    // Речник с текстовете за десетиците
    var tens = [
        "",
        "",
        "двадесет",
        "тридесет",
        "четиридесет",
        "петдесет",
        "шестдесет",
        "седемдесет",
        "осемдесет",
        "деветдесет"
    ];

    // Речник с текстовете за стотиците
    var hundreds = [
        "",
        "сто",
        "двеста",
        "триста",
        "четиристотин",
        "петстотин",
        "шестстотин",
        "седемстотин",
        "осемстотин",
        "деветстотин"
    ];

    // Речник с текстовете за хилядите
    var thousands = [
        "",
        "хиляда",
        "две хиляди",
        "три хиляди",
        "четири хиляди",
        "пет хиляди",
        "шест хиляди",
        "седем хиляди",
        "осем хиляди",
        "девет хиляди"
    ];

    // Речник с текстовете за милионите
    var millions = [
        "",
        "един милион",
        "два милиона",
        "три милиона",
        "четири милиона",
        "пет милиона",
        "шест милиона",
        "седем милиона",
        "осем милиона",
        "девет милиона"
    ];

    // Проверка за нула
    if (price === 0) {
        return numbers[0];
    }

    // Преобразуване на числото в текст
    var result = "";

    // Разделяне на числото на отделни части
    var integerPart = Math.floor(price);
    var fractionalPart = Math.round((price - integerPart) * 100);

    // Обработка на цялата част
    if (integerPart >= 1000000) {
        var millionsPart = Math.floor(integerPart / 1000000);
        integerPart %= 1000000;
        result += millions[millionsPart] + " ";
    }

    if (integerPart >= 1000) {
        var thousandsPart = Math.floor(integerPart / 1000);
        integerPart %= 1000;
        if(thousandsPart > 9) {
            result += convertPriceToText(thousandsPart) + " хиляди ";
        } else {
            result += thousands[thousandsPart] + " ";
        }
    }

    if (integerPart >= 100) {
        var hundredsPart = Math.floor(integerPart / 100);
        integerPart %= 100;
        result += hundreds[hundredsPart] + " ";
    }

    if (integerPart >= 20) {
        var tensPart = Math.floor(integerPart / 10);
        integerPart %= 10;
        result += tens[tensPart] + " ";
    }

    if (integerPart > 0) {
        result += (result ? " и " : "") + numbers[integerPart] + " ";
    }

    // Обработка на дробната част
    if (fractionalPart > 0) {
        result += " лв и " + fractionalPart + " стотинки";
    }

    return result.trim();
}

export function priceToText(price) {
  return convertPriceToText(price).trim();
}
