import {ClientDetail} from "../pages/nomenclatures/clients/details/ClientDetails";
import React, {useState} from "react";
import {ClientType, convertClientData} from "../pages/nomenclatures/clients/hooks/useClients";
import {Modal, ModalTitle, Spinner} from "react-bootstrap";
import {doc, getDoc, getFirestore} from "firebase/firestore";
import {fbDb, firebaseApp} from "../App";
import ModalHeader from "react-bootstrap/ModalHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHandshake} from "@fortawesome/free-solid-svg-icons";
import DialogCloseButton from "./DialogCloseButton";
import ModalBackground from "./ModalBackground";
import {ClientLocationType} from "../fb-converters/location-converter";
import {useHookstate} from "@hookstate/core";
import appDb from "../global-state/global-db";

type Props = {
    clientLocationId: string;
    clientLocationName: string;
}

const ClientNameWithDetails: React.FC<Props> = (props) => {

    const zIndex = 1100;
    const allClients = useHookstate(appDb.clients);
    const [client, setClient] = useState<ClientType | null>(null);
    const [loading, setLoading] = useState(false);

    async function loadClient() {
        setLoading(true);
        const clientLocationData = await getDoc(doc(fbDb, `clientLocations/${props.clientLocationId}`));
        if(clientLocationData && clientLocationData.exists()) {
            const clientData = allClients?.find(c => c.value.id === clientLocationData.data()?.clientId);
            setClient(clientData ? clientData.value as ClientType : null);
        }
        setLoading(false);
    }

    return <>
        <span onClick={() => {
            loadClient()
        }}>
            {loading ? <Spinner animation={"border"}/> : <span className={"text-primary cursor-pointer"}>{props.clientLocationName}</span>}
        </span>
        {
            client &&
            <ModalBackground zIndex={zIndex}>
                <Modal show={true} size={"xl"} style={{zIndex: (zIndex + 1)}}>
                    <ModalHeader>
                        <ModalTitle>
                            <FontAwesomeIcon icon={faHandshake}/> Клиенти
                        </ModalTitle>
                        <DialogCloseButton onClose={() => setClient(null)}/>
                    </ModalHeader>
                    <ClientDetail doc={client}
                                  zIndex={1100}
                                  sizeGroups={[]}
                                  salesRepresentatives={[]}
                                  onDone={() => {
                                      setClient(null)
                                  }}
                                  onSelect={() => {
                                      setClient(null)
                                  }}
                    />
                </Modal>
            </ModalBackground>
        }
    </>
}


export default ClientNameWithDetails
