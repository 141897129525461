import {collection, doc, DocumentData, getFirestore, onSnapshot, orderBy, query, where} from "firebase/firestore";
import {fbDb, firebaseApp} from "../../App";
import {API} from "../../api";
import {useEffect, useState} from "react";
import {Spinner} from "react-bootstrap";
import CrmActivityListItem from "./CrmActivityListItem";

export interface CrmPastActivitiesViewerProps {
    viewDate: Date,
    selectedActivities: string[];
    onToggle: (documentId: string) => void;
    onDetails: (d: DocumentData) => void;
}

export default function CrmPastActivitiesViewer({
                                                    viewDate,
                                                    selectedActivities,
                                                    onToggle,
                                                    onDetails
                                                }: CrmPastActivitiesViewerProps) {

    const [pastActivities, setPastActivities] = useState<DocumentData[]>([]);

    useEffect(() => {
        let q = query(collection(fbDb, "activities"),
            where('userId', '==', API.user?.id || "undefined"),
            where("date", '<', viewDate),
            where("activityStatus", 'in', ["", "не отговаря", null]),
            orderBy("date"));

        const unsubscribe = onSnapshot(q, (docs) => {
            setPastActivities(docs.docs || []);
        });
        return unsubscribe;
    }, []);

    const [content, setContent] = useState<JSX.Element[]>([]);

    useEffect(() => {
        let loadPastActivities = false;

        if (loadPastActivities) {
            setContent([<div className={"text-center"}><Spinner animation={"border"}/></div>]);
        } else {
            let errorPastActivities = null;

            if (errorPastActivities) {
                setContent([<div className={"text-danger text-center"}>{errorPastActivities}</div>]);
            } else {
                let _content =
                    pastActivities?.map(
                        d => <CrmActivityListItem key={d.id} data={d.data()} className={"bg-danger-light"}
                                                  isSelected={selectedActivities.indexOf(d.id) > -1}
                                                  onToggle={() => onToggle(d.id)}
                                                  onDetails={() => onDetails(d)}/>
                    );
                setContent(_content || []);
            }
        }
    }, [pastActivities, selectedActivities]);


    return <>{content}</>
}
