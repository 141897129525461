import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilter} from "@fortawesome/free-solid-svg-icons";
import {Button} from "react-bootstrap";
import {FC} from "react";

const FilterIconButton: FC<any> = (props) => {
    return <Button {...props} variant={"info"} className={"text-light"} >
        <FontAwesomeIcon icon={faFilter}/>
    </Button>;
}

export default FilterIconButton;
