import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";

export interface DialogCloseButtonProps {
    onClose: () => any;
}

export default function DialogCloseButton({onClose}: DialogCloseButtonProps) {
    return <Button onClick={onClose} variant={"outline-secondary"}>
        <FontAwesomeIcon icon={faXmark} />
    </Button>
}