import {FirestoreError, QueryConstraint} from "firebase/firestore";
import {useEffect, useState} from "react";
import {serviceConverter, ServiceType} from "../../../fb-converters/service-converter";
import useFirebaseCollection from "../../../hooks/useFirebaseCollection";

export default function useServices(constraints?: QueryConstraint[]): [ServiceType[], boolean, FirestoreError | undefined] {
    const [documents, loading, error] = useFirebaseCollection("services",
        [...(constraints || [])], serviceConverter);
    const [convertedData, setConvertedData] = useState<ServiceType[]>([]);

    useEffect(() => {
        if(documents) {
            setConvertedData(documents.docs.map(d => d.data() as ServiceType));
        }
    }, [documents]);

    return [convertedData, loading, error];
}