import {useState} from "react";

import {collection, deleteDoc, getDocs, query, runTransaction, where} from "firebase/firestore";
import {Button, Col, Form, Modal, Row, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faSave} from "@fortawesome/free-solid-svg-icons";
import {ClientType} from "./hooks/useClients";
import {fbDb} from "../../../App";
import {showErrorsListInToast} from "../../../common";


export interface ClientDeleteProps {
    doc: ClientType | null;
    onDone: () => any;
}

export function ClientDelete({doc, onDone}: ClientDeleteProps) {

    const [deleting, setDeleting] = useState(false);

    const deleteRegion = async () => {
        if(!doc || !doc.ref) return;
        setDeleting(true);

        try {
            await runTransaction(fbDb, async (transaction) => {
                const clientLocations = await getDocs(
                    query(collection(fbDb, "clientLocations"),
                        where("clientId", "==", doc!.id))
                );
                clientLocations.docs.forEach(
                    d => transaction.delete(d.ref)
                )
                if(doc?.ref) {
                    transaction.delete(doc.ref);
                }
            });
            onDone();
        } catch (e: any) {
            showErrorsListInToast("Грешка", [e.message])
        } finally {
            setDeleting(false);
        }
    }

    return (
        <>
            <Modal.Body>
                <h5 className={"mb-3"}>
                    Изтриване на клиент
                </h5>

                <div className={"text-danger"}>
                    Моля, потвърдете изтриването на клиента <strong className={"text-primary"}>{doc?.name}</strong>!
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Row className={"w-100"}>
                    {
                        deleting && <Col xs={12} className={"text-center"}>
                            <Spinner animation={"border"}/>
                        </Col>
                    }

                    {
                        !deleting &&
                        <>
                            <Col xs={6}>
                                <Button variant={"outline-secondary"} onClick={() => {
                                    onDone()
                                }}>
                                    <FontAwesomeIcon icon={faArrowLeft}/> Към списъка
                                </Button>
                            </Col>
                            <Col xs={6} className={"text-end"}>
                                <Button onClick={() => deleteRegion()} variant={"danger"}>
                                    <FontAwesomeIcon icon={faSave}/> Изтрий
                                </Button>
                            </Col>
                        </>
                    }
                </Row>
            </Modal.Footer>
        </>
    )
}
