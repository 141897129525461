import {FC, useEffect} from "react";
import {useDocument} from "react-firebase-hooks/firestore";
import {doc, setDoc} from "firebase/firestore";
import {fbDb,} from "../../App";
import {useHookstate} from "@hookstate/core";
import appState from "../global-state";

const GlobalStateListener: FC<{}> = () => {
    const _globalState = useHookstate(appState);

    const globalStateRef = doc(fbDb, "settings", "globalSettings");
    const [globalStateData,] = useDocument(globalStateRef);


    useEffect(() => {
        if(globalStateData) {
            if(globalStateData.exists()) {
                _globalState.set({
                    id: globalStateData.id,
                    ref: globalStateData.ref,
                    ecoTaxPerKgPrice: globalStateData.data().ecoTaxPerKgPrice || 0,
                    currencyIndex: globalStateData.data().currencyIndex || {
                        EUR: 0
                    },
                    customerInquiryNumber: globalStateData.data().customerInquiryNumber || 0,
                    customerInvoiceNumber: globalStateData.data().customerInvoiceNumber || 0,
                    productsNumber: globalStateData.data().productsNumber || 0,
                    customerProformaInvoiceNumber: globalStateData.data().customerProformaInvoiceNumber || 0,
                    customerOrderConfirmationNumber: globalStateData.data().customerOrderConfirmationNumber || 0,
                    productionCardNumber: globalStateData.data().productionCardNumber || 0,
                    warehouseRequestsNumber: globalStateData.data().warehouseRequestsNumber || 0,
                })
            } else {
                // debugger;
                // setDoc(globalStateRef, {
                //     ecoTaxPerKgPrice: 0,
                //     currencyIndex: {
                //         EUR: 0
                //     },
                //     customerInquiryNumber: 0,
                //     customerInvoiceNumber: 0,
                //     productsNumber: 0,
                //     customerProformaInvoiceNumber: 0,
                //     customerOrderConfirmationNumber: 0,
                //     productionCardNumber: 0,
                // }).then();
            }
        }
    }, [globalStateData]);

    return null;
}
export default GlobalStateListener;
