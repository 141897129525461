import {ProductionCardProductsType} from "../types/ProductionCardProductType";
import {Col, Form, Row, Table} from "react-bootstrap";
import React from "react";
import {textToInt} from "../../../common";
import RightAlignedCell from "../../../common/RightAlignedCell";
import I18Label, {getI18Text} from "../../../i18/i18label";

function SelectConnector(props: { onChange: (connector: string) => void, selected: string }) {
    return <Form.Select size={"sm"}
                        value={props.selected}
                        onChange={e => props.onChange(e.target.value)}>
        <option value=""></option>
        <option value="Гъвкави">{ getI18Text("Гъвкави") }</option>
        <option value="Твърди">{ getI18Text("Твърди") }</option>
    </Form.Select>;
}

function SelectOutputType(props: { onChange: (outputType: string) => void, selected: string }) {
    return <Form.Select size={"sm"}
                        value={props.selected}
                        onChange={e => props.onChange(e.target.value)}>
        <option value=""></option>
        <option value="Под болт">{ getI18Text("Под болт") }</option>
        <option value="БДС">{ getI18Text("БДС") }</option>
        <option value="SIN">{ getI18Text("SIN") }</option>
    </Form.Select>;
}

export default function ProductionPlanSpecificationTable(props: {
    onChange: (items: ProductionCardProductsType) => void,
    items: ProductionCardProductsType
}) {

    const [productSizeL, setProductSizeL] = React.useState("");
    const [productSizeB, setProductSizeB] = React.useState("");
    const [productSizeH1, setProductSizeH1] = React.useState("");
    const [productSizeH2, setProductSizeH2] = React.useState("");
    const [activeIndex, setActiveIndex] = React.useState(-1);

    return (
        <Table>
            <thead>
            <tr>
                <th>№</th>
                <th>SKU</th>
                <th><I18Label label={"Тип на стоката"} /></th>
                <th><I18Label label={"Размери"} /> (L/B/H1/H2) mm</th>
                <th><I18Label label={"Брой"} /></th>
                <th><I18Label label={"Конектори"} /></th>
                <th><I18Label label={"Тип на изводите"} /></th>
                <th className={"text-center"}><I18Label label={"Заряд"} /></th>
                <th className={"text-center"}><I18Label label={"Дренажни отвори"} /></th>
            </tr>
            </thead>
            <tbody>
            {
                props.items
                    .sort((a, b) => a.orderIndex - b.orderIndex)
                    .map(
                        (item, index) => {
                            return (
                                <tr key={index}>
                                    <td>{item.orderIndex}</td>
                                    <td>{item.productCode}</td>
                                    <td>{item.productName}</td>
                                    <td className={"w-160px"}>
                                        <Row className={"p-0 m-0"}>
                                            <Col className={"p-0 m-0"}>
                                                <Form.Control size={"sm"}
                                                              className={"p-0 m-0 pe-1 text-end"}
                                                              value={activeIndex === index ? productSizeL : item.productSize.l}
                                                              onChange={
                                                                  e => {
                                                                      setProductSizeL(e.target.value);
                                                                  }
                                                              }
                                                              onFocus={
                                                                  () => {
                                                                      setActiveIndex(index);
                                                                      setProductSizeL(item.productSize.l.toString());
                                                                  }
                                                              }
                                                              onBlur={
                                                                  () => {
                                                                      item.productSize.l = textToInt(productSizeL) ?? item.productSize.l;
                                                                      props.onChange(props.items);
                                                                  }
                                                              }
                                                />
                                            </Col>
                                            <Col className={"p-0 m-0"}>
                                                <Form.Control
                                                    className={"p-0 m-0 pe-1 text-end"}
                                                    size={"sm"}
                                                    value={activeIndex === index ? productSizeB : item.productSize.b}
                                                    onChange={
                                                        e => {
                                                            setProductSizeB(e.target.value);
                                                        }
                                                    }
                                                    onFocus={
                                                        () => {
                                                            setActiveIndex(index);
                                                            setProductSizeB(item.productSize.b.toString());
                                                        }
                                                    }
                                                    onBlur={
                                                        () => {
                                                            item.productSize.b = textToInt(productSizeB) ?? item.productSize.b;
                                                            props.onChange(props.items);
                                                        }
                                                    }
                                                />
                                            </Col>
                                            <Col className={"p-0 m-0"}>
                                                <Form.Control
                                                    className={"p-0 m-0 pe-1 text-end"}
                                                    size={"sm"}
                                                    value={activeIndex === index ? productSizeH1 : item.productSize.h1}
                                                    onChange={
                                                        e => {
                                                            setProductSizeH1(e.target.value);
                                                        }
                                                    }
                                                    onFocus={
                                                        () => {
                                                            setActiveIndex(index);
                                                            setProductSizeH1(item.productSize.h1.toString());
                                                        }
                                                    }
                                                    onBlur={
                                                        () => {
                                                            item.productSize.h1 = textToInt(productSizeH1) ?? item.productSize.h1;
                                                            props.onChange(props.items);
                                                        }
                                                    }
                                                />
                                            </Col>
                                            <Col className={"p-0 m-0"}>
                                                <Form.Control
                                                    className={"p-0 m-0 pe-1 text-end"}
                                                    size={"sm"}
                                                    value={activeIndex === index ? productSizeH2 : item.productSize.h2}
                                                    onChange={
                                                        e => {
                                                            setProductSizeH2(e.target.value);
                                                        }
                                                    }
                                                    onFocus={
                                                        () => {
                                                            setActiveIndex(index);
                                                            setProductSizeH2(item.productSize.h2.toString());
                                                        }
                                                    }
                                                    onBlur={
                                                        () => {
                                                            item.productSize.h2 = textToInt(productSizeH2) ?? item.productSize.h2;
                                                            props.onChange(props.items);
                                                        }
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    </td>
                                    <RightAlignedCell>{item.productQty}</RightAlignedCell>
                                    <td><SelectConnector selected={item.connectors}
                                                         onChange={connector => {
                                                             item.connectors = connector;
                                                             props.onChange([...props.items]);
                                                         }}/></td>
                                    <td><SelectOutputType
                                        onChange={outputType => {
                                            item.outputType = outputType;
                                            props.onChange([...props.items]);
                                        }} selected={item.outputType}/></td>
                                    <td className={"text-center"}>
                                        <Form.Check checked={item.charging} onChange={event => {
                                            item.charging = event.target.checked;
                                            props.onChange([...props.items]);
                                        }}/>
                                    </td>
                                    <td className={"text-center"}>
                                        <Form.Check checked={item.drenageHoles} onChange={event => {
                                            item.drenageHoles = event.target.checked;
                                            props.onChange([...props.items]);
                                        }}/>
                                    </td>
                                </tr>
                            )
                        }
                    )
            }
            </tbody>
        </Table>
    );
}
